<section class="firstScreen has-wave bottom">
  <div class="wave wave-1">
    <picture>
      <source srcset="/assets/images/shared/wave@1x.png,
                      /assets/images/shared/wave@2x.png 2x" media="(min-width: 481px)">
        <img src="/assets/images/shared/wave5@1x.png" srcset="/assets/images/shared/wave5@2x.png 2x" alt="wave">
    </picture>
  </div>
  <picture>
    <source srcset="/assets/images/domains/firstscreen-domains-decorations@1x.png,
                    /assets/images/domains/firstscreen-domains-decorations@2x.png 2x" media="(min-width: 481px)">
    <img  src="/assets/images/domains/firstscreen-domains-decorations-mobile@1x.png"
          srcset="/assets/images/domains/firstscreen-domains-decorations-mobile@2x.png 2x" alt="decorations" class="decorations">
  </picture>
  <div class="wrapCircle">
    <img  src="/assets/images/shared/bg-blue-circle@1x.png"
          srcset="/assets/images/shared/bg-blue-circle@2x.png 2x" alt="circle" class="circle" *ngIf="!isMobile">
  </div>

  <div class="container plans-ts">
    <div class="firstScreen__info">
      <div class="suptitle upper color-accent-green center" i18n>compra tu dominio</div>
      <h1 class="title-1 center" i18n>Encuentra el nombre perfecto para tu sitio</h1>
      <div class="subheading center" i18n>Obtén tu nombre de dominio .{{tld}} por {{symbol}} {{getCurrentDomainZone(tld) | currencyFormat }} / anual {{ getTaxSymbol() }}</div>
    </div>
  </div>

  <!-- START formDomain -->
  <form class="formDomain" [formGroup]="form" (ngSubmit)="onSubmit()">
    <span class="prefix">WWW</span>
    <input type="search"
          #inputSearch
          formControlName="search"
          placeholder="Escriba su nombre de dominio…">
    <button class="btn btnAccent rounded"
            mat-button
            [disabled]="!form.valid" i18n>
      Buscar
    </button>
  </form>
  <!-- END -->

  <!-- <div class="subtext color-white" *ngIf="isShowResultSearch; else loading" i18n>El dominio, <b>{{searchedDomain}}</b> no está disponible, sin embargo, te mostramos algunas alternativas disponibles.</div> -->

  <ng-template [ngIf]="!response">
    <div class="subtext color-white" *ngIf="isShowResultSearch; else loading"></div>
  </ng-template>
  <ng-template [ngIf]="response">
    <div class="subtext color-white" *ngIf="response.is_available">El dominio, <b>{{searchedDomain}}</b> está disponible, también {{te}} mostramos algunas alternativas disponibles. <br><br> <button style="background-color: #FF623B;" (click)="goToPlan( response.domain )" class="btn color-white btnDomain" mat-button>COMPRAR</button></div>
    <div class="subtext color-white" *ngIf="!response.is_available">El dominio, <b>{{searchedDomain}}</b> no está disponible, sin embargo, {{te}} mostramos algunas alternativas disponibles.</div>
    <!-- <img *ngIf="response.is_available" src="/assets/images/icons/domains/Icon_domain_check.svg" alt="icon domain available">
    <img *ngIf="!response.is_available" src="/assets/images/icons/domains/icon_domain_registered.svg" alt="icon domain registered"> -->
  </ng-template>


  <ng-template #loading>
    <div *ngIf="isloading" class="center container">
      <br>
      <mat-progress-bar class="domain-progress" mode="indeterminate"></mat-progress-bar>
    </div>
  </ng-template>
</section>

<!-- START sectionDomainSelection -->
<ng-container *ngIf="!form.get('search').hasError('required')">
  <section class="sectionDomainSelection" [class.hide]="!isShowResultSearch">
    <div class="container">
      <div class="suptitle upper color-accent-green center" i18n>Otros nombres de dominio</div>
      <h2 class="title-2 center center" i18n>También puede adquirir estos nombres dominios</h2>
    </div>

    <app-table-domain [searchedDomain]="searchedDomain" [subtext]="subtext"></app-table-domain>
  </section>
</ng-container>
<!-- END -->

<!-- START sectionColumns--domainname -->
<section class="sectionColumns sectionColumns--domainname">
  <div class="sectionColumns__col">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>el nombre</div>
      <h2 class="title-2" i18n>¿Por qué necesitas comprar un dominio web?</h2>
      <div class="body-1 color-secondary" [preRender]="false" (deferLoad)="showImage1 = true" i18n>El dominio es el nombre de tu sitio web en internet. Un buen dominio aumentará la visibilidad de tu empresa y hará que más visitantes lleguen a ella, por eso es importante elegir el nombre de dominio.</div>
    </div>
  </div>

  <div class="sectionColumns__col">
    <picture *ngIf="showImage1">
      <source srcset="/assets/images/domains/domain-name-desktop@2x.avif 2x"
                      type="image/avif" media="(min-width: 670px)">
      <source srcset="/assets/images/domains/domain-name-desktop@2x.webp 2x"
                      type="image/webp" media="(min-width: 670px)">
      <source srcset="/assets/images/domains/domain-name-desktop@1x.png,
                      /assets/images/domains/domain-name-desktop@2x.png 2x" media="(min-width: 670px)">
      <source srcset="/assets/images/domains/domain-name-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/domains/domain-name-mobile@1x.png"
            srcset="/assets/images/domains/domain-name-mobile@2x.png 2x" alt="Site publisher">
    </picture>
  </div>

  <picture *ngIf="showImage1">
    <source srcset="/assets/images/domains/domainname-decorations-desktop@1x.png,
                    /assets/images/domains/domainname-decorations-desktop@2x.png 2x" media="(min-width: 992px)">
    <source srcset="/assets/images/domains/domainname-decorations-tablet@1x.png,
                    /assets/images/domains/domainname-decorations-tablet@2x.png 2x" media="(min-width: 670px)">
    <img  src="/assets/images/domains/domainname-decorations-mobile@1x.png"
          srcset="/assets/images/domains/domainname-decorations-mobile@2x.png 2x" alt="decorations" class="decorations">
  </picture>
</section>
<!-- END -->

<!-- START sectionVideo -->
<section class="sectionVideo" (click)="isShowVideo = true" *ngIf="showImage1" [preRender]="false" (deferLoad)="showImage2 = true">
  <ng-container *ngIf="isShowVideo">
    <iframe width="100%" height="100%"
      src="https://www.youtube.com/embed/ZMVTiG8p-8Y?autoplay=1&amp;rel=0&amp;loop=1&amp;&amp;playlist=ZMVTiG8p-8Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </ng-container>
  <picture>
    <source srcset="/assets/images/domains/domains-preview@2x.webp 2x" type="image/webp">
    <img src="/assets/images/domains/domains-preview.jpg" alt="preview domain">
  </picture>
  <button *ngIf="!isShowVideo" class="btnPlay" mat-icon-button>
    <svg-icon-sprite
      [src]="'/assets/images/sprites/sprite.svg#icon-play'"
      [width]="'24px'"
      [classes]="'icon-play'">
    </svg-icon-sprite>
  </button>
</section>
<!-- END -->

<!-- START wrapTips -->
<section class="wrapTips">
  <div class="wrapDecorations" *ngIf="showImage2">
    <img  src="/assets/images/shared/bg-blue-circle@1x.png"
          srcset="/assets/images/shared/bg-blue-circle@2x.png 2x" alt="circle" class="circle">
    <picture>
      <source srcset="/assets/images/domains/section-tips-decorations-desktop@1x.png,
                      /assets/images/domains/section-tips-decorations-desktop@2x.png 2x"
                      media="(min-width: 670px)">
      <img  src="/assets/images/vps/vps-decorations-mobile@1x.png"
            srcset="/assets/images/vps/vps-decorations-mobile@2x.png 2x" alt="decorations" class="decorations">
    </picture>
  </div>
  <div class="container">
    <div class="suptitle upper center color-accent-green" i18n>Consejos</div>
    <h2 class="title-2 center" i18n>Tips para elegir un nombre de Dominio</h2>

    <app-options [options]="options" [class]="'domains'" [loadImage]="showImage1"></app-options>

    <button id="l-vps-plans" class="btn btnAccent rounded" mat-button (click)="navigateToSection('plans-ts')" i18n>Comprar</button>
  </div>
</section>
<!-- END -->

<!-- START sectionDomains-->
<section class="sectionDomains has-wave top">
  <div class="wave wave-2" *ngIf="showImage2">
    <img  src="/assets/images/shared/wave2-blue@1x.png"
          srcset="/assets/images/shared/wave2-blue@2x.png 2x" alt="wave">
  </div>
  <div class="container">
    <div class="suptitle upper color-accent-green center" i18n>Extensiones de dominio</div>
    <h2 class="title-2 color-white center" i18n>Elige la extensión de dominio perfecta para tu sitio</h2>
    <div class="body-1 color-white center" i18n>Tenemos la mayor variedad y los menores precios para ti</div>
  </div>
  <app-table-domain-zones [subtext]="subtext"></app-table-domain-zones>
</section>
<!-- END -->

<!-- START sectionColumns--support -->
<section class="has-wave top">
  <img  src="/assets/images/shared/wave5@1x.png"
        srcset="/assets/images/shared/wave5@2x.png 2x" alt="wave" class="wave wave-5" *ngIf="showImage2">
  <app-section-support [data]="dataSupport" [class]="'domains'" [loadImage]="showImage2"></app-section-support>
</section>
<!-- END -->

<!-- START payment-logos-->
<app-payment-logos
  [buttonText]="'Comprar'"
  [buttonId]="'l-d-comprar2'"
  [class]="'web-hosting-full'"
  [title]="title"
  [subtext]="'Desde ' + symbol + (getCurrentDomainZone(tld) | currencyFormat ) + ' ANUAL'"
  [loadImage]="showImage2">
</app-payment-logos>
<!-- END -->

<app-footer [loadImage]="showImage2"></app-footer>
