<section class="sectionColumns sectionColumns--support has-wave bottom" [ngClass]="class">
  <div class="sectionColumns__col">
    <picture *ngIf="loadImage">
      <source srcset="/assets/images/web-hosting/support-desktop@2x.avif 2x"
              type="image/avif" media="(min-width: 481px)">
      <source srcset="/assets/images/web-hosting/support-desktop@2x.webp 2x"
              type="image/webp" media="(min-width: 481px)">
      <source srcset="/assets/images/web-hosting/support-desktop@1x.png,
                      /assets/images/web-hosting/support-desktop@2x.png 2x" media="(min-width: 481px)">
      <source srcset="/assets/images/web-hosting/support-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/web-hosting/support-mobile@1x.png"
            srcset="/assets/images/web-hosting/support-mobile@2x.png 2x" alt="Soporte">
    </picture>
  </div>
  <div class="sectionColumns__col">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>{{data.suptitle}}</div>
      <h2 class="title-2" i18n>{{data.title}}</h2>
      <div class="body-1 color-secondary" i18n>{{data.text}}</div>
      <button id="{{data.buttonId}}" class="btn btnTranspAcceent rounded" mat-button (click)="navigationTo('/contactar-con-ventas/')" i18n>Contactar</button>
    </div>
  </div>

  <div class="wave wave-6" *ngIf="loadImage">

    <picture>
      <source srcset="/assets/images/shared/wave6@1x.png,
                      /assets/images/shared/wave6@2x.png 2x" media="(min-width: 992px)">
      <img  src="/assets/images/shared/wave6-tablet@1x.png"
            srcset="/assets/images/shared/wave6-tablet@2x.png 2x" alt="wave">
    </picture>
  </div>

  <div class="decorations" *ngIf="loadImage">
    <picture>
      <source  srcset="/assets/images/shared/decorations-support-desktop@1x.webp,
                      /assets/images/shared/decorations-support-desktop@2x.webp 2x"
                      type="image/webp" media="(min-width: 992px)">
      <source  srcset="/assets/images/shared/decorations-support-desktop@1x.png,
                      /assets/images/shared/decorations-support-desktop@2x.png 2x" media="(min-width: 992px)">
      <source  srcset="/assets/images/shared/decorations-support-tablet@1x.webp,
                      /assets/images/shared/decorations-support-tablet@2x.webp 2x"
                      type="image/webp" media="(min-width: 481px)">
      <source  srcset="/assets/images/shared/decorations-support-tablet@1x.png,
                      /assets/images/shared/decorations-support-tablet@2x.png 2x" media="(min-width: 481px)">
      <source  srcset="/assets/images/shared/decorations-support-mobile@1x.webp,
                      /assets/images/shared/decorations-support-mobile@2x.webp 2x"
                      type="image/webp">
      <img  src="/assets/images/shared/decorations-support-mobile@1x.png"
            srcset="/assets/images/shared/decorations-support-mobile@2x.png 2x" alt="decorations">
    </picture>
  </div>
</section>
