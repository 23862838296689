import { Component, OnInit } from '@angular/core';
import { LocaleService } from '../../services/locale.service';
import { CONTACTS } from '../../contacts.constants';

@Component({
    selector: 'app-payment-options',
    templateUrl: './payment-options.component.html',
    styleUrls: ['./payment-options.component.sass']
})

export class PaymentOptionsComponent implements OnInit {

    public tld = this.localeService.currentContact.country_code;
    public contactList;
    public currentCountry;

    constructor(
        private localeService: LocaleService,
    ) {
        this.contactList = CONTACTS;
        this.currentCountry = this.contactList.filter((c) => c.country_code === this.tld)[0].country;
    }

    ngOnInit() {
    }

    getCurrentCountry() {
        const country = this.contactList.filter((c) => c.country === this.currentCountry);
        return country[0];
    }

    getCurrentPayment() {
        return this.localeService.getContent().PAYMENTS.filter(item => item.country === this.currentCountry)[0];
    }
}
