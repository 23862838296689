import { Component, OnInit, ElementRef } from '@angular/core';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { ScrollToClass } from './../../../../common/scrollTo.class';

@Component({
    selector: 'app-vps',
    templateUrl: './vps.component.html',
    styleUrls: ['./vps.component.sass']
})

export class VpsComponent extends ScrollToClass implements OnInit {

    public URL = URL;
    public tld = this.localeService.currentContact.country_code;
    public symbol = this.localeService.symbol;
    public title = '¡Contrata tu VPS ahora!';
    // load images
    public showImage1 = false;
    public showImage2 = false;

    public dataCards;

    public dataSupport = {
        suptitle: 'Soporte',
        title: '¿Tienes dudas sobre nuestros VPS?',
        text: 'Ponte en contacto con uno de nuestros expertos y él resolverá todas tus dudas.',
        buttonId: 'l-vps-contactar2'
    };

    public dataSupport_AR = {
        suptitle: 'Soporte',
        title: '¿Tenés dudas sobre nuestros VPS?',
        text: 'Ponte en contacto con uno de nuestros expertos y él resolverá todas tus dudas.',
        buttonId: 'l-vps-contactar2'
    };

    public dataSupport_CO = {
        suptitle: 'Soporte ',
        title: '¿Tiene dudas sobre nuestros VPS?',
        text: 'Pongase en contacto con uno de nuestros expertos y él resolverá todas sus dudas.',
        buttonId: 'l-vps-contactar2'
    };

    public options = [
        {
            title: 'Control total para administrar tus proyectos',
            text: 'Configura tu servidor como quieras con acceso root. Administra tu VPS con libertad absoluta.',
            icon: 'icon-folder',
            color: 'color-accent-yellow'
        }, {
            title: 'SSD: 10x más velocidad',
            // tslint:disable-next-line:max-line-length
            text: 'Mayor desempeño para tu servidor virtual. La tecnología SSD proporciona una mucha mayor velocidad de acceso a los datos que la de los discos tradicionales.',
            icon: 'icon-grid',
            color: 'color-accent-green'
        }, {
            title: 'Soporte  en tu idioma',
            // tslint:disable-next-line:max-line-length
            text: 'Contamos con un equipo de expertos para resolver todas tus dudas al que puedes contactar vía telefónica, tickets o chat en línea.',
            icon: 'icon-dns',
            color: 'color-accent-violet'
        }
    ];

    public options_AR = [
        {
            title: 'Control total para administrar tus proyectos',
            text: 'Configurá tu servidor como querás con acceso root. Administrá tu VPS con libertad absoluta.',
            icon: 'icon-folder',
            color: 'color-accent-yellow'
        }, {
            title: 'SSD: 10x más velocidad',
            // tslint:disable-next-line:max-line-length
            text: 'Mayor desempeño para tu servidor virtual. La tecnología SSD proporciona una mucha mayor velocidad de acceso a los datos que la de los discos tradicionales.',
            icon: 'icon-grid',
            color: 'color-accent-green'
        }, {
            title: 'Soporte  en tu idioma',
            // tslint:disable-next-line:max-line-length
            text: 'Contamos con un equipo de expertos para resolver todas tus dudas al que podés contactar vía telefónica, tickets o chat en línea.',
            icon: 'icon-dns',
            color: 'color-accent-violet'
        }
    ];

    public options_CO = [
        {
            title: 'Control total para administrar sus proyectos',
            text: 'Configure su servidor como quiera con acceso root. Administre su VPS con libertad absoluta.',
            icon: 'icon-folder',
            color: 'color-accent-yellow'
        }, {
            title: 'SSD: 10x más velocidad',
            // tslint:disable-next-line:max-line-length
            text: 'Mayor desempeño para su servidor virtual. La tecnología SSD proporciona una mucha mayor velocidad de acceso a los datos que la de los discos tradicionales.',
            icon: 'icon-grid',
            color: 'color-accent-green'
        }, {
            title: 'Soporte  en tu idioma',
            // tslint:disable-next-line:max-line-length
            text: 'Contamos con un equipo de expertos para resolver todas sus dudas al que puedes contactar vía telefónica, tickets o chat en línea.',
            icon: 'icon-dns',
            color: 'color-accent-violet'
        }
    ];

    public additionalPlugins = [
        {
            title: 'cPanel',
            text: 'Administra y gestiona tu web hosting de forma más sencilla y rápida con cPanel.',
            price: this.tld === 'cl' ? 20000 : 100,
            icon: 'icon-cpanel',
            color: 'bg-accent-orange',
            iconWidth: 27
        }, {
            title: 'Softaculous',
            text: 'Dale a tus cientes el chance de optar entre las más de 450 apps de nuestro instalador.',
            price: this.tld === 'cl' ? 1000 : 5,
            icon: 'icon-soft',
            color: 'bg-accent-lightgreen',
            iconWidth: 24
        }
    ];

    public additionalPlugins_AR = [
        {
            title: 'cPanel',
            text: 'Administrá y gestioná tu web hosting de forma más sencilla y rápida con cPanel.',
            price: 6000,
            icon: 'icon-cpanel',
            color: 'bg-accent-orange',
            iconWidth: 27
        }, {
            title: 'Softaculous',
            text: 'Dale a tus cientes el chance de optar entre las más de 450 apps de nuestro instalador.',
            price: 400,
            icon: 'icon-soft',
            color: 'bg-accent-lightgreen',
            iconWidth: 24
        }
    ];

    public additionalPlugins_CO = [
        {
            title: 'cPanel',
            text: 'Administre y gestione su web hosting de forma más sencilla y rápida con cPanel.',
            price: 90000,
            icon: 'icon-cpanel',
            color: 'bg-accent-orange',
            iconWidth: 27
        }, {
            title: 'Softaculous',
            text: 'Dale a sus cientes el chance de optar entre las más de 450 apps de nuestro instalador.',
            price: 5000,
            icon: 'icon-soft',
            color: 'bg-accent-lightgreen',
            iconWidth: 24
        }
    ];

    constructor(
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private el: ElementRef
    ) {
        super(el);
        this.dataCards = this.localeService.getContent().plans;
    }

    ngOnInit() {
        if (this.tld === 'ar') {
            this._setMeta_AR();
            this.options = this.options_AR;
            this.additionalPlugins = this.additionalPlugins_AR;
            this.dataSupport = this.dataSupport_AR;
            this.title = '¡Contratá tu VPS ahora!';
        } else if (this.tld === 'co') {
            this._setMeta_CO();
            this.options = this.options_CO;
            this.additionalPlugins = this.additionalPlugins_CO;
            this.dataSupport = this.dataSupport_CO;
            this.title = '¡Contrate su VPS ahora!';
        } else if (this.tld === 'pe') {
            this._setMeta_PE();
        } else if (this.tld === 'mx') {
            this._setMeta_MX();
            this.additionalPlugins[0].price = 580;
            this.additionalPlugins[1].price = 25;
        } else {
            this._setMeta_CL();
        }
    }

    public navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }

    private _setMeta_CL() {
        this._meta.setTitle('LIVEHOST | SSD Cloud Server de Alto Rendimiento');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Si un hosting compartido no es para ti, de seguro un VPS completará tus expectativas, desde $2.500 Mensuales + IVA disfruta de recursos dedicados');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'vps ssd cloud server, cloud server, vps ssd, ssd cloud server, vps chile, cloud server chile, cloud server hosting');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD VPS Cloud Server - LiveHost');
        this._meta.setTag('og:locale', 'es_CL');
    }

    private _setMeta_PE() {
        this._meta.setTitle('LIVEHOST | SSD Cloud Server de Alto Rendimiento');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Si un hosting compartido no es para ti, de seguro un VPS completará tus expectativas, desde S/15 Mensuales + IVA disfruta de recursos dedicados');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'vps ssd cloud server, cloud server, vps ssd, ssd cloud server, vps peru, cloud server peru, cloud server hosting');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD VPS Cloud Server - LiveHost');
        this._meta.setTag('og:locale', 'es_PE');
    }

    private _setMeta_CO() {
        this._meta.setTitle('LIVEHOST | SSD Cloud Server de Alto Rendimiento');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Si un hosting compartido no es para usted, de seguro un VPS completará sus expectativas, desde $11.700 Mensuales disfrute de recursos dedicados');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'vps ssd cloud server, cloud server, vps ssd, ssd cloud server, vps colombia, cloud server colombia, cloud server hosting');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD VPS Cloud Server - LiveHost');
        this._meta.setTag('og:locale', 'es_CO');
    }

    private _setMeta_AR() {
        this._meta.setTitle('LIVEHOST | SSD Cloud Server de Alto Rendimiento');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Si un hosting compartido no es para ti, de seguro un VPS completará tus expectativas, desde $150 Mensuales disfruta de recursos dedicados');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'vps ssd cloud server, cloud server, vps ssd, ssd cloud server, vps argentina, cloud server argentina, cloud server hosting');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD VPS Cloud Server - LiveHost');
        this._meta.setTag('og:locale', 'es_AR');
    }

    private _setMeta_MX() {
        this._meta.setTitle('LIVEHOST | SSD Cloud Server de Alto Rendimiento');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Si un hosting compartido no es para ti, de seguro un VPS completará tus expectativas, desde $90 Mensuales disfruta de recursos dedicados');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'vps ssd cloud server, cloud server, vps ssd, ssd cloud server, vps méxico, cloud server méxico, cloud server hosting');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD VPS Cloud Server - LiveHost');
        this._meta.setTag('og:locale', 'es_MX');
    }
}
