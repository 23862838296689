<div class="tableDomain" *ngIf="!isLoading; else loading">
  <div class="tableDomain__tr" *ngFor="let tableRow of dataTableDomain">

    <div class="tableDomain__td">
      <div class="icon color-accent-green" *ngIf="tableRow.state">
        <svg-icon-sprite
          [src]="'/assets/images/sprites/sprite.svg#icon-check'"
          [width]="'18px'"
          [classes]="'icon-check'">
        </svg-icon-sprite>
      </div>
      <div class="icon color-accent-orange" *ngIf="!tableRow.state">
        <svg-icon-sprite
          [src]="'/assets/images/sprites/sprite.svg#icon-close'"
          [width]="'16px'"
          [classes]="'icon-close'">
        </svg-icon-sprite>
      </div>
    </div>

    <div class="tableDomain__td upper small">{{tableRow.prefix}}</div>
    <div class="tableDomain__td">{{selectedDomain}}{{tableRow.prefix}}</div>

    <div class="br" *ngIf="isMobile"></div>

    <div class="tableDomain__td">
      <b class="price" *ngIf="tableRow.state">
        <span class="currency">{{symbol}}</span> {{tableRow.price | currencyFormat}}
      </b>
      <span class="sub_price" *ngIf="tableRow.state" i18n>Anual</span>
      <b class="not_available" *ngIf="!tableRow.state" i18n>No disponible</b>
    </div>
    <div class="tableDomain__td">
      <button id="{{tableRow.btnId}}"
              class="btn"
              mat-button
              [disabled]="!tableRow.state"
              (click)="goToUrl(tableRow.btnLink)" i18n>
        Comprar <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>

  </div>

</div>

<p class="subtext" *ngIf="subtext !== undefined && !isLoading" i18n>{{subtext}}</p>

<ng-template #loading>
  <mat-progress-bar class="domain-progress" mode="indeterminate"></mat-progress-bar>
</ng-template>
