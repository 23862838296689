import { Component, OnInit, HostListener } from '@angular/core';
import { LocaleService } from '../../services/locale.service';

@Component({
    selector: 'app-table-price-backup',
    templateUrl: './table-price-backup.component.html',
    styleUrls: ['./table-price-backup.component.sass']
})

export class TablePriceBackupComponent implements OnInit {

    public isMobile = false;
    public symbol;
    public dataTableServer;
    public isOpenInfoTable = 0;

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobile = window.innerWidth <= 767 ? true : false;
    }


    constructor(
        private localeService: LocaleService
    ) {
        this.symbol = localeService.symbol;
    }

    ngOnInit() {
        this.dataTableServer = this.localeService.getContent().DATA_TABLE_SERVER_BACKUP;
        this.isMobile = window.innerWidth <= 767 ? true : false;
    }

    toggleOnTablet(index) {
        if (window.innerWidth <= 767) {
            this.isOpenInfoTable = index;
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }
}
