import { Component, OnInit } from '@angular/core';
import { URL } from '../../environments/environment';

@Component({
    selector: 'app-payment-methods',
    templateUrl: './payment-methods.component.html',
    styleUrls: ['./payment-methods.component.sass']
})

export class PaymentMethodsComponent implements OnInit {
    public URL = URL;

    constructor() {
    }

    ngOnInit() {
    }
}
