<div class="tablePrice tablePrice__mobile">

  <div class="tablePrice__header" *ngIf="!isMobile">
    <div class="tablePrice__header--td" *ngFor="let headerCell of dataTableServer.table_header">
      <b>{{headerCell}}</b>
    </div>
  </div>

  <div class="tablePrice__body">
    <div class="tablePrice__body--row"
        [class.open]="isOpenInfoTable === i"
        *ngFor="let tableRow of dataTableServer.table_body; let i = index">

      <div class="tablePrice__body--nav" *ngIf="isMobile" (click)="toggleOnTablet(i)">
        <div class="title upper">ram <br> <b>{{tableRow.rom}}</b></div>
        <div class="price accent-primary">
          <b><span class="currency">{{symbol}}</span>{{tableRow.price | currencyFormat}}</b>
          <span class="sub_price color-secondary" i18n>Mensual</span>
        </div>
        <button mat-icon-button><mat-icon>keyboard_arrow_down</mat-icon></button>
      </div>

      <ng-container *ngIf="!isMobile || isOpenInfoTable === i">
        <div class="tablePrice__body--td">
          <b class="title upper" *ngIf="isMobile">ram</b>
          <span class="color-secondary">{{tableRow.rom}}</span>
        </div>
        <div class="tablePrice__body--td">
          <b class="title upper" *ngIf="isMobile">vcpu</b>
          <span class="color-secondary">{{tableRow.vcpu}}</span>
        </div>
        <div class="tablePrice__body--td">
          <b class="title upper" *ngIf="isMobile" i18n>ssd disco</b>
          <span class="color-secondary">{{tableRow.ssd}}</span>
        </div>
        <div class="tablePrice__body--td">
          <b class="title upper" *ngIf="isMobile" i18n>TRÁFICO</b>
          <span class="color-secondary">{{tableRow.trafic}}</span>
        </div>
        <div class="tablePrice__body--td" *ngIf="!isMobile">
          <b class="price"><span class="currency">{{symbol}}</span>{{tableRow.price | currencyFormat}}</b>
          <span class="sub_price color-secondary" i18n>Mensual</span>
        </div>
        <div class="tablePrice__body--td">
          <button class="btn"
                  [class.btnAccentTransp]="isMobile"
                  [class.rounded]="isMobile"
                  mat-button
                  (click)="goToUrl(tableRow.btnLink)" i18n>
            Comprar <mat-icon *ngIf="!isMobile">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </ng-container>

    </div>
  </div>

</div>
