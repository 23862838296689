<div class="options" [ngClass]="class">
  <div class="options__container">
    <div class="options__item" *ngFor="let item of options">
      <div class="icon" [ngClass]="item.color">
        <svg-icon-sprite
          [src]="'/assets/images/sprites/sprite.svg#' + item.icon"
          [width]="'22px'"
          [classes]="item.icon"
          *ngIf="loadImage">
        </svg-icon-sprite>
      </div>
      <div class="title" i18n>{{item.title}}</div>
      <div class="text" i18n>{{item.text}}</div>
    </div>
  </div>
</div>
