export const CONTENT_MX = {
    plans: {
        web_hosting: {
            recommendedList: [
                'Bloggers',
                'Diseñadores',
                'Desarrolladores'
            ],
            plans: [
                {
                    id: 1,
                    name: 'Estándar',
                    price: 550,
                    buttonId: 'l-h-contratar-estandar',
                    buttonUrl: 'https://panel.livehost.host/cart.php?a=add&pid=87&currency=8&tld=.com.mx',
                    list: [
                        '<b>Espacio Web SSD</b> <a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a>',
                        '<b>Sitios web</b> 1',
                        '<b>Cuentas de Correo</b> Ilimitadas',
                        '<b>Transferencia mensual</b> Ilimitada',
                        '<b>Base de datos</b> 10'
                    ],
                    text: 'Da tus primeros pasos con un hosting sencillo y amigable, elige el plan estándar.',
                    link: '/web-hosting/'
                }, {
                    id: 2,
                    name: 'Plus',
                    price: 650,
                    buttonId: 'l-h-contratar-plus',
                    buttonUrl: 'https://panel.livehost.host/cart.php?a=add&pid=86&currency=8&tld=.com.mx',
                    list: [
                        '<b>Espacio Web SSD</b> <a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a>',
                        '<b>Sitios web</b> Ilimitados',
                        '<b>Cuentas de Correo</b> Ilimitadas',
                        '<b>Transferencia mensual</b> Ilimitada',
                        '<b>Base de datos</b> Ilimitadas'
                    ],
                    text: 'Si necesitas más recursos y velocidad este plan es ideal para ti. Con todo sino ¿para qué?',
                    link: '/web-hosting/'
                }
            ]
        },
        web_hosting_full: {
            plans: [
                {
                    id: 1,
                    name: 'Pro',
                    price: 1350,
                    buttonId: 'l-whf-contratar-pro',
                    buttonUrl: 'https://panel.livehost.host/cart.php?a=add&pid=3&currency=8&tld=.com.mx',
                    list: [
                        '<b>Espacio Web SSD</b> <a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a>',
                        '<b>Sitios web</b> 1',
                        '<b>Cuentas de Correo</b> Ilimitadas',
                        '<b>Transferencia mensual</b> Ilimitada',
                        '<b>Base de datos</b> Ilimitadas',
                        '<b>Respaldos</b> Ilimitados',
                        '<b>Inodos</b> 400.000',
                        '<b>Cuentas por Núcleos</b> 4'
                    ]
                }, {
                    id: 2,
                    name: 'Máster',
                    price: 2550,
                    buttonId: 'l-whf-contratar-master',
                    buttonUrl: 'https://panel.livehost.host/cart.php?a=add&pid=4&currency=8&tld=.com.mx',
                    list: [
                        '<b>Espacio Web SSD</b> <a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a>',
                        '<b>Sitios web</b> 12',
                        '<b>Cuentas de Correo</b> Ilimitadas',
                        '<b>Transferencia mensual</b> Ilimitada',
                        '<b>Base de datos</b> Ilimitadas',
                        '<b>Respaldos</b> Ilimitados',
                        '<b>Inodos</b> 400.000',
                        '<b>Cuentas por Núcleos</b> 4'
                    ]
                }
            ]
        },
        pymes: {
            recommendedList: [
                'Diseñadores',
                'Negocios',
                'Ecommerce'
            ],
            plans: [
                {
                    id: 1,
                    name: 'Máster',
                    price: 2550,
                    buttonId: 'l-h-contratar-estandar',
                    buttonUrl: 'https://panel.livehost.host/cart.php?a=add&pid=87&currency=1&tld=.cl',
                    list: [
                        '<b>Espacio Web SSD</b> Ilimitado',
                        '<b>Sitios web</b> 1',
                        '<b>Cuentas de Correo</b> Ilimitadas',
                        '<b>Transferencia mensual</b> Ilimitada',
                        '<b>Base de datos</b> 10'
                    ],
                    text: 'Que no te limite un mayor tráfico llega al último nivel. Más capacidad y velocidad que un plan Pro',
                    link: '/web-hosting-full/'
                }, {
                    id: 2,
                    name: 'Pro',
                    price: 1350,
                    buttonId: 'l-h-contratar-plus',
                    buttonUrl: 'https://panel.livehost.host/cart.php?a=add&pid=86&currency=1&tld=.cl',
                    list: [
                        '<b>Espacio Web SSD</b> Ilimitado',
                        '<b>Sitios web</b> Ilimitados',
                        '<b>Cuentas de Correo</b> Ilimitadas',
                        '<b>Transferencia mensual</b> Ilimitada',
                        '<b>Base de datos Ilimitadas'
                    ],
                    text: 'Un hosting diseñado para profesionales, tres veces más potencia y rendimiento a tu alcance',
                    link: '/web-hosting-full/'
                }
            ]
        },
        vps: {
            recommendedList: [
                'Bloggers',
                'Diseñadores',
                'Desarrolladores'
            ],
            plans: [
                {
                    id: 1,
                    name: 'El más popular',
                    price: 90,
                    buttonId: 'l-h-contratar-estandar',
                    buttonUrl: 'https://panel.livehost.host/cart.php?a=add&pid=87&currency=1&tld=.cl',
                    list: [
                        '<b>Espacio Web SSD</b> Ilimitado',
                        '<b>Sitios web</b> 1',
                        '<b>Cuentas de Correo</b> Ilimitadas',
                        '<b>Transferencia mensual</b> Ilimitada',
                        '<b>Base de datos</b> 10'
                    ],
                    text: 'Ten más control y siente la seguridad que te concede un VPS desde $90 mensuales',
                    link: '/ssd-vps-cloudserver/'
                }
            ]
        },
        reseller: {
            plans: [
                {
                    id: 1,
                    name: 'Bronce',
                    price: 320,
                    buttonId: 'l-r-contratar-bronce',
                    buttonUrl: 'https://panel.livehost.host/cart.php?a=add&pid=33&currency=8&tld=.com.mx',
                    list: [
                        '<b>Espacio Web</b> 35 GB',
                        '<b>Transferencia</b> 300 GB mensual',
                        '<b>Sitios</b> Ilimitados',
                        '<b>Cuentas de correo</b> Ilimitadas',
                        '<b>Cuentas cPanel</b> Ilimitadas'
                    ]
                }, {
                    id: 2,
                    name: 'Plata',
                    price: 770,
                    buttonId: 'l-r-contratar-plata',
                    buttonUrl: 'https://panel.livehost.host/cart.php?a=add&pid=34&currency=8&tld=.com.mx',
                    list: [
                        '<b>Espacio Web</b> 75 GB',
                        '<b>Transferencia</b> 500 GB mensual',
                        '<b>Sitios</b> Ilimitados',
                        '<b>Cuentas de correo</b> Ilimitadas',
                        '<b>Cuentas cPanel</b> Ilimitadas'
                    ]
                }, {
                    id: 2,
                    name: 'Platinum',
                    price: 1400,
                    buttonId: 'l-r-contratar-platinum',
                    buttonUrl: 'https://panel.livehost.host/cart.php?a=add&pid=37&currency=8&tld=.com.mx',
                    list: [
                        '<b>Espacio Web</b> 155 GB',
                        '<b>Transferencia</b> 1200 GB mensual',
                        '<b>Sitios</b> Ilimitados',
                        '<b>Cuentas de correo</b> Ilimitadas',
                        '<b>Cuentas cPanel</b> Ilimitadas'
                    ]
                }
            ]
        }
    },
    DATA_TABLE_SERVER: {
        table_header: [
            'RAM',
            'VCPUS',
            'SSD DISCO',
            'TRÁFICO',
            'PRECIO',
            ''
        ],
        table_body: [
            {
                rom: '1 GB',
                vcpu: '1 VCPU',
                ssd: '20 GB',
                trafic: '1 TB',
                price: 90,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=90&currency=8'
            }, {
                rom: '2 GB',
                vcpu: '1 VCPU',
                ssd: '50 GB',
                trafic: '2 TB',
                price: 180,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=91&currency=8'
            }, {
                rom: '4 GB',
                vcpu: '2 VCPU',
                ssd: '80 GB',
                trafic: '4 TB',
                price: 360,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=92&currency=8'
            }, {
                rom: '8 GB',
                vcpu: '4 VCPU',
                ssd: '160 GB',
                trafic: '5 TB',
                price: 720,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=93&currency=8'
            }, {
                rom: '16 GB',
                vcpu: '6 VCPU',
                ssd: '320 GB',
                trafic: '6 TB',
                price: 1400,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=94&currency=8'
            }, {
                rom: '32 GB',
                vcpu: '8 VCPU',
                ssd: '640 GB',
                trafic: '7 TB',
                price: 2800,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=95&currency=8'
            }, /*{
                rom: '48 GB',
                vcpu: '12 VCPU',
                ssd: '960 GB',
                trafic: '8 TB',
                price: 4400,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=18&currency=4'
            },*/
            {
                rom: '64 GB',
                vcpu: '16 VCPU',
                ssd: '1280 GB',
                trafic: '9 TB',
                price: 5900,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=96&currency=8'
            }, {
                rom: '96 GB',
                vcpu: '20 VCPU',
                ssd: '1920 GB',
                trafic: '10 TB',
                price: 8800,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=97&currency=8'
            }, {
                rom: '128 GB',
                vcpu: '24 VCPU',
                ssd: '2560 GB',
                trafic: '11 TB',
                price: 11700,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=98&currency=8'
            }/*, {
                rom: '192 GB',
                vcpu: '32 VCPU',
                ssd: '3840 GB',
                trafic: '12 TB',
                price: 17600,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=18&currency=4'
            }
            */
        ]
    },
    DATA_DOMAIN_ZONES: {
        table_header: [
            'Valores en Pesos Mexicanos',
            '1 Año',
            '2 Años',
            '3 Años'
        ],
        table_body: [
            {
                name: '.CL',
                price_period_1: '380',
                price_period_2: '760',
                price_period_3: '1140'
            }, {
                name: '.PE',
                price_period_1: '1700',
                price_period_2: '3400',
                price_period_3: '5100'
            }, {
                name: '.COM.PE',
                price_period_1: '1700',
                price_period_2: '3400',
                price_period_3: '5100'
            }, {
                name: '.NET.PE',
                price_period_1: '1700',
                price_period_2: '3400',
                price_period_3: '5100'
            }, {
                name: '.CO (Colombia)',
                price_period_1: '1150',
                price_period_2: '2300',
                price_period_3: '3450'
            }, {
                name: '.COM.CO',
                price_period_1: '400',
                price_period_2: '800',
                price_period_3: '1200'
            }, {
                name: '.NET.CO',
                price_period_1: '400',
                price_period_2: '800',
                price_period_3: '1200'
            }, {
                name: '.COM.AR',
                price_period_1: '1700',
                price_period_2: '3400',
                price_period_3: '5100'
            }, {
                name: '.COM.MX',
                price_period_1: '600',
                price_period_2: '1200',
                price_period_3: '1800'
            }, {
                name: '.COM',
                price_period_1: '350',
                price_period_2: '700',
                price_period_3: '1050'
            }, {
                name: '.ORG',
                price_period_1: '350',
                price_period_2: '700',
                price_period_3: '1050'
            }, {
                name: '.NET',
                price_period_1: '350',
                price_period_2: '700',
                price_period_3: '1050'
            }, {
                name: '.PRO',
                price_period_1: '550',
                price_period_2: '1100',
                price_period_3: '1650'
            }, {
                name: '.LTDA',
                price_period_1: '1600',
                price_period_2: '3200',
                price_period_3: '4800'
            }, {
                name: '.IO',
                price_period_1: '1700',
                price_period_2: '3400',
                price_period_3: '5100'
            }, {
                name: '.BLOG',
                price_period_1: '900',
                price_period_2: '1800',
                price_period_3: '2700'
            }, {
                name: '.US',
                price_period_1: '350',
                price_period_2: '700',
                price_period_3: '1050'
            }, {
                name: '.INFO',
                price_period_1: '350',
                price_period_2: '700',
                price_period_3: '1050'
            }, {
                name: '.NAME',
                price_period_1: '350',
                price_period_2: '700',
                price_period_3: '1050'
            }, {
                name: '.TV',
                price_period_1: '1350',
                price_period_2: '2700',
                price_period_3: '4050'
            }, {
                name: '.BIZ',
                price_period_1: '350',
                price_period_2: '700',
                price_period_3: '1050'
            }
        ]
    },
    PAYMENTS: [
        {
            country: 'Chile',
            payments_method: [
                {
                    method: 'Transferencia',
                    state: 'Activación inmediata',
                    // bank_info: {
                    //     bank: 'Banco Itaú S.A.',
                    //     number: '№ 208905929',
                    //     company: 'LiveHost Spa',
                    //     rut: '76.353.391-3',
                    //     email: 'pago@livehost.cl',
                    // }
                    logos: ['logo-linkify-circle']
                }, {
                    method: 'Tarjeta de crédito o débito',
                    state: 'Activación <br> inmediata',
                    logos: ['logo-webpay'],
                // }, {
                //     method: 'Khipu',
                //     state: 'Activación <br> inmediata',
                //     logos: ['logo-khipu'],
                },
                // {
                //     method: 'Servipag Online',
                //     state: 'Activación entre 24 <br> y 48 horas hábiles',
                //     logos: ['logo-servipag'],
                // }, {
                //     method: 'Cupón de pago Servipag',
                //     state: 'Activación entre 24 <br> y 48 horas hábiles',
                //     logos: ['logo-servipag'],
                // },
                {
                    method: 'Paypal',
                    state: 'Activación <br> inmediata',
                    logos: ['logo-paypal'],
                }
            ]
        }, {
            country: 'Perú',
            payments_method: [
                {
                    method: 'Transferencia o <br> depósito bancario',
                    state: 'Activación entre 24 <br> y 48 horas hábiles',
                    payment_info: [
                        {
                            point_1: 'PagoMaster S.A.C.',
                            point_2: '194-2157025-0-30',
                            point_4: '194-2171047-1-76',
                            point_6: 20565999754,
                            email: 'pago@livehost.cl',
                            logos: ['logo-bcp']
                        }, {
                            point_1: 'PagoMaster S.A.C.',
                            point_2: '107-300105942-0',
                            point_3: '003-107-003001059420-05',
                            point_4: '107-300105943-8',
                            point_5: '003001059438-07',
                            point_6: 20565999754,
                            email: 'pago@livehost.cl',
                            logos: ['logo-interbank']
                        }
                    ]
                }, {
                    method: 'Pago efectivo',
                    state: 'Activación <br> inmediata',
                    logos: ['logo-pago-efectivo'],
                }, {
                    method: 'Pago en bancos',
                    state: 'Activación entre 24 <br> y 48 horas hábiles',
                    logos: ['logo-bcp'],
                }, {
                    method: 'Tarjeta de crédito o <br> débito',
                    state: 'Activación en 48 <br> horas hábiles',
                    logos: [
                        'logo-diners',
                        'logo-visa',
                        'logo-american-express',
                        'logo-mastercard'
                    ],
                }, {
                    method: 'Paypal',
                    state: 'Activación <br> inmediata',
                    logos: ['logo-paypal'],
                }
            ]
        }, {
            country: 'México',
            payments_method: [
                {
                    method: 'Pago efectivo',
                    state: 'Activación en 48 <br> horas hábiles',
                    logos: [
                        'logo-oxxo',
                        'logo-super-farmacia',
                        'logo-eleven',
                        'logo-ahorro'
                    ],
                }, {
                    method: 'Tarjeta de crédito o <br> débito',
                    state: 'Activación inmediata',
                    logos: [
                        'logo-american-express',
                        'logo-mastercard',
                        'logo-visa',
                        'logo-diners'
                    ],
                }, {
                    method: 'Pago en Bancos',
                    state: 'Activación entre 24 <br> y 48 horas hábiles',
                    logos: [
                        'logo-santander',
                        'logo-bankomer',
                        'logo-scotiabank'
                    ],
                }, {
                    method: 'Paypal',
                    state: 'Activación <br> inmediata',
                    logos: ['logo-paypal'],
                }
            ]
        }, {
            country: 'Argentina',
            payments_method: [
                {
                    method: 'Pago efectivo',
                    state: 'Activación en 48 <br> horas hábiles',
                    logos: [
                        'logo-cobro-express',
                        'logo-pago-facil',
                        'logo-provincia',
                        'logo-ripsa',
                        'logo-rapi-pago'
                    ],
                }, {
                    method: 'Tarjeta de crédito o <br> débito',
                    state: 'Activación <br> inmediata',
                    logos: [
                        'logo-american-express',
                        'logo-argencard',
                        'logo-tarjeta-shopping',
                        'logo-mastercard',
                        'logo-cencosud',
                        'logo-cabal',
                        'logo-visa'
                    ],
                }, {
                    method: 'Paypal',
                    state: 'Activación <br> inmediata',
                    logos: ['logo-paypal'],
                }
            ]
        }, {
            country: 'Colombia',
            payments_method: [
                {
                    method: 'Pago efectivo',
                    state: 'Activación en 24 <br> horas hábiles',
                    logos: [
                        'logo-baloto',
                        'logo-paga-todo',
                        'logo-gana-gana',
                        'logo-su-chance',
                        'logo-au',
                        'logo-apuestas-cucuta',
                        'logo-gana',
                        'logo-acertemos',
                        'logo-laperla',
                        'logo-efecty',
                        'logo-jer'
                    ],
                }, {
                    method: 'Pago en bancos',
                    state: 'Activación <br> inmediata',
                    logos: [
                        'logo-banco-de-bagota',
                        'logo-bancolombia',
                        'logo-davivienda'
                    ],
                }, {
                    method: 'Tarjetas de crédito',
                    state: 'Activación en 48 <br> horas hábiles',
                    logos: [
                        'logo-diners',
                        'logo-visa',
                        'logo-american-express',
                        'logo-mastercard',
                        'logo-codensa'
                    ],
                }, {
                    method: 'Débito bancario PSE',
                    state: 'Activación en 48 <br> horas hábiles',
                    logos: ['logo-pse'],
                }, {
                    method: 'PAYPAL',
                    state: 'Activación <br> inmediata',
                    logos: ['logo-paypal'],
                }
            ]
        }
    ],
    DATA_TABLE_SERVER_BACKUP: {
        table_header: [
            'RAM',
            'VCPUS',
            'SSD DISCO',
            'TRÁFICO',
            'BACKUP',
            'PRECIO',
            ''
        ],
        table_body: [
            {
                rom: '1 GB',
                vcpu: '1 VCPU',
                ssd: '20 GB',
                trafic: '1 TB',
                backup: 'Incluido',
                price: 108,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=99&currency=8'
            }, {
                rom: '2 GB',
                vcpu: '1 VCPU',
                ssd: '50 GB',
                trafic: '2 TB',
                backup: 'Incluido',
                price: 216,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=100&currency=8'
            }, {
                rom: '4 GB',
                vcpu: '2 VCPU',
                ssd: '80 GB',
                trafic: '4 TB',
                backup: 'Incluido',
                price: 432,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=101&currency=8'
            }, {
                rom: '8 GB',
                vcpu: '4 VCPU',
                ssd: '160 GB',
                trafic: '5 TB',
                backup: 'Incluido',
                price: 864,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=102&currency=8'
            }, {
                rom: '16 GB',
                vcpu: '6 VCPU',
                ssd: '320 GB',
                trafic: '6 TB',
                backup: 'Incluido',
                price: 1680,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=103&currency=8'
            }, {
                rom: '32 GB',
                vcpu: '8 VCPU',
                ssd: '640 GB',
                trafic: '7 TB',
                backup: 'Incluido',
                price: 3360,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=104&currency=8'
            }, /*{
                rom: '48 GB',
                vcpu: '12 VCPU',
                ssd: '960 GB',
                trafic: '8 TB',
                backup: 'Incluido',
                price: 5280,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=NULL&currency=4'
            },*/
            {
                rom: '64 GB',
                vcpu: '16 VCPU',
                ssd: '1280 GB',
                trafic: '9 TB',
                backup: 'Incluido',
                price: 7080,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=105&currency=8'
            }, {
                rom: '96 GB',
                vcpu: '20 VCPU',
                ssd: '1920 GB',
                trafic: '10 TB',
                backup: 'Incluido',
                price: 10560,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=106&currency=8'
            }, {
                rom: '128 GB',
                vcpu: '24 VCPU',
                ssd: '2560 GB',
                trafic: '11 TB',
                backup: 'Incluido',
                price: 14040,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=107&currency=8'
            }/*, {
                rom: '192 GB',
                vcpu: '32 VCPU',
                ssd: '3840 GB',
                trafic: '12 TB',
                backup: 'Incluido',
                price: 21120,
                btnLink: 'https://panel.livehost.host/cart.php?a=add&pid=NULL&currency=4'
            }
            */
        ]
    }
};
