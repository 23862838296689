<div class="horizontalSliderWrapper">
  <div class="inner">
    <div class="horizontalSliderWrapper__info">
      <div class="icon">
        <svg-icon-sprite
          [src]="'/assets/images/sprites/sprite.svg#icon-quote'"
          [width]="'200px'"
          [classes]="'icon-quote'">
        </svg-icon-sprite>
      </div>
      <div class="suptitle upper color-accent-green" i18n>Testimonios</div>
      <h2 class="title-2" i18n>Lo que opinan nuestros clientes</h2>
    </div>
  </div>
  <div class="inner">
    <div class="wrapImg">
      <picture>
        <source srcset="/assets/images/slider/slider-circle-desktop@1x.png,
                        /assets/images/slider/slider-circle-desktop@2x.png 2x" media="(min-width: 992px)">
        <source srcset="/assets/images/slider/slider-circle-tablet@1x.png,
                        /assets/images/slider/slider-circle-tablet@2x.png 2x" media="(min-width: 671px)">
        <img  src="/assets/images/slider/slider-circle-mobile@1x.png"
              srcset="/assets/images/slider/slider-circle-mobile@2x.png 2x" alt="circle">
      </picture>
    </div>
    <div class="horizontalSlider slider-ts">
      <div *ngFor="let item of reviewsList">
        <div class="horizontalSlider__item">
          <div class="icon">
            <svg-icon-sprite
              [src]="'/assets/images/sprites/sprite.svg#icon-quote'"
              [width]="'32px'"
              [classes]="'icon-quote'">
            </svg-icon-sprite>
          </div>
          <div class="horizontalSlider__item--container">
            <div class="text" i18n>{{item.text}}</div>
            <div class="reviewer">
              <div class="avatar"><img src="/assets/images/slider/{{item.image}}.jpg" alt="avatar"></div>
              <div class="name" i18n>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
