<section class="firstScreen has-wave bottom">
  <div class="wave wave-5">
    <img  src="/assets/images/shared/wave5@1x.png"
          srcset="/assets/images/shared/wave5@2x.png 2x" alt="wave">
  </div>

  <img  src="/assets/images/payment-methods/firstscreen-payment-decorations@1x.png"
        srcset="/assets/images/payment-methods/firstscreen-payment-decorations@2x.png 2x" alt="decorations" class="decorations">

  <div class="container">
    <div class="firstScreen__info">
      <div class="suptitle upper color-accent-green center" i18n>oh no!</div>
      <h1 class="title-1 center" i18n>Error 404</h1>
      <div class="subheading center" i18n>No hemos encontrado la página que estás buscando, por favor intenta volver a la página anterior o contacta a soporte.</div>
    </div>
  </div>

</section>

<!-- START contentWrapper -->
<section class="contentWrapper has-wave bottom">
  <div class="wave wave-6">
    <picture>
      <source srcset="/assets/images/shared/wave6@1x.png,
                      /assets/images/shared/wave6@2x.png 2x" media="(min-width: 992px)">
      <img  src="/assets/images/shared/wave6-tablet@1x.png"
            srcset="/assets/images/shared/wave6-tablet@2x.png 2x" alt="wave">
    </picture>
  </div>

  <div class="wrapError">
    <div class="title-2" i18n>Página no encontrada</div>

    <button mat-button class="btn btnAccent rounded" i18n [routerLink]="['/']">Volver</button>
  </div>

  <div class="contactNumber">
    <div class="body-2 color-secondary" i18n>Si lo prefieres puedes llamarnos al:</div>
    <a href="tel:{{contact.main_phone_href}}" class="contactNumber__phone">
      <svg-icon-sprite
        [src]="'/assets/images/sprites/sprite.svg#icon-outgoing-call'"
        [width]="'28px'"
        [classes]="'icon-outgoing-call'">
      </svg-icon-sprite>
      <span class="title-2" i18n>{{contact.main_phone}}</span>
    </a>
    <div class="currentCountry">
      <span class="flag">
        <img src="{{contact.flag_url}}" alt="{{contact.country}}">
      </span>
      <span class="country color-secondary" i18n>{{contact.city}}, {{contact.country}}</span>
    </div>
  </div>

</section>
<!-- END -->

<app-footer [loadImage]="true"></app-footer>
