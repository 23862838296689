<div class="infoCards" [ngClass]="class">
  <div class="infoCards__container">
    <div class="infoCards__card" *ngFor="let card of data">
      <div class="icon" [ngClass]="card.color" [class.circle]="isIconCircle">
        <svg-icon-sprite
          [src]="'/assets/images/sprites/sprite.svg#' + card.icon"
          [width]="isIconCircle ? '22px' : '54px'"
          [classes]="card.icon"
          *ngIf="loadImage">
        </svg-icon-sprite>
      </div>
      <div class="title" i18n>{{card.title}}</div>
      <div class="text" i18n>{{card.text}}</div>
    </div>
  </div>
</div>
