import { Component, OnInit, ViewChild } from '@angular/core';
import { URL } from '../../environments/environment';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contact } from '../contact';
import { LocaleService } from '../services/locale.service';
import { CONTACTS } from '../contacts.constants';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { HttpClient, HttpParams } from '@angular/common/http';
import { concat, delay, retryWhen, take } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.sass']
})

export class ContactsComponent implements OnInit {
    public URL = URL;

    // load images
    public showImage1 = false;

    public contact: Contact;
    public contactList = CONTACTS;
    public currentCountry;

    public form: FormGroup;
    public disableButton = false;
    public isSendForm;
    captcha: any;
    siteKey = '6LeFGJMUAAAAAEDLJLme8uOEGMOTwsjYB6v-gZe7';
    activeCaptcha = false;

    public tld = this.localeService.currentContact.country_code;
    @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

    public contactsArr = [
        {
            icon: 'icon-headphones',
            color: 'bg-accent-orange',
            title: 'Soporte',
            contacts: [
                {
                    soc: 'Ventas@livehost.host'
                }, {
                    soc: 'Soporte@livehost.host'
                }, {
                    soc: 'Pagos@livehost.host'
                },
            ]
        }, {
            icon: 'icon-phone-call',
            color: 'bg-accent-green',
            title: 'Ventas',
            contacts: [
                {
                    soc: 'ventas@livehost.cl',
                }, {
                    soc: 'soporte@livehost.cl'
                }
            ],
            tel: {
                cl: '+56 2 3239 4624',
                co: '+57 601 489 6972',
                pe: '+51 1709 4332',
                ar: '+54 11 3988 9121',
                mx: '+52 55 4169 6410'
            }
        }, {
            icon: 'icon-language',
            color: 'bg-accent-red',
            title: 'Social media',
            contacts: [
                {
                    soc: 'twiter',
                    url: 'LIVEHOST_TWITTER'
                }, {
                    soc: 'facebook',
                    url: 'LIVEHOST_FACEBOOK'
                }, {
                    soc: 'youtube',
                    url: 'LIVEHOST_YOUTUBE'
                }
            ]
        }
    ];

    constructor(
        private localeService: LocaleService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private _http: HttpClient,
        private reCaptchaV3Service: ReCaptchaV3Service,
    ) {
        this.contact = this.contactList.filter((c) => c.country_code === this.tld)[0];
    }

    public ngOnInit() {

        if (this.tld !== 'cl') {
            this.contactsArr[1].contacts[0].soc = 'ventas@livehost.host';
            this.contactsArr[1].contacts[1].soc = 'soporte@livehost.host';
        }

        this.form = this.formBuilder.group({
            name: ['', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]],
            email: ['', [
                Validators.required,
                Validators.email,
                Validators.maxLength(200)
            ]],
            phone: [' ', [
                Validators.required,
                Validators.pattern('[0-9]{8,9}')
            ]]
        });

        this.changeValidation(this.contact);
        this.StartCaptcha();
    }

    public btnMsg() {
        if (this.disableButton === false) {
            return 'Enviar';
        } else {
            return 'Enviando Solicitud...';
        }
    }

    // show spinner of the button
    public sendForm() {
        this.isSendForm = true;

        setTimeout(() => {
            this.isSendForm = false;
        }, 3000);
    }

    public onPhoneBlur(event: any) {
        if (this.form.get('phone').value === '') {
            this.form.get('phone').setValue(' ');
        }
    }

    public onPhoneFocus(event: any) {
        if (this.form.get('phone').value === ' ') {
            this.form.get('phone').setValue('');
        }
    }

    public changeValidation(contact: Contact) {
        switch (contact.country_code) {
            case 'cl':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[0-9]{8,9}')]);
                break;
            case 'pe':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[0-9]{9,10}')]);
                break;
            case 'me':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[0-9]{10,11}')]);
                break;
            case 'ar':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[0-9]{11}')]);
                break;
            case 'co':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[0-9]{8,10}')]);
                break;
        }
        this.form.get('phone').updateValueAndValidity();
    }

    public onSubmit() {
        if (this.form.invalid) {
            this.showMessage('¡Ups! Ocurrió un error al enviar sus datos de contacto.', 'REINTENTAR', 0, true);
            return;
        }
        const values = this.form.value;
        values['g-recaptcha-response'] = this.captcha;

        const url = window.location.href;
        let urlReq;
        if (url.includes('dev')) {
            urlReq = 'https://api-frontend.haulmer.dev/v2/call';
        } else {
            urlReq = 'https://api-frontend.haulmer.com/v2/call';
        }
        const body = new HttpParams()
            .set('title', 'Livehost ' + this.tld.toUpperCase())
            .set('nombreCompleto', values.name)
            .set('telefono', values.phone)
            .set('email', values.email)
            .set('g-recaptcha-response', values['g-recaptcha-response'])
            .set('codigoPais', this.contact.phone_code.substr(1))
            .set('pais', 'Chile');

        this._http.post(urlReq, body)
            .pipe(
                retryWhen(err => err.pipe(
                    delay(3000),
                    take(5),
                    concat(throwError('An error occur while trying to post the info'))
                )
                )
            )
            .subscribe(() => {
                this.formDirective.resetForm();
                this.form.get('phone').setValue(' ');
                this.activeCaptcha = false;
                this.disableButton = false;

                this.showMessage('Solicitud creada! Será contactado en breve por nuestro departamento de ventas', 'CERRAR', 10000);
            }, error => {
                this.showMessage('¡Ups! Ocurrió un error al enviar sus datos de contacto.', 'REINTENTAR', 0, true);
            });
        this.StartCaptcha();

        this.disableButton = true;
    }

    private showMessage(bodyText, btnText, duration, reload?) {
        const bar = this.snackBar.open(bodyText, btnText,
            {
                duration: duration,
                panelClass: 'snackBar-btn'
            });

        if (reload) {
            bar.afterDismissed().subscribe(() => location.reload());
        }
    }

    StartCaptcha() {
        this.reCaptchaV3Service.execute(this.siteKey,
            'contactar_ventas',
            (token) => {
                this.activeCaptcha = true;
                this.captcha = token;
                const element = document.getElementsByClassName('grecaptcha-badge')[0];
                element.setAttribute('style', element.getAttribute('style')
                    + 'margin-bottom:80px; z-index: 2;');
            });
    }
}
