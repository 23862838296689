import { Component, OnInit, Input, HostListener, AfterViewInit, OnChanges } from '@angular/core';
import { DomainService } from '../../services/domain-service';
import { domain } from '../../model/domain';
import { Observable } from 'rxjs';
import { LocaleService } from '../../services/locale.service';

@Component({
    selector: 'app-table-domain',
    templateUrl: './table-domain.component.html',
    styleUrls: ['./table-domain.component.sass']
})

export class TableDomainComponent implements OnInit, OnChanges {
    @Input() searchedDomain;
    @Input() subtext;

    public URL = URL;
    public symbol;
    public isMobile = false;
    public isLoading = true;
    public dataTableDomain: domain[] = [];
    public Domain: domain;
    public selectedDomain;
    public response: Observable<any>;
    public link = 'https://panel.livehost.host/cart.php?a=add&domain=register&currency=';

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobile = window.innerWidth <= 670 ? true : false;
    }

    constructor(
        private _domainSrv: DomainService,
        private localeService: LocaleService
    ) {
        this.isLoading = true;
        this.symbol = localeService.symbol;
        this._domainSrv.getValue$().subscribe((doc) => {
            this.selectedDomain = doc.split('.')[0];
        });
    }

    ngOnInit() {
        this.link += this.localeService.currentContact.currency_number;
        this.isMobile = window.innerWidth <= 670 ? true : false;
    }

    ngOnChanges(searchedDomain) {
        if (this.searchedDomain !== undefined) {
            this._domainSrv.setdomain(this.searchedDomain);
            this.getDomains();
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    getDomains() {
        this._domainSrv.getDomains().subscribe(res => {
            this.isLoading = false;
            this.response = res['response'];
            this.dataTableDomain = [];
            this.response['related_domains'].forEach(rel_dom => {
                this.Domain = new domain();
                this.Domain.prefix = rel_dom['tld'];
                this.Domain.state = rel_dom['is_available'];
                if (this.Domain.state) {
                    this.Domain.price = rel_dom['prices'][0]['price'];
                }
                this.Domain.btnLink = this.link + '&tld=' + this.Domain.prefix + '&sld=' + this.selectedDomain;

                this.dataTableDomain.push(this.Domain);
            });
        });
    }
}
