import { Component, OnInit, ViewChild, ElementRef, Inject, HostBinding } from '@angular/core';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { delay, retryWhen, take } from 'rxjs/operators';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
    phone = window.innerWidth < 768;

    public URL = URL;
    locale;
    symbol = '';
    params;
    totalPrice = '0';
    saleTicket = '0';
    flag = false;
    buttonCalendly = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private localeService: LocaleService,
        private _http: HttpClient,
    ) {
        this.symbol = this.localeService.symbol;
        this.locale = this.localeService.locale;
        this.activatedRoute.queryParams.subscribe(params => {
            this.params = params;
        });
    }

    ngOnInit() {
        let total;
        let ticket;
        let validator;
        let flag2 = false;
        if (this.params.hasOwnProperty('total')) {
            total = this.params['total'];
        }
        if (this.params.hasOwnProperty('ticket')) {
            ticket = this.params['ticket'];
        }
        if (this.params.hasOwnProperty('validator')) {
            validator = this.params['validator'];
        }
        if (this.params.hasOwnProperty('st') && this.params.st === '1') {
            this.buttonCalendly = true;
        } else {
            this.buttonCalendly = false;
        }
        if (total !== undefined && ticket !== undefined && validator !== undefined) {
            try {
                total = parseInt(total, 16);
                ticket = parseInt(ticket, 16);
                validator = parseInt(validator, 16);
                flag2 = true;
            } catch (e) {
                console.error(e)
            }
            if (flag2) {
                try {
                    let totalN = Number(total);
                    let ticketN = Number(ticket);
                    validator = Number(validator);
                    const clientVal = Math.floor(Math.sqrt((totalN + ticketN) * 310));
                    if (validator === clientVal) {
                        this.flag = true;
                        this.totalPrice = total;
                        this.saleTicket = ticket;
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            this.sendRequest(ticket, total, validator, this.params['validator'])
        }
    }

    sendRequest(ticket, total, validator, validatorEncr) {
        if (!this.flag) {
            let body = {
                'titulo': "Livehost",
                'ticket': ticket,
                'total': total,
                'validator': validator,
                'validatorEncr': validatorEncr
            }
            const urlReq = "https://contact-form-function.azurewebsites.net/api/HttpTrigger1?code=CjaP8djxs2iV3Y2vX73oqd3WcFikswMRxLs/2DiYdgECpfiyF/jf2w==";
            this._http.post(urlReq, body)
                .pipe(
                    retryWhen(err => err.pipe(
                        delay(3000),
                        take(0)
                    )
                    )
                )
                .subscribe(() => {
                    // console.log("Mensaje enviado")
                }, error => {
                    console.error(error)
                });
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
