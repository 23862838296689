<section class="firstScreen has-wave bottom">
  <div class="firstScreen__img">
    <picture>
      <source srcset="/assets/images/web-hosting-full/hosting-full-firstscreen@2x.webp 2x"
              type="image/webp" media="(min-width: 1280px)">
      <source srcset="/assets/images/web-hosting-full/hosting-full-firstscreen@1x.webp,
                      /assets/images/web-hosting-full/hosting-full-firstscreen@2x.webp 2x"
              type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/web-hosting-full/hosting-full-first-screen.jpg" media="(min-width: 671px)">
      <source srcset="/assets/images/web-hosting-full/hosting-full-firstscreen-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/web-hosting-full/hosting-full-firstscreen-mobile@1x.jpg"
            srcset="/assets/images/web-hosting-full/hosting-full-firstscreen-mobile@2x.jpg 2x" alt="people">
    </picture>
  </div>
  <div class="wave wave-1">
    <img  src="/assets/images/shared/wave@1x.png"
          srcset="/assets/images/shared/wave@2x.png 2x" alt="wave">
  </div>
  <picture>
    <source srcset="/assets/images/shared/first-screen-decorations@1x.png,
                    /assets/images/shared/first-screen-decorations@2x.png 2x" media="(min-width: 992px)">
    <img  src="/assets/images/shared/firstscreen-decorations-tablet@1x.png"
          srcset="/assets/images/shared/firstscreen-decorations-tablet@2x.png 2x" alt="decorations" class="decorations">
  </picture>

  <div class="container">
    <div class="firstScreen__info">
      <div class="suptitle upper color-accent-green" i18n>Web hosting Full</div>
      <h1 class="title-1" i18n>Planes Web Hosting<br> de Alto Tráfico</h1>
      <div class="subheading" i18n>Hosting para sitios con un alto volumen de visitantes simultáneos</div>

      <div class="wrapButton">
        <button id="l-whf-plans" class="btn btnAccent rounded" mat-button (click)="navigateToSection('plans-ts')" i18n>Ver Planes</button>
        <button id="l-whf-contactanos" class="btn btnTransp rounded" mat-button [routerLink]="['/contactar-con-ventas/']" i18n>Contáctanos</button>
      </div>
    </div>
  </div>
</section>

<app-cards
    [data]="dataCards.web_hosting_full.plans"
    [suptitle]="suptitle"
    [title2]="title2"
    [preRender]="false" (deferLoad)="showImage1 = true"
    class="plans-ts"
    data-offset="40">
</app-cards>

<!-- START sectionSales-->
<app-section-sales [data]="sales" [preRender]="false" (deferLoad)="showImage2 = true" [loadImage]="showImage1"></app-section-sales>
<!-- END -->

<!-- START sectionVisits -->
<section class="sectionVisits has-wave bottom">
  <div class="wave wave-2" *ngIf="showImage2">
    <img  src="/assets/images/shared/wave2@1x.png"
          srcset="/assets/images/shared/wave2@2x.png 2x" alt="wave">
  </div>

  <div class="decorations" *ngIf="showImage2">
    <picture>
      <source srcset="/assets/images/web-hosting-full/visits-decorations-desktop@2x.webp 2x"
              type="image/webp" media="(min-width: 416px)">
      <source srcset="/assets/images/web-hosting-full/visits-decorations-desktop@1x.png,
                      /assets/images/web-hosting-full/visits-decorations-desktop@2x.png 2x" media="(min-width: 416px)">
      <img  src="/assets/images/web-hosting-full/visits-decorations-mobile@1x.png"
            srcset="/assets/images/web-hosting-full/visits-decorations-mobile@2x.png 2x" alt="visitas" class="decorations">
    </picture>
  </div>
  <div class="wrapper">
    <div class="icon width-56 m-x-auto accent-red">
      <svg-icon-sprite
        [src]="'/assets/images/sprites/sprite.svg#icon-navigation'"
        [width]="'22px'"
        [classes]="'icon-navigation'"
        *ngIf="showImage2">
      </svg-icon-sprite>
    </div>
    <h2 class="title-2 center" i18n>Muchas visitas. Ningún problema</h2>
    <div class="body-1 center color-secondary" i18n>Todos los planes de Web Hosting Alto Tráfico tienen asignados mayores recursos para poder recibir un gran número de visitas simultáneas sin problemas. Vuélvete viral y no colapses en el proceso.</div>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--power -->
<section class="sectionColumns sectionColumns--power" [preRender]="false" (deferLoad)="showImage2 = true">
  <div class="sectionColumns__col rel">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/shared/bg-circle-blue@1x.webp,
                      /assets/images/shared/bg-circle-blue@2x.webp 2x"
                      type="image/webp" media="(min-width: 992px)">
      <source srcset="/assets/images/shared/bg-circle-blue@1x.png,
                      /assets/images/shared/bg-circle-blue@2x.png 2x" media="(min-width: 992px)">
      <source srcset="/assets/images/shared/bg-circle-blue-mobile@1x.webp,
                      /assets/images/shared/bg-circle-blue-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/shared/bg-circle-blue-mobile@1x.png"
            srcset="/assets/images/shared/bg-circle-blue-mobile@2x.png 2x" alt="circle">
    </picture>
    <app-info-cards [data]="powerArr" [class]="'chessboard'" [isIconCircle]="true" [loadImage]="showImage2"></app-info-cards>
  </div>
  <div class="sectionColumns__col rel">
    <span class="decorations"></span>
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>Potencia al máximo</div>
      <h2 class="title-2 color-white" i18n>Más Recursos para tu Sitio</h2>
      <div class="body-1 color-white" i18n>Más del doble de potencia que otros proveedores para un mejor performance en tu hosting.</div>
    </div>
  </div>
</section>
<!-- END -->

<!-- START sectionDatacenter -->
<section class="sectionDatacenter has-wave top bottom" [preRender]="false" (deferLoad)="showImage3 = true">
  <div class="wave wave-5">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/shared/wave5@1x.png,
                      /assets/images/shared/wave5@2x.png 2x" media="(min-width: 992px)">
      <img  src="/assets/images/shared/wave3@1x.png"
            srcset="/assets/images/shared/wave3@2x.png 2x" alt="wave">
    </picture>
  </div>
  <div class="wave wave-1" *ngIf="showImage2">
    <picture>
      <source srcset="/assets/images/shared/wave@1x.png,
                      /assets/images/shared/wave@2x.png 2x" media="(min-width: 671px)">
      <img  src="/assets/images/shared/wave2@1x.png"
            srcset="/assets/images/shared/wave2@2x.png 2x" alt="wave">
    </picture>
  </div>
  <span class="decorations" *ngIf="!isTablet"></span>
  <div class="wrapper">
    <div class="suptitle upper color-accent-green center" i18n>Nuestro datacenter</div>
    <h2 class="title-2 center" i18n>Data center en Chile</h2>
    <div class="body-1 center color-secondary" i18n>Nuestro Datacenter en Chile le concede la seguridad y estabilidad que otras empresas no le pueden garantizar. Tiempos de respuestas más cortos que empresas externas</div>
  </div>

  <app-horizontal-cards [data]="datacenter" [class]="'default'" [loadImage]="showImage2"></app-horizontal-cards>
</section>
<!-- END -->

<!-- START sectionColumns--productivity -->
<section class="sectionColumns sectionColumns--productivity">
  <div class="sectionColumns__col">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>Alto rendimiento</div>
      <h2 class="title-2 color-white" i18n>Tecnología SSD para mayor rendimiento</h2>
      <div class="body-1 color-white" i18n>Nuestros Servidores utilizan discos sólidos que disminuyen en hasta 100 veces el tiempo de respuesta para entregarte un servicio de alta velocidad. Tecnología de punta para potenciar tu sitio.
      </div>
    </div>
  </div>

  <div class="sectionColumns__col">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/web-hosting-full/section-productivity-desktop@2x.webp 2x"
              type="image/webp" media="(min-width: 992px)">
      <source srcset="/assets/images/web-hosting-full/section-productivity-desktop@1x.png,
                      /assets/images/web-hosting-full/section-productivity-desktop@2x.png 2x"
              media="(min-width: 992px)">
      <source srcset="/assets/images/web-hosting-full/section-productivity-tablet@2x.webp 2x"
              type="image/webp" media="(min-width: 670px)">
      <source srcset="/assets/images/web-hosting-full/section-productivity-tablet@1x.png,
                      /assets/images/web-hosting-full/section-productivity-tablet@2x.png 2x"
              media="(min-width: 670px)">
      <source srcset="/assets/images/web-hosting-full/section-productivity-mobile@2x.webp 2x"
              type="image/webp">
      <img  src="/assets/images/web-hosting-full/section-productivity-mobile@1x.png"
            srcset="/assets/images/web-hosting-full/section-productivity-mobile@2x.png 2x" alt="productivity">
    </picture>
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/web-hosting-full/bg-circle-rose@1x.webp,
                      /assets/images/web-hosting-full/bg-circle-rose@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/web-hosting-full/bg-circle-rose@1x.png"
            srcset="/assets/images/web-hosting-full/bg-circle-rose@2x.png 2x" alt="circle" class="circle">
    </picture>
  </div>
  <picture *ngIf="showImage2">
    <source srcset="/assets/images/web-hosting-full/productivity-decorations-desktop@1x.png,
                    /assets/images/web-hosting-full/productivity-decorations-desktop@2x.png 2x"
                    media="(min-width: 992px)">
    <source srcset="/assets/images/web-hosting-full/productivity-decorations-tablet@1x.png,
                    /assets/images/web-hosting-full/productivity-decorations-tablet@2x.png 2x"
                    media="(min-width: 670px)">
    <img  src="/assets/images/web-hosting-full/productivity-decorations-mobile@1x.png"
          srcset="/assets/images/web-hosting-full/productivity-decorations-mobile@2x.png 2x" alt="decorations" class="decorations">
  </picture>
</section>
<!-- END -->

<!-- START sectionCustomMail -->
<section class="sectionCustomMail has-wave top">

  <div class="wave wave-5" *ngIf="showImage2">
    <picture>
      <source srcset="/assets/images/shared/wave5@1x.png,
                      /assets/images/shared/wave5@2x.png 2x" media="(min-width: 671px)">
      <img  src="/assets/images/shared/wave3@1x.png"
            srcset="/assets/images/shared/wave3@2x.png 2x" alt="wave">
    </picture>
  </div>

  <img  src="/assets/images/web-hosting/custom-mail-decorations@1x.png"
        srcset="/assets/images/web-hosting/custom-mail-decorations@2x.png 2x" alt="decorations" class="decorations" *ngIf="showImage2">

  <div class="wrapImg" *ngIf="showImage2">
    <picture>
      <source srcset="/assets/images/shared/comma-left@1x.webp,
                      /assets/images/shared/comma-left@2x.webp 2x"
              type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/shared/comma-left@1x.png,
                      /assets/images/shared/comma-left@2x.png 2x" media="(min-width: 671px)">
      <source srcset="/assets/images/shared/comma-left-mobile@1x.webp,
                      /assets/images/shared/comma-left-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/shared/comma-left-mobile@1x.png"
            srcset="/assets/images/shared/comma-left-mobile@2x.png 2x" alt="comma">
    </picture>
  </div>
  <div class="wrapper">
    <div class="suptitle upper color-accent-green center" i18n>Correo Personalizado</div>
    <h2 class="title-2 center" i18n>Crea tus correos corporativos gratis</h2>
    <div class="body-1 center color-secondary" i18n>Entrega confianza a tus clientes con cuentas de correo personalizadas para todos tus colaboradores con el nombre de tu empresa.</div>
  </div>
  <div class="image">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/web-hosting/custom-mail-desktop@2x.webp 2x"
              type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/web-hosting/custom-mail-desktop@1x.png,
                      /assets/images/web-hosting/custom-mail-desktop@2x.png 2x" media="(min-width: 671px)">
      <source srcset="/assets/images/web-hosting/custom-mail-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/web-hosting/custom-mail-mobile@1x.png"
            srcset="/assets/images/web-hosting/custom-mail-mobile@2x.png 2x" alt="Personalizado">
    </picture>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--support -->
<app-section-support [data]="dataSupport" [loadImage]="showImage3"></app-section-support>
<!-- END -->

<!-- START payment-logos -->
<app-payment-logos
  [class]="'web-hosting-full'"
  [buttonId]="'l-whf-plans'"
  [subtext]="'Desde ' + symbol + (dataCards.web_hosting_full.plans[0].price | currencyFormat ) + ' ANUAL'"
  [loadImage]="showImage3">
</app-payment-logos>
<!-- END -->

<app-footer [loadImage]="showImage3"></app-footer>
