import { Component, OnInit, Input } from '@angular/core';
import { LocaleService } from '../../services/locale.service';

@Component({
    selector: 'app-section-sales',
    templateUrl: './section-sales.component.html',
    styleUrls: ['./section-sales.component.sass']
})

export class SectionSalesComponent implements OnInit {
    @Input() data;
    @Input() loadImage;

    public tld = this.localeService.currentContact.country_code;
    public access = 'Accede';

    constructor(
        private localeService: LocaleService,
    ) {
    }

    ngOnInit() {
        if (this.tld === 'co') {
            this.access = 'Acceda';
        } else if (this.tld === 'ar'){
            this.access = 'Accedé';
        }
     }
}
