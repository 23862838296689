<div class="tabPlans">
  <div class="tabPlans__nav">
    <button class="btn rounded"
            [class.btnAccent]="currentPlan === data.web_hosting"
            mat-button
            (click)="selectedPlan(1)">
      Web Hosting
    </button>
    <button class="btn rounded"
            [class.btnAccent]="currentPlan === data.pymes"
            mat-button
            (click)="selectedPlan(2)">
      Web Hosting Full
    </button>
    <button class="btn rounded"
            [class.btnAccent]="currentPlan === data.vps"
            mat-button
            (click)="selectedPlan(3)">
      VPS
    </button>
  </div>

  <div class="tabPlans__body">
    <div class="body-2">Recomendado para:</div>
    <ul class="tabPlans__body--list">
      <li *ngFor="let point of currentPlan.recommendedList">
        <div class="icon color-accent-green">
          <svg-icon-sprite
            [src]="'/assets/images/sprites/sprite.svg#icon-check'"
            [width]="'16px'"
            [classes]="'icon-check'">
          </svg-icon-sprite>
        </div>
        <span i18n>
          {{point}}
        </span>
      </li>
    </ul>

    <div class="horizontalCard__wrapper" [class.reverse]="currentPlan === data.web_hosting">
      <div class="horizontalCard" *ngFor="let card of currentPlan.plans">
        <div class="inner">
          <div class="title" i18n>{{card.name}}</div>
          <div class="text" i18n>{{card.text}}</div>
        </div>
        <div class="inner">
          <div class="price"><span>{{ symbol }}</span>{{card.price | currencyFormat}}</div>
          <div class="period"><span>/</span>{{period}} {{ getTaxSymbol() }}</div>
          <a [routerLink]="[card.link]" class="color-accent-orange" i18n (click)="scrollTop()">Ver detalles</a>
        </div>
      </div>
    </div>
  </div>
</div>
