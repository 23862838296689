<ul class="customDropdownMenu">
  <li *ngFor="let item of dataList">
    <button id="{{item.id}}"
            class="customDropdownMenu__item"
            mat-button
            (click)="navigationTo(item.url)"
            tabindex="-1"
            mat-dialog-close>
      {{item.text}}
    </button>
  </li>
</ul>
