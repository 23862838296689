<div class="tableDomainZones">
  <div class="tableDomainZones__header">
    <ng-container *ngIf="!isMobile">
      <div class="tableDomainZones__th" *ngFor="let cell of dataTableDomainZones.table_header"><b>{{cell}}</b></div>
    </ng-container>

    <ng-container *ngIf="isMobile">
      <div class="tableDomainZones__th" i18n><b>Valores en Pesos Mexicanos</b></div>
      <div class="tableDomainZones__th">
        <button mat-button [matMenuTriggerFor]="menuPeriod">
          <b i18n>{{currencyPeriod}}</b>
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu class="tableDomainZones__dropdown" #menuPeriod="matMenu">
          <ng-container *ngFor="let item of dataTableDomainZones.table_header; let i = index">
            <button mat-menu-item (click)="selectedPeriod(item, i)" *ngIf="i !== 0" i18n>{{item}}</button>
          </ng-container>
        </mat-menu>
      </div>
    </ng-container>
  </div>

  <div class="tableDomainZones__body">
    <ng-container *ngIf="!isMobile">
      <div class="tableDomainZones__tr" *ngFor="let row of dataTableDomainZones.table_body">
        <div class="tableDomainZones__td">{{row.name}}</div>
        <div class="tableDomainZones__td">{{symbol}}{{row.price_period_1 | currencyFormat }}</div>
        <div class="tableDomainZones__td">{{symbol}}{{row.price_period_2 | currencyFormat }}</div>
        <div class="tableDomainZones__td">{{symbol}}{{row.price_period_3 | currencyFormat }}</div>
        <div class="tableDomainZones__td" *ngIf="row.hasOwnProperty('price_period_4') && row.price_period_4">{{symbol}}{{ row.price_period_4 | currencyFormat }}</div>
        <div class="tableDomainZones__td" *ngIf="row.hasOwnProperty('price_period_5') && row.price_period_5">{{symbol}}{{ row.price_period_5 | currencyFormat }}</div>
        <div class="tableDomainZones__td" *ngIf="row.hasOwnProperty('price_period_4') && !row.price_period_4">-</div>
        <div class="tableDomainZones__td" *ngIf="row.hasOwnProperty('price_period_5') && !row.price_period_5">-</div>
      </div>
    </ng-container>

    <ng-container *ngIf="isMobile">
      <div class="tableDomainZones__tr" *ngFor="let row of dataTableDomainZones.table_body">
        <div class="tableDomainZones__td">{{row.name}}</div>
        <div class="tableDomainZones__td" *ngIf="currencyData === 1">{{symbol}}{{row.price_period_1 | currencyFormat}}</div>
        <div class="tableDomainZones__td" *ngIf="currencyData === 2">{{symbol}}{{row.price_period_2 | currencyFormat}}</div>
        <div class="tableDomainZones__td" *ngIf="currencyData === 3">{{symbol}}{{row.price_period_3 | currencyFormat}}</div>
        <div class="tableDomainZones__td" *ngIf="row.hasOwnProperty('price_period_4') && row.price_period_4 && currencyData === 4">
          {{symbol}}{{row.price_period_4 | currencyFormat}}
        </div>
        <div class="tableDomainZones__td" *ngIf="row.hasOwnProperty('price_period_5') && row.price_period_5 && currencyData === 5">
          {{symbol}}{{row.price_period_5 | currencyFormat}}
        </div>
        <div class="tableDomainZones__td" *ngIf="row.hasOwnProperty('price_period_4') && !row.price_period_4 && currencyData === 4">-</div>
        <div class="tableDomainZones__td" *ngIf="row.hasOwnProperty('price_period_5') && !row.price_period_5 && currencyData === 5">-</div>
      </div>
    </ng-container>
  </div>
</div>
<p class="subtext" *ngIf="subtext !== undefined" i18n>{{subtext}}</p>
