<div class="sectionNoResult">
  <div class="sectionNoResult__info">
    <div class="sectionNoResult__info-title">
      <svg-icon-sprite
        [src]="'/assets/images/sprites/sprite.svg#' + data.icon"
        [width]="'33px'"
        [ngClass]="!!data.state ? data.state : ''">
      </svg-icon-sprite>
      <span i18n [innerHTML]="data.title"></span>
    </div>
    <div class="sectionNoResult__info-text" [innerHTML]="data.text" i18n></div>
  </div>
</div>
