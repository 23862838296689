import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-options',
    templateUrl: './options.component.html',
    styleUrls: ['./options.component.sass']
})

export class OptionsComponent implements OnInit {
    @Input() options;
    @Input() class;
    @Input() loadImage;

    constructor() {
    }

    ngOnInit() {
    }

}
