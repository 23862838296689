<section class="sendTicket">
  <div class="sendTicket__container">
    <div class="sendTicket__inner">
      <button class="btn btnAccent rounded" mat-button [routerLink]="['/web-hosting/.']">
        <span i18n>Productos</span>
      </button>
      <button class="btn btnAccent rounded" mat-button [routerLink]="['/contactar-con-soporte/.']">
        <span i18n>Soporte</span>
      </button>
    </div>
    <div class="sendTicket__inner">
      <h2 class="sendTicket__title" i18n>
        Enviar ticket de<br> consulta.
      </h2>
      <p i18n>
        Levanta un Ticket de Soporte para solicitar asistencia técnica y monitorear el estado de tu solicitud.
      </p>
    </div>
  </div>
</section>
