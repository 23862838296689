<header #header class="wrapHeader">
  <div class="container">

    <div class="wrapHeader__inner">
      <a class="logo" [routerLink]="['/']" (click)="scrollTop()">
        <picture class="logo-white">
          <source srcset="/assets/images/logo/livehost-white@1x.png,
                          /assets/images/logo/livehost-white@2x.png 2x" media="(min-width: 671px)">
          <img  src="/assets/images/logo/livehost-white-mobile@1x.png"
                srcset="/assets/images/logo/livehost-white-mobile@2x.png 2x" alt="Livehost">
        </picture>
        <picture class="logo-dark">
          <source srcset="/assets/images/logo/livehost-accent@1x.png,
                        /assets/images/logo/livehost-accent@2x.png 2x" media="(min-width: 671px)">
          <img  src="/assets/images/logo/livehost-accent-mobile@1x.png"
                srcset="/assets/images/logo/livehost-accent-mobile@2x.png 2x" alt="Livehost">
        </picture>
        <span i18n>Damos vida a tus ideas!</span>
      </a>

      <ul class="wrapHeader__list">
        <li><a #triggerDialogHosting id="{{isOpenMenu ? '' : 'l-h-webhosting'}}" (click)="openDialogCustomMenu('hosting')">Web Hosting <mat-icon>keyboard_arrow_down
            </mat-icon></a></li>
        <li><a #triggerDialogVPS id="{{isOpenMenu ? '' : 'l-h-vps'}}" (click)="openDialogCustomMenu('VPS')">VPS <mat-icon>keyboard_arrow_down
             </mat-icon></a></li>
        <li>
          <a id="{{isOpenMenu ? '' : 'l-h-dominios'}}" [routerLink]="['registro-dominio/']" (click)="scrollTop()">
            Dominios
          </a>
        </li>
        <li [ngClass]="{'right': !isMobile}">
          <a id="{{isOpenMenu ? '' : 'l-h-pago'}}" [routerLink]="['pago-directo/']" (click)="scrollTop()">
            <span>Pago de cuentas</span>
            <svg-icon-sprite
              [src]="'/assets/images/sprites/sprite.svg#icon-sprite-payment-card'"
              [width]="'24px'">
            </svg-icon-sprite>
          </a>
        </li>
        <li>
          <a #triggerDialogSupport id="{{isOpenMenu ? '' : 'gl-support'}}" (click)="openDialogCustomMenu('support')">Soporte <mat-icon>keyboard_arrow_down
            </mat-icon></a>
        </li>
      </ul>

      <button mat-icon-button class="btnHumburger" (click)="openMobileMenu()">
        <mat-icon>dehaze</mat-icon>
      </button>
    </div>

    <div class="wrapHeader__inner">
      <ul class="wrapHeader__list">
        <li><a id="{{isOpenMenu ? '' : 'l-iniciarsesion'}}"
            (click)="goToUrl('https://panel.livehost.host/clientarea.php')">Iniciar Sesión</a></li>
      </ul>
      <button id="l-contactanos" mat-button [routerLink]="['contactar-con-ventas/']" class="btn rounded btnTransp"
        (click)="scrollTop()">
        {{contactUs}}
      </button>
    </div>

  </div>
</header>

<!-- START dropdownContainer -->
<div class="dropdownContainer">
  <div class="dropdownContainer__header">
    <a class="logo" [routerLink]="['/']" (click)="closeMobileMenu(); scrollTop()">
      <picture>
        <source srcset="/assets/images/logo/livehost-accent@1x.png,
                /assets/images/logo/livehost-accent@2x.png 2x" media="(min-width: 671px)">
        <img  src="/assets/images/logo/livehost-accent-mobile@1x.png"
              srcset="/assets/images/logo/livehost-accent-mobile@2x.png 2x" alt="Livehost">
      </picture>
      <span i18n>Damos vida a tus ideas!</span>
    </a>
    <button class="btnClose" mat-icon-button (click)="closeMobileMenu()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dropdownContainer__body">

    <ul class="menuMobile">
      <li>
        <a id="{{!isOpenMenu ? '' : 'l-h-webhosting'}}" (click)="showDropdownMenu('first')">
          Web Hosting <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </li>
      <li>
        <a id="{{!isOpenMenu ? '' : 'l-h-vps'}}" (click)="showDropdownMenu('VPS')">
          VPS <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </li>
      <li>
        <a id="{{!isOpenMenu ? '' : 'l-h-dominios'}}"
        (click)="closeMobileMenu(); scrollTop()"
        [routerLink]="['registro-dominio/']">
          Dominios
        </a>
      </li>
      <li>
        <a
          id="{{!isOpenMenu ? '' : 'l-h-pago'}}"
          [routerLink]="['pago-directo/']"
          (click)="closeMobileMenu(); scrollTop()">
          <span>Pago de cuentas</span>
          <svg-icon-sprite
            [src]="'/assets/images/sprites/sprite.svg#icon-sprite-payment-card'"
            [width]="'24px'">
          </svg-icon-sprite>
        </a>
      </li>
    </ul>

    <ul class="menuMobile">
      <li (click)="showDropdownMenu('soporte')">
        <a id="{{!isOpenMenu ? '' : 'gl-support'}}">Soporte<mat-icon>keyboard_arrow_right</mat-icon></a>
      </li>
      <li>
        <a id="{{!isOpenMenu ? '' : 'l-iniciarsesion'}}" (click)="goToUrl('https://panel.livehost.host/clientarea.php')">
          Iniciar Sesión
        </a>
      </li>
    </ul>

    <!-- START dropdownMenu Web Hosting -->
    <div class="dropdownMenu" [class.first]="isShowMenuFirst">

      <div class="dropdownContainer__header">
        <button mat-icon-button (click)="hideMenuDropdown('first')">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span>Web Hosting</span>
        <button class="btnClose" mat-icon-button (click)="closeMobileMenu()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="inner">
        <ul class="dropdownMenu__list">
          <li>
            <a id="{{isOpenMenu ? 'l-h-webhosting' : ''}}" [routerLink]="['web-hosting/']"
              (click)="closeMobileMenu(); scrollTop()">
              <span class="title">Web Hosting</span>
            </a>
          </li>
          <li>
            <a id="{{isOpenMenu ? 'l-h-webhostingfull' : ''}}" [routerLink]="['web-hosting-full/']"
              (click)="closeMobileMenu(); scrollTop()">
              <span class="title">Web Hosting Full</span>
            </a>
          </li>
          <li>
            <a id="{{isOpenMenu ? 'l-h-reseller' : ''}}" [routerLink]="['reseller/']"
              (click)="closeMobileMenu(); scrollTop()">
              <span class="title">Reseller</span>
            </a>
          </li>
        </ul>
      </div>

    </div>
    <!-- END dropdownMenu Web Hosting -->

    <!-- START dropdownMenu Soporte -->
    <div class="dropdownMenu" [class.support]="isShowMenuSupport">

      <div class="dropdownContainer__header">
        <button mat-icon-button (click)="hideMenuDropdown('soporte')">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span>Soporte</span>
        <button class="btnClose" mat-icon-button (click)="closeMobileMenu()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="inner">
        <ul class="customDropdownMenu">
          <li class="customDropdownMenu__item" *ngFor="let item of dataSupport ; let i = index">
            <a id="{{item.id}}" href="{{item.url}}">
              <span class="title">{{item.text}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- END dropdownMenu Soporte -->

    <!-- START dropdownMenu Soporte -->
    <div class="dropdownMenu" [class.vps]="isShowMenuVPS">

      <div class="dropdownContainer__header">
        <button mat-icon-button (click)="hideMenuDropdown('VPS')">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span>VPS</span>
        <button class="btnClose" mat-icon-button (click)="closeMobileMenu()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="inner">
        <ul class="customDropdownMenu">
          <li class="customDropdownMenu__item" *ngFor="let item of dataVPS ; let i = index">
            <a id="{{item.id}}" href="{{item.url}}">
              <span class="title">{{item.text}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- END dropdownMenu Soporte -->

  </div>
</div>
<!-- END dropdownContainer -->

<div class="menu-overlay" (click)="closeMobileMenu()">
</div>
