import { Component, OnInit, ElementRef } from '@angular/core';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { ScrollToClass } from './../../../../common/scrollTo.class';

@Component({
    selector: 'app-reseller',
    templateUrl: './reseller.component.html',
    styleUrls: ['./reseller.component.sass']
})

export class ResellerComponent extends ScrollToClass implements OnInit {

    public URL = URL;
    public tld = this.localeService.currentContact.country_code;
    public symbol = this.localeService.symbol;

    // load images
    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    public suptitle = 'Planes para revendedores';
    public title2 = 'Crea tus planes a partir de nuestros precios';

    public dataCards;

    public sales = [
        {
            title: 'Hasta 16% descuento',
            text: 'Al contratar 2 años en los planes Reseller',
            icon: 'icon-local-offer',
            color: 'bg-accent-violet'
        }, {
            title: 'Hasta 36% descuento',
            text: 'Al contratar 3 años en los planes Reseller',
            icon: 'icon-gift',
            color: 'bg-accent-red'
        }
    ];

    public lists = [
        {
            list: [
                'Panel de control WHM',
                '99.9% de Uptime garantizado',
                'DNS personalizados',
                'Certificado SSL Gratis',
                'Sitepublisher Gratis',
            ]
        }, {
            list: [

                'Dominios Ilimitados',
                'Subdominios Ilimitados',
                'Bases de datos MySQL Ilimitada',
                'Cuentas de FTP Ilimitadas',
                'Personalizable o anónimo cPanel'
            ]
        }
    ];

    public listsWHM = [
        {
            list: [
                'Panel de control WHM',
                'Monitoreo del estado del servidor',
                'Personalización de planes'
            ]
        }, {
            list: [

                'Control total de las cuentas',
                'Panel del cliente personalizable',
                'Optimización de planes'
            ]
        }
    ];

    public dataSupport = {
        suptitle: 'Ventas',
        title: 'Nuestro equipo está para aclarar tus dudas',
        text: 'Ponte en contacto con nuestros ejecutivos y ellos resolverán todas tus preguntas sobre los planes Reseller',
        buttonId: 'l-r-contactar2'
    };

    public dataSupport_CO = {
        suptitle: 'Ventas',
        title: 'Nuestro equipo está para aclarar sus dudas',
        text: 'Pongase en contacto con nuestros ejecutivos y ellos resolverán todas sus preguntas sobre los planes Reseller',
        buttonId: 'l-r-contactar2'
    };

    public options = [
        {
            title: 'cPanel y WHM',
            text: 'Crea y vende planes de hosting independientes y personalizables para tus clientes.',
            icon: 'icon-folder',
            color: 'color-accent-yellow'
        }, {
            title: 'Softaculous',
            text: 'Entrégale a tus clientes un autoinstalador de 1 clic con más de 400 aplicaciones.',
            icon: 'icon-grid',
            color: 'color-accent-green'
        }, {
            title: 'Site Publisher',
            text: 'Dale a tus clientes un creador de sitios web sencillo para que diseñen su propia página.',
            icon: 'icon-dns',
            color: 'color-accent-violet'
        }, {
            title: 'DNS Personalizado',
            text: 'Nadie sabrá que eres un reseller. Crea tu propio negocio de hosting con tu propio DNS.',
            icon: 'icon-dns-planet',
            color: 'color-accent-blue'
        }, {
            title: 'SSL Gratis',
            text: 'Ofrece a tus clientes un certificado de seguridad y encriptación de datos para proteger tu sitio.',
            icon: 'icon-book',
            color: 'color-accent-rose'
        }
    ];

    public options_AR = [
        {
            title: 'cPanel y WHM',
            text: 'Creá y vendé planes de hosting independientes y personalizables para tus clientes.',
            icon: 'icon-folder',
            color: 'color-accent-yellow'
        }, {
            title: 'Softaculous',
            text: 'Entrégale a tus clientes un autoinstalador de 1 clic con más de 400 aplicaciones.',
            icon: 'icon-grid',
            color: 'color-accent-green'
        }, {
            title: 'Site Publisher',
            text: 'Dale a tus clientes un creador de sitios web sencillo para que diseñen su propia página.',
            icon: 'icon-dns',
            color: 'color-accent-violet'
        }, {
            title: 'DNS Personalizado',
            text: 'Nadie sabrá que eres un reseller. Creá tu propio negocio de hosting con tu propio DNS.',
            icon: 'icon-dns-planet',
            color: 'color-accent-blue'
        }, {
            title: 'SSL Gratis',
            text: 'Ofrecé a tus clientes un certificado de seguridad y encriptación de datos para proteger tu sitio.',
            icon: 'icon-book',
            color: 'color-accent-rose'
        }
    ];

    public options_CO = [
        {
            title: 'cPanel y WHM',
            text: 'Cree y venda planes de hosting independientes y personalizables para sus clientes.',
            icon: 'icon-folder',
            color: 'color-accent-yellow'
        }, {
            title: 'Softaculous',
            text: 'Entregue a sus clientes un autoinstalador de 1 clic con más de 400 aplicaciones.',
            icon: 'icon-grid',
            color: 'color-accent-green'
        }, {
            title: 'Site Publisher',
            text: 'Dele a sus clientes un creador de sitios web sencillo para que diseñen su propia página.',
            icon: 'icon-dns',
            color: 'color-accent-violet'
        }, {
            title: 'DNS Personalizado',
            text: 'Nadie sabrá que es un reseller. Cree su propio negocio de hosting con su propio DNS.',
            icon: 'icon-dns-planet',
            color: 'color-accent-blue'
        }, {
            title: 'SSL Gratis',
            text: 'Ofrezca a sus clientes un certificado de seguridad y encriptación de datos para proteger su sitio.',
            icon: 'icon-book',
            color: 'color-accent-rose'
        }
    ];

    constructor(
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private el: ElementRef
    ) {
        super(el);
        this.dataCards = this.localeService.getContent().plans;
    }

    ngOnInit() {
        if (this.tld === 'ar') {
            this._setMeta_AR();
            this.title2 = 'Creá tus planes a partir de nuestros precios';
            this.options = this.options_AR;
        } else if (this.tld === 'co') {
            this._setMeta_CO();
            this.title2 = 'Cree sus planes a partir de nuestros precios';
            this.options = this.options_CO;
            this.dataSupport = this.dataSupport_CO;
        } else if (this.tld === 'pe') {
            this._setMeta_PE();
        } else if (this.tld === 'mx') {
            this._setMeta_MX();
        } else {
            this._setMeta_CL();
        }
    }

    public navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }

    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    private _setMeta_CL() {
        this._meta.setTitle('Reseller Web Hosting SSD - LiveHost');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Construye tu propio negocio siendo socio de LiveHost Chile y ofrécele a tus clientes calidad y rapidez en alojamiento web');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting reseller, hosting reseller chile, hosting reseller ilimitado, hosting reseller economico, hosting reseller barato, hosting para revendedores, reventa de hosting, hosting buenos en chile');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-reseller.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Reseller Web Hosting SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_CL');
    }

    private _setMeta_PE() {
        this._meta.setTitle('Reseller Web Hosting SSD - LiveHost');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Construye tu propio negocio siendo socio de LiveHost Perú y ofrécele a tus clientes calidad y rapidez en alojamiento web');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting reseller, hosting reseller peru, hosting reseller ilimitado, hosting reseller economico, hosting reseller barato, hosting para revendedores, reventa de hosting, hosting buenos en peru');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-reseller.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Reseller Web Hosting SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_PE');
    }

    private _setMeta_CO() {
        this._meta.setTitle('Reseller Web Hosting SSD - LiveHost');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Construya su propio negocio siendo socio de LiveHost Colombia y ofrezcale a sus clientes calidad y rapidez en alojamiento web');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting reseller, hosting reseller colombia, hosting reseller ilimitado, hosting reseller economico, hosting reseller barato, hosting para revendedores, reventa de hosting, hosting buenos en colombia');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-reseller.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Reseller Web Hosting SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_CO');
    }

    private _setMeta_AR() {
        this._meta.setTitle('Reseller Web Hosting SSD - LiveHost');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Construye tu propio negocio siendo socio de LiveHost Argentina y ofrécele a tus clientes calidad y rapidez en alojamiento web');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting reseller, hosting reseller argentina, hosting reseller ilimitado, hosting reseller economico, hosting reseller barato, hosting para revendedores, reventa de hosting, hosting buenos en argentina');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-reseller.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Reseller Web Hosting SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_AR');
    }

    private _setMeta_MX() {
        this._meta.setTitle('Reseller Web Hosting SSD - LiveHost');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Construye tu propio negocio siendo socio de LiveHost México y ofrécele a tus clientes calidad y rapidez en alojamiento web');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting reseller, hosting reseller méxico, hosting reseller ilimitado, hosting reseller economico, hosting reseller barato, hosting para revendedores, reventa de hosting, hosting buenos en méxico');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-reseller.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Reseller Web Hosting SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_MX');
    }

}
