<div class="horizontalCards" [ngClass]="class">
  <div class="inner">
    <div class="horizontalCards__item" *ngFor="let item of data">
      <span class="icon size-40" [ngClass]="item.color">
        <svg-icon-sprite
          [src]="'/assets/images/sprites/sprite.svg#' + item.icon"
          [width]="item.hasOwnProperty('iconWidth') ? item.iconWidth + 'px' : '16px'"
          [classes]="item.icon"
          *ngIf="loadImage">
        </svg-icon-sprite>
      </span>
      <div class="inner">
        <div class="text">
          <div class="title-3" i18n>{{item.title}}</div>
          <div class="body-2 color-secondary" i18n>{{item.text}}</div>
        </div>
        <div class="footer" *ngIf="item.hasOwnProperty('price')">
          <span class="body-2">
            <span class="color-secondary" *ngIf="item.title === 'cPanel'" i18n>Desde </span>
            <span class="price">{{ symbol }} {{item.price | currencyFormat}} </span>
            <span class="color-secondary" i18n>/ Mensual</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
