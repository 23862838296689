<section class="firstScreen has-wave bottom">
  <div class="firstScreen__img">
    <picture>
      <source srcset="/assets/images/web-hosting/hosting-first-screen@2x.webp 2x"
              type="image/webp" media="(min-width: 1280px)">
      <source srcset="/assets/images/web-hosting/hosting-first-screen@1x.webp,
                      /assets/images/web-hosting/hosting-first-screen@2x.webp 2x"
              type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/web-hosting/hosting-first-screen.jpg" media="(min-width: 671px)">
      <source srcset="/assets/images/web-hosting/hosting-firstscreen-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/web-hosting/hosting-firstscreen-mobile@1x.jpg"
            srcset="/assets/images/web-hosting/hosting-firstscreen-mobile@2x.jpg 2x" alt="people">
    </picture>
  </div>
  <div class="wave wave-1">
    <img  src="/assets/images/shared/wave@1x.png"
          srcset="/assets/images/shared/wave@2x.png 2x" alt="wave">
  </div>
  <picture>
    <source srcset="/assets/images/shared/first-screen-decorations@1x.png,
                    /assets/images/shared/first-screen-decorations@2x.png 2x" media="(min-width: 992px)">
    <img  src="/assets/images/shared/firstscreen-decorations-tablet@1x.png"
          srcset="/assets/images/shared/firstscreen-decorations-tablet@2x.png 2x" alt="decorations" class="decorations">
  </picture>

  <div class="container">
    <div class="firstScreen__info">
      <div class="suptitle upper color-accent-green" i18n>Web hosting</div>
      <h1 class="title-1" i18n>Hosting económico <br>y sencillo</h1>
      <div class="subheading" i18n>Desde {{symbol}} {{ dataCards.web_hosting.plans[0].price | currencyFormat }} {{ getTaxSymbol() }} al año</div>

      <div class="wrapButton">
        <button id="l-wh-plans" class="btn btnAccent rounded" mat-button (click)="navigateToSection('plans-ts')" i18n>Ver Planes</button>
        <button id="l-contactanos" class="btn btnTransp rounded" mat-button [routerLink]="['/contactar-con-ventas/']" i18n>Contáctanos</button>
      </div>
    </div>
  </div>
</section>

<app-cards
    [buttonsId]="['l-wh-contratar-estandar', 'l-wh-contratar-plus']"
    [data]="dataCards.web_hosting.plans"
    [suptitle]="suptitle"
    [title2]="title2"
    [class]="'hosting'"
    [preRender]="false" (deferLoad)="showImage1 = true"
    class="plans-ts"
    data-offset="40">
</app-cards>


<!-- START sectionSales-->
<app-section-sales [data]="sales" [loadImage]="showImage1" [preRender]="false" (deferLoad)="showImage2 = true"></app-section-sales>
<!-- END -->

<!-- START sectionColumns--publisher -->
<section class="sectionColumns sectionColumns--publisher">
  <div class="sectionColumns__col">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>Site publisher</div>
      <h2 class="title-2" i18n>Haz tu propia página web con nuestro creador de sitios</h2>
      <div class="body-1 color-secondary" i18n>Nuestro Creador de Sitios Web te entregará una experiencia sencilla e intuitiva con plantillas personalizables para que puedas crear y publicar tu propia página en poco tiempo.</div>
    </div>
  </div>

  <div class="sectionColumns__col">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/web-hosting/site-publisher-desktop@2x.webp 2x"
              type="image/webp" media="(min-width: 670px)">
      <source srcset="/assets/images/web-hosting/site-publisher-desktop@1x.png,
                      /assets/images/web-hosting/site-publisher-desktop@2x.png 2x" media="(min-width: 670px)">
      <source srcset="/assets/images/web-hosting/site-publisher-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/web-hosting/site-publisher-mobile@1x.png"
            srcset="/assets/images/web-hosting/site-publisher-mobile@2x.png 2x" alt="Site publisher">
    </picture>
  </div>
  <img  src="/assets/images/web-hosting/publicher-decorations-desktop@1x.png"
        srcset="/assets/images/web-hosting/publicher-decorations-desktop@2x.png 2x" alt="decorations" class="decorations" *ngIf="!isTablet && showImage2">
</section>
<!-- END -->

<!-- START sectionCustomMail -->
<section class="sectionCustomMail has-wave bottom">
  <div class="wave wave-1" *ngIf="showImage2">
    <picture>
      <source srcset="/assets/images/shared/wave@1x.png,
                      /assets/images/shared/wave@2x.png 2x" media="(min-width: 992px)">
        <img  src="/assets/images/shared/wave2@1x.png"
              srcset="/assets/images/shared/wave2@2x.png 2x" alt="wave">
    </picture>
  </div>

  <img  src="/assets/images/web-hosting-full/section-mail-decorations@1x.png"
        srcset="/assets/images/web-hosting-full/section-mail-decorations@2x.png 2x" alt="decorations" class="decorations" *ngIf="showImage2">

  <div class="wrapImg" *ngIf="showImage2">
    <picture>
      <source srcset="/assets/images/shared/comma-right@1x.webp,
                      /assets/images/shared/comma-right@2x.webp 2x"
                      type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/shared/comma-right@1x.png,
                      /assets/images/shared/comma-right@2x.png 2x" media="(min-width: 671px)">
      <source srcset="/assets/images/shared/comma-left-mobile@1x.webp,
                      /assets/images/shared/comma-left-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/shared/comma-left-mobile@1x.png"
            srcset="/assets/images/shared/comma-left-mob/assets/images/web-hosting/site-publisher-mobile@1x.pngile@2x.png 2x" alt="comma">
    </picture>
  </div>
  <div class="wrapper">
    <div class="suptitle upper color-accent-green center" i18n>Correo Personalizado</div>
    <h2 class="title-2 center" i18n>Correo profesional el nombre de tu empresa</h2>
    <div class="body-1 center color-secondary" i18n>Crea cuentas de correo profesionales con tu nombre y el de tu empresa para atraer potenciales clientes.</div>
  </div>
  <div class="image">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/web-hosting/custom-mail-desktop@2x.webp 2x"
              type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/web-hosting/custom-mail-desktop@1x.png,
                      /assets/images/web-hosting/custom-mail-desktop@2x.png 2x" media="(min-width: 671px)">
      <source srcset="/assets/images/web-hosting/custom-mail-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/web-hosting/custom-mail-mobile@1x.png"
            srcset="/assets/images/web-hosting/custom-mail-mobile@2x.png 2x" alt="Personalizado">
    </picture>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--software -->
<section class="sectionColumns sectionColumns--software" [preRender]="false" (deferLoad)="showImage3 = true">
  <div class="sectionColumns__col">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/web-hosting/softaculous-desktop@1x.png,
                      /assets/images/web-hosting/softaculous-desktop@2x.png 2x" media="(min-width: 671px)">
      <img  src="/assets/images/web-hosting/softaculous-mobile@1x.png"
            srcset="/assets/images/web-hosting/softaculous-mobile@2x.png 2x" alt="Softaculous">
    </picture>
  </div>
  <div class="sectionColumns__col">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>Softaculous</div>
      <h2 class="title-2 color-white" i18n>Instalación de Apps en un clic con Softaculous</h2>
      <div class="body-1 color-white" i18n>Con nuestro autoinstalador podrás crear un sitio web a tu medida con tus aplicaciones favoritas casi al instante.</div>
    </div>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--secure -->
<section class="sectionColumns sectionColumns--secure has-wave top">
  <div class="sectionColumns__col">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>seguridad garantizada</div>
      <h2 class="title-2" i18n>Certificado SSL <br>gratis</h2>
      <div class="body-1 color-secondary" i18n>Garantiza la seguridad de los datos que son recogidos en tu sitio con un certificado SSL, además de impulsar tu posicionamiento en el ranking de Google.
      </div>
    </div>
  </div>

  <div class="sectionColumns__col rel">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/web-hosting/secure-desktop@2x.webp 2x"
              type="image/webp" media="(min-width: 481px)">
      <source srcset="/assets/images/web-hosting/secure-desktop@1x.png,
                      /assets/images/web-hosting/secure-desktop@2x.png 2x" media="(min-width: 481px)">
      <source srcset="/assets/images/web-hosting/secure-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/web-hosting/secure-mobile@1x.png"
            srcset="/assets/images/web-hosting/secure-mobile@2x.png 2x" alt="seguridad">
    </picture>
  </div>
  <div class="wave wave-5" *ngIf="showImage2">
    <picture>
      <source srcset="/assets/images/shared/wave5@1x.png,
                      /assets/images/shared/wave5@2x.png 2x" media="(min-width: 992px)">
      <img  src="/assets/images/shared/wave3@1x.png"
            srcset="/assets/images/shared/wave3@2x.png 2x" alt="wave">
    </picture>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--support -->
<app-section-support [data]="dataSupport" [loadImage]="showImage2"></app-section-support>
<!-- END -->

<!-- START  -->
<section class="hostingsTable__wrapper">
  <div class="container">
    <div class="suptitle upper color-accent-green center" i18n>livehost y otros hosting</div>
    <h2 class="title-2 color-white center" i18n>Compara nuestros planes con otros en el mercado</h2>
    <div class="body-1 color-white center" i18n>Nuestros planes de Hosting cuentan con una mayor asignación de recursos,
    brindando un mejor rendimiento y performance por un precio más bajo.</div>

    <app-hostings-table [loadImage]="showImage3"></app-hostings-table>
  </div>
</section>
<!-- END -->

<!-- START payment-logos -->
<app-payment-logos
  [class]="'web-hosting'"
  [buttonId]="'l-wh-plans'"
  [subtext]="'Desde ' + symbol + (dataCards.web_hosting.plans[0].price | currencyFormat) + ' anual'"
  [loadImage]="showImage3">
</app-payment-logos>
<!-- END -->

<app-footer [loadImage]="showImage3"></app-footer>
