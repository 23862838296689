import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { INoResult } from './no-search-result/no-search-result.component';
import { LocaleService } from '../services/locale.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-direct-payment',
    templateUrl: './direct-payment.component.html',
    styleUrls: ['./direct-payment.component.scss']
})
export class DirectPaymentComponent implements OnInit {
    public isLoading = false;
    public isComplete = false;

    public dataNoResult: INoResult;
    public isNoResult = false;

    public dataResult;

    public noResult: INoResult = {
        icon: 'icon-sprite-close-circle',
        title: 'No se encontraron resultados.',
        text: 'Lo sentimos! no se encontraron resultados, por favor vuelve a intentarlo más tarde.',
        state: 'error'
    }

    public notRegister: { "error": INoResult, "badInput": INoResult } = {
        "error": {
            icon: "icon-sprite-close-circle",
            title: '<b>No se encontraron</b> resultados<span class="accent-primary">.</span>',
            text: '<b>Lo sentimos!</b> no se encontraron resultados, por favor vuelve a intentarlo más tarde.',
            state: 'error'
        },
        "badInput": {
            icon: "icon-sprite-close-circle",
            title: '<b>No se encontraron</b> resultados<span class="accent-primary">.</span>',
            text: 'Los datos ingresados no corresponden a un Dominio, un Correo electrónico o un N° de Orden. Por favor verifica la información ingresada.',
            state: 'error'
        }
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private localeService: LocaleService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this._scrollTop();
    }

    public getLoadingStatus(ev): void {
        this.isLoading = ev;
    }

    public getCompleteStatus(ev): void {
        this.isComplete = ev.show;
        this.dataResult = ev;
    }

    public getNoResultVisibility(ev): void {
        this.isNoResult = ev.show;
        if (ev.status === 'No found') {
            this.dataNoResult = this.noResult;
        } else {
            this.dataNoResult = this.notRegister[ev.status];
        }
    }

    private _scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

}
