<div class="sectionSales">
  <h2 class="title-2">{{access}} a descuentos:</h2>
  <div class="sectionSales__inner">
    <div class="sectionSales__item" *ngFor="let item of data">
      <span class="icon size-40" [ngClass]="item.color">
        <svg-icon-sprite
          [src]="'/assets/images/sprites/sprite.svg#' + item.icon"
          [width]="'20px'"
          [classes]="item.icon"
          *ngIf="loadImage">
        </svg-icon-sprite>
      </span>
      <div class="inner">
        <div class="title-3" i18n>{{item.title}}</div>
        <div class="body-2" i18n>{{item.text}}</div>
      </div>
    </div>
  </div>
</div>
