<div [ngClass]="class">
  <div class="suptitle upper center color-accent-green" *ngIf="suptitle !== undefined" i18n>{{suptitle}}</div>
  <h2 class="title-2 center color-white" *ngIf="title2 !== undefined" i18n>{{title2}}</h2>
  <div #slider class="wrapCards" [class.col-3]="data.length > 2">
    <div class="card" *ngFor="let card of data; let i = index">
      <div class="card__title" i18n>{{card.name}}</div>
      <div class="card__price"><span>{{ symbol }}</span>{{card.price | currencyFormat}}</div>
      <div class="card__period" i18n>{{period}} {{ getTaxSymbol() }}</div>
      <ul class="card__list">
        <li [innerHtml]="point" *ngFor="let point of card.list" i18n></li>
      </ul>
      <button [id]="buttonsId == undefined ? card.buttonId : buttonsId[i]" class="btn btnAccent rounded" mat-button (click)="goToUrl(card.buttonUrl)" i18n>Contratar</button>
    </div>
  </div>
</div>
