<section class="firstScreen has-wave bottom">
  <div class="wave wave-5">
    <img  src="/assets/images/shared/wave5@1x.png"
          srcset="/assets/images/shared/wave5@2x.png 2x" alt="wave">
  </div>

  <img  src="/assets/images/payment-methods/firstscreen-payment-decorations@1x.png"
        srcset="/assets/images/payment-methods/firstscreen-payment-decorations@2x.png 2x" alt="decorations" class="decorations">

  <div class="container">
    <div class="firstScreen__info">
      <div class="suptitle upper color-accent-green center" i18n>Formas de pago</div>
      <h1 class="title-1 center" i18n>Paga con el método que prefieras</h1>
    </div>
  </div>

</section>

<section class="has-wave bottom">
  <div class="wave wave-6">
    <picture>
      <source srcset="/assets/images/shared/wave6@1x.png,
                      /assets/images/shared/wave6@2x.png 2x" media="(min-width: 992px)">
      <img  src="/assets/images/shared/wave6-tablet@1x.png"
            srcset="/assets/images/shared/wave6-tablet@2x.png 2x" alt="wave">
    </picture>
  </div>
  <app-payment-options></app-payment-options>
</section>

<app-footer [loadImage]="true"></app-footer>
