import { Component, OnInit, ViewChild, ElementRef, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { LIST_SUPPORT, LIST_HOSTING, LIST_VPS } from '../../data-header';
import { DialogCustomMenuComponent } from '../dialogs/dialog-custom-menu/dialog-custom-menu.component';
import { LocaleService } from '../../services/locale.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    @ViewChild('header', { static: true }) header: ElementRef;
    @ViewChild('triggerDialogSupport', { static: true }) triggerDialogSupport: ElementRef;
    @ViewChild('triggerDialogHosting', { static: true }) triggerDialogHosting: ElementRef;
    @ViewChild('triggerDialogVPS', { static: true }) triggerDialogVPS: ElementRef;
    isOpenMenu = false;
    isShowMenuFirst = false;
    isShowMenuSupport = false;
    public isShowMenuVPS = false;
    public isMobile: boolean = false;
    tld = this.localeService.currentContact.country_code;

    contactUs = 'Contáctanos';
    dataSupport;
    dataVPS;

    @HostListener('window:resize')
    onResize() {
        this.isMobile = window.innerWidth < 1200;
    }

    @HostListener('window:scroll')
    onScroll(): void {
        this.checkHeaderPosition();
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        public dialog: MatDialog,
        private localeService: LocaleService
    ) {
    }

    ngOnInit() {
        this.isMobile = window.innerWidth < 1200;
        this.dataSupport = LIST_SUPPORT;
        this.dataVPS = LIST_VPS;
        if (this.tld === 'ar') {
            this.dataSupport[2].url += 'com.ar';
            this.contactUs = 'Contactanos';
        } else if (this.tld === 'co') {
            this.dataSupport[2].url += 'com.co';
            this.contactUs = 'Contáctenos';
        } else {
            this.dataSupport[2].url += this.tld;
        }
    }

    checkHeaderPosition(): void {
        const offsetY = window.scrollY;
        const headerEl = this.header.nativeElement;
        if (offsetY >= 56 || pageYOffset >= 56) {
            headerEl.classList.add('fixed');
        } else {
            headerEl.classList.remove('fixed');
        }
    }

    openMobileMenu() {
        const body = <HTMLBodyElement>this._document.body;
        body.classList.add('open-menu');
        this.isOpenMenu = true;
    }

    closeMobileMenu() {
        const body = <HTMLBodyElement>this._document.body;
        body.classList.remove('open-menu');
        this.isOpenMenu = false;
        this.isShowMenuFirst = false;
        this.isShowMenuSupport = false;
    }

    showDropdownMenu(menu: string) {
        switch (menu) {
            case 'first':
                this.isShowMenuFirst = true;
                break;
            case 'soporte':
                if(window.innerWidth <= 1200) this.isShowMenuSupport = true;
                break;
            case 'VPS':
                this.isShowMenuVPS = true;
                break;
            default:
                break;
        }
    }

    hideMenuDropdown(menu) {
        switch (menu) {
            case 'first':
                this.isShowMenuFirst = false;
                break;
            case 'soporte':
                if(window.innerWidth <= 1200) this.isShowMenuSupport = false;
                break;
            case 'VPS':
                this.isShowMenuVPS = false;
                break;
            default:
                break;
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    openDialogCustomMenu(selectedItem) {
        if (window.innerWidth > 1200) {
            let triggerPositionLeft;
            let selectedList;
            let width: string = '204px';

            switch (selectedItem) {
                case 'hosting':
                    triggerPositionLeft = this.triggerDialogHosting.nativeElement.getBoundingClientRect().left + 14 + 'px';
                    selectedList = LIST_HOSTING;
                    break;
                case 'support':
                    triggerPositionLeft = this.triggerDialogSupport.nativeElement.getBoundingClientRect().left - 24 + 'px';
                    selectedList = LIST_SUPPORT;
                    break;
                case 'VPS':
                    triggerPositionLeft = this.triggerDialogVPS.nativeElement.getBoundingClientRect().left - 24 + 'px';
                    selectedList = LIST_VPS;
                    width = '130px';
                    break;
                default:
                    break;
            }

            const dialogRef = this.dialog.open(DialogCustomMenuComponent, {
                panelClass: 'dialogCustomMenu',
                width: width,
                backdropClass: 'transparent',
                position: {
                    top: '72px',
                    left: triggerPositionLeft
                },
                data: {
                    dataList: selectedList,
                    dialog: selectedItem
                }
            });

            const html = <HTMLElement>this._document.getElementsByTagName('html')[0];
            if (html.classList.contains('cdk-global-scrollblock')) {
                html.classList.add('remove-fixed');
            }

            dialogRef.afterClosed().subscribe(() => {
                html.classList.remove('remove-fixed');
            });
        }
    }
}
