import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { ScrollToClass } from './../../../../common/scrollTo.class';

@Component({
    selector: 'app-web-hosting',
    templateUrl: './web-hosting.component.html',
    styleUrls: ['./web-hosting.component.sass']
})

export class WebHostingComponent extends ScrollToClass implements OnInit {

    public URL = URL;
    public tld = this.localeService.currentContact.country_code;
    public symbol = this.localeService.symbol;

    public isTablet: boolean;

    // load images
    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    public title2 = 'Precios a tu alcance';
    public suptitle = 'Elige tu plan de hosting';
    public dataCards;
    public sales = [
        {
            title: 'Hasta 21% descuento',
            text: 'Al contratar 2 años en los planes Web Hosting',
            icon: 'icon-local-offer',
            color: 'bg-accent-violet'
        }, {
            title: 'Hasta 26% descuento',
            text: 'Al contratar 3 años en los planes Web Hosting',
            icon: 'icon-gift',
            color: 'bg-accent-red'
        }
    ];

    public dataSupport = {
        suptitle: 'soporte técnico',
        title: 'Estamos contigo',
        // tslint:disable-next-line:max-line-length
        text: '¿Nuevo en hosting? No te preocupes, estamos aquí para ayudarte. Comunícate de Lunes a Viernes con nuestro equipo de soporte y ellos resolverán todas tus dudas.',
        buttonId: 'l-wh-contactar2'
    };

    public dataSupport_AR = {
        suptitle: 'soporte técnico',
        title: 'Estamos contigo',
        // tslint:disable-next-line:max-line-length
        text: '¿Nuevo en hosting? No te preocupés, estamos aquí para ayudarte. Comunicate de Lunes a Viernes con nuestro equipo de soporte y ellos resolverán todas tus dudas.',
        buttonId: 'l-wh-contactar2'
    };

    public dataSupport_CO = {
        suptitle: 'soporte técnico',
        title: 'Estamos con usted',
        // tslint:disable-next-line:max-line-length
        text: '¿Nuevo en hosting? No se preocupe, estamos aquí para ayudarle. Comuníquese de Lunes a Viernes con nuestro equipo de soporte y ellos resolverán todas sus dudas.',
        buttonId: 'l-wh-contactar2'
    };

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isTablet = window.innerWidth <= 992 ? true : false;
    }

    constructor(
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private el: ElementRef
    ) {
        super(el);
        this.dataCards = this.localeService.getContent().plans;
    }

    ngOnInit() {
        this.isTablet = window.innerWidth <= 992 ? true : false;

        if (this.tld === 'ar') {
            this._setMeta_AR();
            this.suptitle = 'Elegí tu plan de hosting';
            this.title2 = 'Precios al alcance de vos';
            this.dataSupport = this.dataSupport_AR;
        } else if (this.tld === 'co') {
            this._setMeta_CO();
            this.suptitle = 'Elija su plan de hosting';
            this.title2 = 'Precios a su alcance';
            this.dataSupport = this.dataSupport_CO;

        } else if (this.tld === 'pe') {
            this._setMeta_PE();
        } else if (this.tld === 'mx') {
            this._setMeta_MX();
        } else {
            this._setMeta_CL();
        }
    }

    public navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }

    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    private _setMeta_CL() {
        this._meta.setTitle('LIVEHOST | Tu proyecto web en un Hosting Económico y Sencillo');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Sabemos lo que buscas, contrata un hosting fácil de usar y económico. Los planes de LiveHost van desde los $18.900 Anuales + IVA');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting chile, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-web-hosting.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_CL');
    }

    private _setMeta_PE() {
        this._meta.setTitle('LIVEHOST | Tu proyecto web en un Hosting Económico y Sencillo');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Sabemos lo que buscas, contrata un hosting fácil de usar y económico. Los planes de LiveHost van desde los S/95 Anuales + IGV');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting perú, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-web-hosting.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_PE');
    }

    private _setMeta_CO() {
        this._meta.setTitle('LIVEHOST | Su proyecto web en un Hosting Económico y Sencillo');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Sabemos lo que busca, contrate un hosting fácil de usar y económico. Los planes de LiveHost van desde los $90.000 Anuales');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting colombia, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-web-hosting.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_CO');
    }

    private _setMeta_AR() {
        this._meta.setTitle('LIVEHOST | Tu proyecto web en un Hosting Económico y Sencillo');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Sabemos lo que buscás, contrata un hosting fácil de usar y económico. Los planes de LiveHost van desde los $1.160 Anuales');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting argentina, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-web-hosting.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_AR');
    }

    private _setMeta_MX() {
        this._meta.setTitle('LIVEHOST | Tu proyecto web en un Hosting Económico y Sencillo');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Sabemos lo que buscas, contrata un hosting fácil de usar y económico. Los planes de LiveHost van desde los $550 Anuales + IVA');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting méxico, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-web-hosting.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_MX');
    }

    public getTaxSymbol(): string {
        if (this.tld === 'pe') { return '+ IGV'; }
        if (this.tld === 'mx' || this.tld === 'co' || this.tld === 'ar') { return ''; }
        return '+ IVA';
    }

}
