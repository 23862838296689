import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare var tns: any;

@Component({
    selector: 'app-reviews-slider',
    templateUrl: './reviews-slider.component.html'
})

export class ReviewsSliderComponent implements OnInit, AfterViewInit {
    reviewsList = [
        {
            text: 'Es excelente su soporte técnico online y el servicio de hosting que ofrecen. Felicitaciones',
            name: 'Patricio Rodriguez',
            image: 'avatar-1'
        }, {
            text: 'Había experimentado en otros servidores, pero hace años sigo manteniendo la cuenta en Livehost. Muy buen servicio.',
            name: 'Antonio Esquivel',
            image: 'avatar-2'
        }, {
            text: 'Hoy ya son dos años desde que uso LiveHost y hasta ahora ha funcionado perfectamente. Buen soporte, buenos precios.',
            name: 'Erica Duque',
            image: 'avatar-3'
        }
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.initSlider();
        }
    }

    initSlider() {
        const slider = tns({
            container: '.slider-ts',
            items: 2,
            autoWidth: true,
            speed: 400,
            loop: true,
            nav: true,
            controls: false,
            responsive: {
                670: {
                    items: 1,
                }
            },
        });
    }

}
