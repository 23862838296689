import { Component, OnInit, Inject, PLATFORM_ID, Input } from '@angular/core';
import { CONTACTS } from '../../contacts.constants';
import { Contact } from '../../contact';
import { URL } from '../../../environments/environment';
import { LocaleService } from '../../services/locale.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
    @Input() loadImage: boolean;
    contact: Contact;
    localetld = this.localeService.currentContact.country_code;
    footertld = this.localeService.currentContact.footer_link;
    contactList = CONTACTS;
    currentCountry;
    com = '';

    public URL = URL;

    footerList = [
        {
            title: 'Servicios',
            list: [
                {
                    id: 'sh-f-wh',
                    text: 'Web Hosting',
                    url: '/web-hosting/',
                    isRouter: true
                }, {
                    id: 'sh-f-whp',
                    text: 'Web Hosting Full',
                    url: '/web-hosting-full/',
                    isRouter: true
                }, {
                    id: 'sh-f-vps',
                    text: 'VPS',
                    url: '/ssd-vps-cloudserver/',
                    isRouter: true
                }, {
                    id: 'sh-f-whr',
                    text: 'Reseller',
                    url: '/reseller/',
                    isRouter: true
                }, {
                    id: 'sh-f-migrar',
                    text: 'Dominios',
                    url: '/registro-dominio',
                    isRouter: true
                }
            ]
        }, {
            title: 'Livehost',
            list: [
                {
                    id: 'sh-f-blog',
                    text: 'Blog',
                    url: this.URL.LIVEHOST_WEBSITE_BASE + this.footertld + '/blog',
                    isRouter: false
                }, {
                    id: 'sh-f-payform',
                    text: 'Formas de pago',
                    url: '/formasdepago/',
                    isRouter: true
                }, {
                    id: 'sh-f-terms',
                    text: 'Términos y Condiciones',
                    url: this.URL.LIVEHOST_TERMS_CONDITIONS,
                    isRouter: false
                }
            ]
        }, {
            title: 'Soporte',
            list: [
                {
                    id: 'sh-f-status',
                    text: 'Status Uptime',
                    url: this.URL.LIVEHOST_STATUS,
                    isRouter: false
                }, {
                    id: 'sh-f-tutorial',
                    text: 'Video Tutoriales',
                    url: this.URL.LIVEHOST_YOUTUBE,
                    isRouter: false
                }, {
                    id: 'sh-f-doc',
                    text: 'Documentación',
                    url: this.URL.LIVEHOST_DOC_BASE,
                    isRouter: false
                }, {
                    id: 'sh-f-contactsales',
                    text: 'Contactar con Ventas',
                    url: 'contactar-con-ventas/',
                    isRouter: true
                }
            ]
        }
    ];

    socList = [
        {
            id: 'sh-f-fb',
            icon: 'icon-facebook',
            url: this.URL.LIVEHOST_FACEBOOK,
            width: '14'
        }, {
            id: 'sh-f-tw',
            icon: 'icon-twiter',
            url: this.URL.LIVEHOST_TWITTER,
            width: '14'
        }, {
            id: 'sh-f-yt',
            icon: 'icon-youtube',
            url: this.URL.LIVEHOST_YOUTUBE,
            width: '23'
        }
    ];

    constructor(
        private localeService: LocaleService,
        @Inject(PLATFORM_ID) private platformId: any,
        private _router: Router,
    ) {
        this.currentCountry = this.getContact(this.localetld);
    }

    ngOnInit() {
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    navigationTo(url, bool) {
        if (!bool) {
            this.goToUrl(url);
        } else {
            this._router.navigate([url]);
            this.scrollTop();
        }
    }

    getContact(TLD: string): Contact {
        const aux = TLD;
        return this.contactList.filter(c => c.country_code === aux)[0];
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
}
