export const CONTACTS = [
    {
        city: 'Santiago',
        country: 'Chile',
        country_code: 'cl',
        currency_code: 'CLP',
        currency_number: 1,
        default_domain: '.cl',
        footer_link: 'cl',
        flag_url: '/assets/images/flags/cl.svg',
        phone_code: '+56',
        main_phone: '+56 2 3239 4624',
        main_phone_href: '+56232394624',
        tax: '+ IVA'
    }, {
        city: 'Lima',
        country: 'Perú',
        country_code: 'pe',
        currency_code: 'PEN',
        currency_number: 3,
        default_domain: '.pe',
        footer_link: 'pe',
        flag_url: '/assets/images/flags/pe.svg',
        phone_code: '+51',
        main_phone: '+51 1709 4332',
        main_phone_href: '+5117094332',
        tax: '+ IGV'
    }, {
        city: 'Bogotá',
        country: 'Colombia',
        country_code: 'co',
        currency_code: 'COP',
        currency_number: 6,
        default_domain: '.com.co',
        footer_link: 'com.co',
        flag_url: '/assets/images/flags/co.svg',
        phone_code: '+57',
        main_phone: '+57 601 489 6972',
        main_phone_href: '+576014896972',
        tax: ''
    }, {
        city: 'Buenos Aires',
        country: 'Argentina',
        country_code: 'ar',
        currency_code: 'ARS',
        currency_number: 4,
        default_domain: '.ar',
        footer_link: 'ar',
        flag_url: '/assets/images/flags/ar.svg',
        phone_code: '+54',
        main_phone: '+54 11 3988 9121',
        main_phone_href: '+541139889121',
        tax: ''
    }, {
        city: 'Ciudad de México',
        country: 'México',
        country_code: 'mx',
        currency_code: 'MXN',
        currency_number: 8,
        default_domain: '.com.mx',
        footer_link: 'mx',
        flag_url: '/assets/images/flags/mx.svg',
        phone_code: '+52',
        main_phone: '+52 55 4169 6410',
        main_phone_href: '+525541696410',
        tax: ''
    }
];
