export const LIST_HOSTING = [
    {
        id: 'l-h-webhosting',
        text: 'Web Hosting',
        url: 'web-hosting/'
    }, {
        id: 'l-h-webhostingfull',
        text: 'Web Hosting Full',
        url: 'web-hosting-full/'
    }, {
        id: 'l-h-reseller',
        text: 'Reseller',
        url: 'reseller/'
    }
];

export const LIST_SUPPORT = [
    {
        id: 'l-h-status',
        text: 'Status Uptime',
        url: 'https://status.livehost.host/'
    }, {
        id: 'l-h-support-tutorial',
        text: 'Video Tutoriales',
        url: 'https://www.youtube.com/channel/UC45rdxGiG11qOVCwrWKMXfQ'
    }, {
        id: 'l-h-support-doc',
        text: 'Documentación',
        url: 'https://docs.livehost.'
    }, {
        id: 'l-h-support-suggestions',
        text: 'Sugerencias o reclamos',
        url: 'https://survey.typeform.com/to/zQ7I32J9#empresa=Livehost&issuetype=11208'
    }
];

export const LIST_VPS = [
    {
        id: 'l-h-vps',
        text: 'VPS',
        url: 'ssd-vps-cloudserver/'
    }, {
        id: 'l-h-vps-backup',
        text: 'VPS + Backup',
        url: 'cloud-vps-backup/'
    }
]
