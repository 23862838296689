import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-section-support',
    templateUrl: './section-support.component.html',
    styleUrls: ['./section-support.component.sass']
})

export class SectionSupportComponent implements OnInit {
    @Input() data;
    @Input() class;
    @Input() loadImage;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private _router: Router,
    ) {
    }

    ngOnInit() {
    }

    navigationTo(url) {
        this._router.navigate([url]);
        this.scrollTop();
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
}
