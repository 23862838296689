import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dialog-custom-menu',
    templateUrl: './dialog-custom-menu.component.html'
})
export class DialogCustomMenuComponent implements OnInit {
    dataList;
    selectedDialog;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(PLATFORM_ID) private platformId: any,
        private _router: Router,
    ) { }

    ngOnInit() {
        this.dataList = this.data.dataList;
        this.selectedDialog = this.data.dialog;
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    navigationTo(link) {
        if (this.selectedDialog === 'support') {
            this.goToUrl(link);
        } else {
            this._router.navigate([link]);
            setTimeout(() => {
                this.scrollTop();
            }, 500);
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
}
