import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { LocaleService } from '../../services/locale.service';

@Component({
    selector: 'app-cards',
    templateUrl: './cards.component.html'
})

export class CardsComponent implements OnInit, AfterViewInit {
    @ViewChild('slider', { static: true }) slider: ElementRef;
    @Input() data;
    @Input() suptitle;
    @Input() title2;
    @Input() class;
    @Input() buttonsId;

    period: string;
    tld = this.localeService.currentContact.country_code !== 'ar' ? this.localeService.currentContact.country_code : 'com.ar';
    symbol = this.localeService.symbol;

    sliderProps = {
        slider: undefined,
        accentItem: undefined,
        itemHeight: undefined,
        margin: undefined,
    };

    constructor(
        private localeService: LocaleService,
    ) {
    }

    ngOnInit() {
        const isReseller = this.data === this.localeService.getContent().plans.reseller.plans;
        if (isReseller) {
            this.period = 'Mensual';
            if (this.tld === 'co') {
                this.period = 'Anual';
            }
        } else {
            this.period = 'Anual';
        }
    }

    ngAfterViewInit() {
        // if (window.innerWidth <= 767) {
        //     setTimeout(() => {
        //         this.initCustomSlider();
        //     }, 100);
        // }
    }

    initCustomSlider() {
        const props = this.sliderProps;
        props.slider = <HTMLElement>this.slider.nativeElement;
        props.slider.style.height = props.slider.children[0].clientHeight + 'px';
        const count = props.slider.children.length > 2 ? 80 : 0;
        props.slider.style.marginBottom = props.slider.children[0].clientHeight / 2 + 60 + count + 'px';

        props.slider.margin = '10';

        setInterval(() => {
            this.transformSlider();
        }, 2000);
    }

    transformSlider() {
        const props = this.sliderProps;
        const slideToMove = props.slider.children[props.slider.children.length - 1].cloneNode(true);
        props.slider.insertBefore(slideToMove, props.slider.children[0]);

        for (let i = 0; i <= props.slider.children.length - 1; i++) {
            props.slider.children[0].style.transform = 'translateY(0) translateX(-50%)';
            // tslint:disable-next-line:max-line-length
            props.slider.children[i].style.transform = 'translateY(' + ((6 + i) * 10) + '%) translateY(' + props.slider.margin * i + 'px) translateX(-50%) rotateX(75deg)';
        }
        props.slider.children[props.slider.children.length - 1].remove();
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    public getTaxSymbol(): string {
        if (this.tld === 'pe') { return '+ IGV'; }
        if (this.tld === 'mx' || this.tld === 'co' || this.tld === 'com.ar') { return ''; }
        return '+ IVA';
    }

}
