<section class="firstScreen has-wave bottom">
  <div class="firstScreen__img">
    <picture>
      <source srcset="/assets/images/home/people@2x.webp 2x"
              type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/home/people.jpg" media="(min-width: 671px)">
      <source srcset="/assets/images/home/people-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/home/people-mobile@1x.jpg"
            srcset="/assets/images/home/people-mobile@2x.jpg 2x" alt="people">
    </picture>
  </div>
  <div class="wave wave-1">
    <img  src="/assets/images/shared/wave@1x.png"
          srcset="/assets/images/shared/wave@2x.png 2x" alt="wave">
  </div>
  <picture>
    <source srcset="/assets/images/home/firstscreen-home-decorations@1x.png,
                    /assets/images/home/firstscreen-home-decorations@2x.png 2x" media="(min-width: 992px)">
    <img  src="/assets/images/shared/firstscreen-decorations-tablet@1x.png"
          srcset="/assets/images/shared/firstscreen-decorations-tablet@2x.png 2x" alt="decorations" class="decorations">
  </picture>

  <div class="container">
    <div class="firstScreen__info">
      <div class="suptitle upper color-accent-green">Livehost</div>
      <h1 class="title-1" i18n>Hosting y Dominio fácil para ti</h1>
      <div class="subheading" i18n>Desde {{symbol}} {{ dataCards.web_hosting.plans[0].price | currencyFormat }} {{ getTaxSymbol() }} al año</div>

      <div class="wrapButton">
        <button id="l-plans" class="btn btnAccent rounded" mat-button (click)="navigateToSection('plans-ts')" i18n>Ver Planes</button>
        <button id="l-contactanos" class="btn btnTransp rounded" mat-button [routerLink]="['contactar-con-ventas/']" i18n>Contáctanos</button>
      </div>
    </div>
  </div>
</section>

<app-cards
  [data]="dataCards.web_hosting.plans"
  [preRender]="false" (deferLoad)="showImage1 = true"
  class="plans-ts"
  data-offset="48">
</app-cards>

<!-- START sectionColumns--plans -->
<section class="sectionColumns sectionColumns--plans">
  <div class="sectionColumns__col">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>Planes de hosting</div>
      <h2 class="title-2" i18n>Precios para todos los bolsillos</h2>
      <div class="body-1 color-secondary" [preRender]="false" (deferLoad)="showImage2 = true" i18n>En LiveHost diseñamos planes pensando en tus necesidades, para que así escoger el hosting ideal sea más sencillo. Revisa las opciones que te concedemos y crece con tu sitio web.</div>
    </div>
  </div>

  <div class="sectionColumns__col rel">
    <div class="wrapImg" *ngIf="showImage1">
      <picture>
        <source srcset="/assets/images/shared/comma-left@1x.webp,
                        /assets/images/shared/comma-left@2x.webp 2x"
                        type="image/webp" media="(min-width: 481px)">
        <source srcset="/assets/images/shared/comma-left@1x.png,
                        /assets/images/shared/comma-left@2x.png 2x" media="(min-width: 481px)">
        <source srcset="/assets/images/shared/comma-left-mobile@1x.webp,
                        /assets/images/shared/comma-left-mobile@2x.webp 2x" type="image/webp">
        <img  src="/assets/images/shared/comma-left-mobile@1x.png"
              srcset="/assets/images/shared/comma-left-mobile@2x.png 2x" alt="comma">
      </picture>
    </div>
    <app-tab-plans [data]="dataCards"></app-tab-plans>
  </div>
  <img  src="/assets/images/home/section-plans-decorations@1x.png"
        srcset="/assets/images/home/section-plans-decorations@2x.png 2x" alt="points" class="decorations" *ngIf="!isTablet && showImage1">
</section>
<!-- END -->

<!-- START wrapServices -->
<section class="wrapServices has-wave bottom">
  <div class="wave wave-2" *ngIf="showImage2">
    <img  src="/assets/images/shared/wave2@1x.png"
          srcset="/assets/images/shared/wave2@2x.png 2x" alt="wave">
  </div>
  <img  src="/assets/images/home/section-services-decorations@1x.png"
        srcset="/assets/images/home/section-services-decorations@2x.png 2x" alt="points" class="decorations" *ngIf="!isTablet && showImage2">
  <div class="container rel">
    <div class="suptitle upper center color-accent-green" i18n>Servicios</div>
    <h2 class="title-2 center" i18n>Todo lo que tu sitio web necesita</h2>

    <app-options [options]="options" [class]="'services'" [loadImage]="showImage2"></app-options>
  </div>
</section>
<!-- END -->

<!-- START infoCards__wrapper -->
<section class="infoCards__wrapper bg-blue" [preRender]="false" (deferLoad)="showImage3 = true">
  <img  src="/assets/images/home/section-cards-decorations@1x.png"
        srcset="/assets/images/home/section-cards-decorations@2x.png 2x" alt="points" class="decorations" *ngIf="!isMobile && showImage2">
  <div class="container rel">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/shared/bg-circle-blue@1x.webp,
                      /assets/images/shared/bg-circle-blue@2x.webp 2x"
                      type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/shared/bg-circle-blue@1x.png,
                      /assets/images/shared/bg-circle-blue@2x.png 2x" media="(min-width: 671px)">
      <source srcset="/assets/images/shared/bg-circle-blue-mobile@1x.webp,
                      /assets/images/shared/bg-circle-blue-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/shared/bg-circle-blue-mobile@1x.png"
            srcset="/assets/images/shared/bg-circle-blue-mobile@2x.png 2x" alt="circle">
    </picture>
    <div class="suptitle upper center color-accent-green rel" i18n>Planes de hosting</div>
    <h2 class="title-2 center color-white rel" i18n>¿Por qué elegir Livehost?</h2>

    <app-info-cards [data]="planHosting" [class]="'stairs rel'" [loadImage]="showImage2"></app-info-cards>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--advantages -->
<section class="sectionColumns sectionColumns--advantages has-wave top">
  <div class="sectionColumns__col rel">
    <div class="wrapImg" *ngIf="showImage2">
      <picture>
        <source srcset="/assets/images/shared/comma-right@1x.webp,
                        /assets/images/shared/comma-right@2x.webp 2x"
                        type="image/webp" media="(min-width: 481px)">
        <source srcset="/assets/images/shared/comma-right@1x.png,
                        /assets/images/shared/comma-right@2x.png 2x" media="(min-width: 481px)">
        <source srcset="/assets/images/shared/comma-right-mobile@1x.webp,
                        /assets/images/shared/comma-right-mobile@2x.webp 2x" type="image/webp">
        <img  src="/assets/images/shared/comma-right-mobile@1x.png"
              srcset="/assets/images/shared/comma-right-mobile@2x.png 2x" alt="comma">
      </picture>
    </div>
    <app-info-cards [data]="advantages" [class]="'advantages rel'" [isIconCircle]="true" [loadImage]="showImage2"></app-info-cards>
  </div>
  <div class="sectionColumns__col">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>promesa livehost</div>
      <h2 class="title-2" i18n>Nuestro compromiso</h2>
      <div class="body-1 color-secondary" i18n>Queremos entregarle a nuestros clientes un servicio cercano en el que puedan confiar.</div>
    </div>
  </div>
  <div class="wave wave-3" *ngIf="showImage2">
    <img  src="/assets/images/shared/wave3@1x.png"
         srcset="/assets/images/shared/wave3@2x.png 2x" alt="wave">
  </div>
  <img  src="/assets/images/home/section-advantages-decorations@1x.png"
        srcset="/assets/images/home/section-advantages-decorations@2x.png 2x" alt="points" class="decorations" *ngIf="!isTablet && showImage2">
</section>
<!-- END -->

<!-- START reviews-slider -->
<section class="wrapSlider has-wave bottom">
  <div class="wave wave-4">

    <picture *ngIf="showImage3">
      <source srcset="/assets/images/shared/wave4@1x.png,
                      /assets/images/shared/wave4@2x.png 2x" media="(min-width: 992px)">
      <img  src="/assets/images/shared/wave6-tablet@1x.png"
            srcset="/assets/images/shared/wave6-tablet@2x.png 2x" alt="wave">
    </picture>
  </div>
  <app-reviews-slider *ngIf="showImage3"></app-reviews-slider>
</section>
<!-- END -->

<!-- START payment-logos-->
<app-payment-logos
  [class]="'default'"
  [buttonId]="'l-h-plans'"
  [subtext]="'Desde ' + symbol + (dataCards.web_hosting.plans[0].price | currencyFormat ) + ' ANUAL'"
  [loadImage]="showImage3">
</app-payment-logos>
<!-- END -->

<app-footer [loadImage]="showImage3"></app-footer>

