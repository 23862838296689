import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MetaService, MetaModule } from '@ngx-meta/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { CustomMaterialModule } from '../../../common/material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DialogCustomMenuComponent } from './shared/dialogs/dialog-custom-menu/dialog-custom-menu.component';
import { HomeComponent } from './home/home.component';
import { CardsComponent } from './shared/cards/cards.component';
import { TabPlansComponent } from './shared/tab-plans/tab-plans.component';
import { OptionsComponent } from './shared/options/options.component';
import { InfoCardsComponent } from './shared/info-cards/info-cards.component';
import { ReviewsSliderComponent } from './shared/reviews-slider/reviews-slider.component';
import { PaymentLogosComponent } from './shared/payment-logos/payment-logos.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { SectionSalesComponent } from './shared/section-sales/section-sales.component';
import { SectionSupportComponent } from './shared/section-support/section-support.component';
import { HostingsTableComponent } from './shared/hostings-table/hostings-table.component';
import { WebHostingFullComponent } from './web-hosting-full/web-hosting-full.component';
import { HorizontalCardsComponent } from './shared/horizontal-cards/horizontal-cards.component';
import { ResellerComponent } from './reseller/reseller.component';
import { VpsComponent } from './vps/vps.component';
import { TablePriceComponent } from './shared/table-price/table-price.component';
import { TablePriceBackupComponent } from './shared/table-price-backup/table-price-backup.component';
import { DomainsComponent } from './domains/domains.component';
import { TableDomainZonesComponent } from './shared/table-domain-zones/table-domain-zones.component';
import { TableDomainComponent } from './shared/table-domain/table-domain.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { PaymentOptionsComponent } from './payment-methods/payment-options/payment-options.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ErrorComponent } from './error/error.component';
import { SuccessComponent } from './success/success.component';
import { SuccessGuard } from './success/success.guard';

// pago-directo
import { DirectPaymentComponent } from './direct-payment/direct-payment.component';
import { DirectPaymentFirstScreenComponent } from './direct-payment/direct-payment-first-screen/direct-payment-first-screen.component';
import { SearchResultComponent } from './direct-payment/search-result/search-result.component';
import { SendTicketComponent } from './direct-payment/send-ticket/send-ticket.component';
import { NoSearchResultComponent } from './direct-payment/no-search-result/no-search-result.component';

import { CurrencyFormatPipe } from './currency.pipe';
import { LocaleService } from './services/locale.service';
import { KeysPipe } from './keys.pipe';
import { DomainService } from './services/domain-service';
import { NgxCaptchaModule } from 'ngx-captcha';

import localeAR from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { VpsBackupComponent } from './vps-backup/vps-backup.component';
registerLocaleData(localeAR);

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        DialogCustomMenuComponent,
        HomeComponent,
        WebHostingComponent,
        CardsComponent,
        TabPlansComponent,
        OptionsComponent,
        InfoCardsComponent,
        ReviewsSliderComponent,
        PaymentLogosComponent,
        CurrencyFormatPipe,
        SectionSalesComponent,
        SectionSupportComponent,
        HostingsTableComponent,
        WebHostingFullComponent,
        HorizontalCardsComponent,
        ResellerComponent,
        VpsComponent,
        VpsBackupComponent,
        TablePriceComponent,
        TablePriceBackupComponent,
        DomainsComponent,
        TableDomainZonesComponent,
        TableDomainComponent,
        PaymentMethodsComponent,
        PaymentOptionsComponent,
        ContactsComponent,
        ErrorComponent,
        KeysPipe,
        SuccessComponent,
        DirectPaymentComponent,
        DirectPaymentFirstScreenComponent,
        SearchResultComponent,
        SendTicketComponent,
        NoSearchResultComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'livehost' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MetaModule.forRoot(),
        FormsModule,
        NgxCaptchaModule,
        ReactiveFormsModule,
        CustomMaterialModule,
        IconSpriteModule,
        DeferLoadModule
    ],
    providers: [
        MetaService,
        LocaleService,
        CurrencyFormatPipe,
        DomainService,
        SuccessGuard
    ],
    entryComponents: [
        DialogCustomMenuComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
