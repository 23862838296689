<!-- desktops table -->
<div class="hostingsTable" *ngIf="!isMobile">
  <div class="hostingsTable__head">
    <div class="hostingsTable__cell" *ngFor="let cell of data.hostings">
      <div class="title" *ngIf="cell.name!=='webhostingargentina.net'" i18n>{{cell.name}}</div> <div class="titleV2" *ngIf="cell.name=='webhostingargentina.net'" i18n>{{cell.name}}</div>
      <div class="subtext" i18n><span>Plan</span> {{cell.plan}}</div>
    </div>
  </div>
  <div class="hostingsTable__body">

    <div class="hostingsTable__row">
      <div class="hostingsTable__col">
        <ng-container *ngFor="let row of data.rowsName">
          <div class="hostingsTable__cell color-secondary" i18n>
            {{row}}
          </div>
        </ng-container>
      </div>

      <div class="hostingsTable__row">
        <ng-container *ngFor="let cell of data.hostings">
          <div class="hostingsTable__col">

            <ng-container *ngFor="let property of cell | keys; let i = index">

              <div  class="hostingsTable__cell color-secondary"
                    *ngIf="i > 1 && i !== (cell | keys).length - 1  ? true : false">
                <ng-container *ngIf="property === 'price'; else other">
                  {{ symbol }}{{cell[property] | currencyFormat}} {{ getTaxSymbol() }}
                </ng-container>

                <ng-template #other i18n>
                  {{cell[property]}}
                </ng-template>

              </div>

              <div class="hostingsTable__cell color-secondary"
                    *ngIf="i == (cell | keys).length - 1  ? true : false">
                <div class="rating flex">
                  <span class="icon"
                        [class.outline]="j + 1 > cell.rating"
                        *ngFor="let star of stars(maxRating); let j = index">
                    <svg-icon-sprite
                      [src]="'/assets/images/sprites/sprite.svg#icon-star-rating'"
                      [width]="'16px'"
                      [classes]="'icon-star-rating'"
                      *ngIf="loadImage">
                    </svg-icon-sprite>
                  </span>
                </div>
              </div>

            </ng-container>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
</div>
<!-- END -->

<!-- mobiles slider -->
<div #slider class="hostingsTable__slider" [hidden]="isMobile ? false : true">
  <ng-container *ngIf="isMobile">
    <div  class="hostingsTable__slider--item"
          [class.active]="i === currentItem"
          *ngFor="let slide of slides(data.rowsName.length); let i = index" >
      <div  class="hostingsTable">
        <div class="hostingsTable__head">
          <div class="hostingsTable__cell" *ngFor="let cell of data.hostings">
            <div class="title">{{cell.name}}</div>
            <div class="subtext" i18n>{{cell.plan}}</div>
          </div>
        </div>
        <div class="hostingsTable__body">

          <div class="hostingsTable__row">
            <div class="hostingsTable__col">
              <div class="hostingsTable__cell color-secondary" i18n>
                {{data.rowsName[i]}}
              </div>
            </div>

            <div class="hostingsTable__row">
              <ng-container *ngFor="let cell of data.hostings">
                <div class="hostingsTable__col">

                  <ng-container *ngFor="let property of cell | keys; let j = index">

                    <div  class="hostingsTable__cell color-secondary"
                          *ngIf="j !== (cell | keys).length - 1 && j === i + 2">
                      <ng-container *ngIf="property === 'price'; else other">
                        {{ symbol }}{{cell[property] | currencyFormat}} {{ getTaxSymbol() }}
                      </ng-container>

                      <ng-template #other i18n>
                        {{cell[property]}}
                      </ng-template>
                    </div>

                    <!-- ratings cell -->
                    <div  class="hostingsTable__cell color-secondary"
                          *ngIf="j == (cell | keys).length - 1 && j == i + 2">
                      <div class="rating flex">
                        <span class="icon"
                              [class.outline]="k + 1 > cell.rating"
                              *ngFor="let star of stars(maxRating); let k = index">
                          <svg-icon-sprite
                            [src]="'/assets/images/sprites/sprite.svg#icon-star-rating'"
                            [width]="'16px'"
                            [classes]="'icon-star-rating'"
                            *ngIf="loadImage">
                          </svg-icon-sprite>
                        </span>
                      </div>
                    </div>
                    <!--  end -->

                  </ng-container>
                </div>
              </ng-container>
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="hostingsTable__nav" *ngIf="isMobile">
  <button mat-icon-button
          [class.active]="currentItem > 0"
          [disabled]="currentItem == 0"
          (click)="moveSlides(1)">
  </button>
  <button mat-icon-button
          [class.active]="currentItem < data.rowsName.length - 1"
          [disabled]="currentItem == data.rowsName.length - 1"
          (click)="moveSlides(2)">
  </button>
</div>
<!-- END -->
