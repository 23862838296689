// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export class URL {
  // DEVELOP.
  public static readonly LIVEHOST_WEBSITE_BASE = 'https://website-livehost-LANG_LOWER.haulmer.dev/';
  public static readonly LIVEHOST_FACEBOOK = 'https://www.facebook.com/LiveHostLAT';
  public static readonly LIVEHOST_TWITTER = 'https://twitter.com/LiveHostLAT';
  public static readonly LIVEHOST_YOUTUBE = 'https://www.youtube.com/channel/UC45rdxGiG11qOVCwrWKMXfQ';
  public static readonly LIVEHOST_STATUS = 'https://status.livehost.host';
  public static readonly LIVEHOST_DOC = 'https://docs.livehost.LANG_LOWER';
  public static readonly LIVEHOST_DOC_BASE = 'http://panel.livehost.host/knowledgebase.php';
  public static readonly LIVEHOST_BLOG = 'https://www.livehost.LANG_LOWER/blog/';
  public static readonly LIVEHOST_GET_DOMAIN = 'https://api.haulmer.dev/v1/livehost/getdomain.php';
  public static readonly FIND_INVOICE = "https://whmcs-base.haulmer.dev/includes/api/findInvoices.php";
  public static readonly LIVEHOST_TERMS_CONDITIONS = "https://www.haulmer.com/legal/tag/web-hosting/";
}
