import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { ScrollToClass } from './../../../../common/scrollTo.class';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.sass']
})

export class HomeComponent extends ScrollToClass implements OnInit {

    public URL = URL;
    public contactsUrl: string;
    public tld = this.localeService.currentContact.country_code;
    public symbol = this.localeService.symbol;

    public isTablet: boolean;
    public isMobile: boolean;

    // load images
    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    dataCards;

    options = [
        {
            title: 'Web Hosting',
            text: 'Conoce nuestros planes de hosting económicos',
            icon: 'icon-folder',
            color: 'color-accent-yellow'
        }, {
            title: 'Web Hosting Pro',
            text: 'Hosting para sitios con alto flujo de visitantes.',
            icon: 'icon-grid',
            color: 'color-accent-green'
        }, {
            title: 'VPS',
            text: 'Obtén tu VPS SSD al mejor precio para tu proyecto.',
            icon: 'icon-dns',
            color: 'color-accent-violet'
        }, {
            title: 'Reseller',
            text: 'Conviértete en un Hosting Reseller con LiveHost.',
            icon: 'icon-briefcase',
            color: 'color-accent-blue'
        }, {
            title: 'Dominios',
            text: 'Adquiere tu dominio y asegura el nombre de tu sitio web.',
            icon: 'icon-book',
            color: 'color-accent-rose'
        }
    ];

    options_ar = [
        {
            title: 'Web Hosting',
            text: 'Conocé nuestros planes de hosting económicos',
            icon: 'icon-folder',
            color: 'color-accent-yellow'
        }, {
            title: 'Web Hosting Pro',
            text: 'Hosting para sitios con alto flujo de visitantes.',
            icon: 'icon-grid',
            color: 'color-accent-green'
        }, {
            title: 'VPS',
            text: 'Obten tu VPS SSD al mejor precio para tu proyecto.',
            icon: 'icon-dns',
            color: 'color-accent-violet'
        }, {
            title: 'Reseller',
            text: 'Convertite en un Hosting Reseller con LiveHost.',
            icon: 'icon-briefcase',
            color: 'color-accent-blue'
        }, {
            title: 'Dominios',
            text: 'Adquiere tu dominio y asegurá el nombre de tu sitio web.',
            icon: 'icon-book',
            color: 'color-accent-rose'
        }
    ];

    options_co = [
        {
            title: 'Web Hosting',
            text: 'Conozca nuestros planes de hosting económicos',
            icon: 'icon-folder',
            color: 'color-accent-yellow'
        }, {
            title: 'Web Hosting Pro',
            text: 'Hosting para sitios con alto flujo de visitantes.',
            icon: 'icon-grid',
            color: 'color-accent-green'
        }, {
            title: 'VPS',
            text: 'Obtenga su VPS SSD al mejor precio para su proyecto.',
            icon: 'icon-dns',
            color: 'color-accent-violet'
        }, {
            title: 'Reseller',
            text: 'Conviértase en un Hosting Reseller con LiveHost.',
            icon: 'icon-briefcase',
            color: 'color-accent-blue'
        }, {
            title: 'Dominios',
            text: 'Adquiera su dominio y asegure el nombre de su sitio web.',
            icon: 'icon-book',
            color: 'color-accent-rose'
        }
    ];

    planHosting = [
        {
            title: 'Velocidad para tu sitio web',
            text: 'Contamos con servidores de última generación para entregar siempre la máxima velocidad a tu sitio web.',
            icon: 'icon-marketing',
            color: 'color-accent-orange'
        }, {
            title: 'Un Hosting cercano',
            text: 'LiveHost fue creado para que cualquiera pueda administrar su sitio web sin ser un experto.',
            icon: 'icon-seo',
            color: 'color-accent-red'
        }, {
            title: '¡Comienza tu sitio ya!',
            text: 'Crea y publica tu sitio web en minutos con nuestro creador de sitios fácil e intuitivo.',
            icon: 'icon-social',
            color: 'color-accent-green-bright'
        }
    ];

    planHosting_ar = [
        {
            title: 'Velocidad para tu sitio web',
            text: 'Contamos con servidores de última generación para entregar siempre la máxima velocidad a tu sitio web.',
            icon: 'icon-marketing',
            color: 'color-accent-orange'
        }, {
            title: 'Un Hosting cercano',
            text: 'LiveHost fue creado para que cualquiera pueda administrar su sitio web sin ser un experto.',
            icon: 'icon-seo',
            color: 'color-accent-red'
        }, {
            title: '¡Comienza tu sitio ya!',
            text: 'Creá y publicá tu sitio web en minutos con nuestro creador de sitios fácil e intuitivo.',
            icon: 'icon-social',
            color: 'color-accent-green-bright'
        }
    ];

    planHosting_co = [
        {
            title: 'Velocidad para su sitio web',
            text: 'Contamos con servidores de última generación para entregar siempre la máxima velocidad a su sitio web.',
            icon: 'icon-marketing',
            color: 'color-accent-orange'
        }, {
            title: 'Un Hosting cercano',
            text: 'LiveHost fue creado para que cualquiera pueda administrar su sitio web sin ser un experto.',
            icon: 'icon-seo',
            color: 'color-accent-red'
        }, {
            title: '¡Comience su sitio ya!',
            text: 'Cree y publique su sitio web en minutos con nuestro creador de sitios fácil e intuitivo.',
            icon: 'icon-social',
            color: 'color-accent-green-bright'
        }
    ];

    advantages = [
        {
            title: 'Soporte Profesional',
            text: '¡Estamos para ayudarte! Llueva o tiemble, podrás contactarte con nuestro equipo de Soporte de Lunes a Viernes.',
            icon: 'icon-phone-call',
            color: 'color-accent-yellow'
        }, {
            title: '99.9% Uptime',
            text: 'Nuestra prioridad es que tu sitio siempre esté disponible. Por eso aseguramos tu tiempo en línea.',
            icon: 'icon-bar-chart',
            color: 'color-accent-green'
        }, {
            title: '30 días de garantía',
            text: '¡Nuestro servicio sin riesgos! Si no estás conforme puedes cancelar tu plan dentro de los primeros 30 días. ',
            icon: 'icon-award',
            color: 'color-accent-violet'
        }
    ];

    advantages_ar = [
        {
            title: 'Soporte Profesional',
            text: '¡Estamos para ayudarte! Llueva o tiemble, podrás contactarte con nuestro equipo de Soporte de Lunes a Viernes.',
            icon: 'icon-phone-call',
            color: 'color-accent-yellow'
        }, {
            title: '99.9% Uptime',
            text: 'Nuestra prioridad es que tu sitio siempre esté disponible. Por eso aseguramos tu tiempo en línea.',
            icon: 'icon-bar-chart',
            color: 'color-accent-green'
        }, {
            title: '30 días de garantía',
            text: '¡Nuestro servicio sin riesgos! Si no estás conforme podés cancelar tu plan dentro de los primeros 30 días. ',
            icon: 'icon-award',
            color: 'color-accent-violet'
        }
    ];

    advantages_co = [
        {
            title: 'Soporte Profesional',
            text: '¡Estamos para ayudarle! Llueva o tiemble, podrás contactarse con nuestro equipo de Soporte de Lunes a Viernes.',
            icon: 'icon-phone-call',
            color: 'color-accent-yellow'
        }, {
            title: '99.9% Uptime',
            text: 'Nuestra prioridad es que su sitio siempre esté disponible. Por eso aseguramos su tiempo en línea.',
            icon: 'icon-bar-chart',
            color: 'color-accent-green'
        }, {
            title: '30 días de garantía',
            text: '¡Nuestro servicio sin riesgos! Si no está conforme puede cancelar su plan dentro de los primeros 30 días. ',
            icon: 'icon-award',
            color: 'color-accent-violet'
        }
    ];

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isTablet = window.innerWidth <= 992 ? true : false;
        this.isMobile = window.innerWidth <= 670 ? true : false;
    }

    constructor(
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private el: ElementRef
    ) {
        super(el);
        this.dataCards = this.localeService.getContent().plans;
    }

    ngOnInit() {
        this.contactsUrl = this.URL.LIVEHOST_WEBSITE_BASE + this.tld + '/contactar-con-ventas';
        this.isTablet = window.innerWidth <= 992 ? true : false;
        this.isMobile = window.innerWidth <= 670 ? true : false;

        if (this.tld === 'ar') {
            this._setMeta_AR();
            this.options = this.options_ar;
            this.planHosting = this.planHosting_ar;
            this.advantages = this.advantages_ar;
        } else if (this.tld === 'co') {
            this._setMeta_CO();
            this.options = this.options_co;
            this.planHosting = this.planHosting_co;
            this.advantages = this.advantages_co;
        } else if (this.tld === 'pe') {
            this._setMeta_PE();
        } else if (this.tld === 'mx') {
            this._setMeta_MX();
        } else {
            this._setMeta_CL();
        }
    }

    public navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }

    private _setMeta_CL() {
        this._meta.setTitle('LIVEHOST | Web Hosting Profesional y Rápido en Chile');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'LiveHost es un hosting profesional de alta velocidad, alto rendimiento y capacidad de adaptación a tus necesidades. Con DataCenter propio en Chile');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting chile, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting Profesional y Rápido en Chile - LiveHost');
        this._meta.setTag('og:locale', 'es_CL');
    }

    private _setMeta_PE() {
        this._meta.setTitle('LIVEHOST | Web Hosting Profesional y Rápido en Perú');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'LiveHost es un hosting profesional de alta velocidad, alto rendimiento y capacidad de adaptación a tus necesidades. Con DataCenter propio en Perú');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting peru, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting Profesional y Rápido en Perú - LiveHost');
        this._meta.setTag('og:locale', 'es_PE');
    }

    private _setMeta_CO() {
        this._meta.setTitle('LIVEHOST | Web Hosting Profesional y Rápido en Colombia');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'LiveHost es un hosting profesional de alta velocidad, alto rendimiento y capacidad de adaptación a sus necesidades. Con DataCenter propio en Colombia');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting colombia, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting Profesional y Rápido en Colombia - LiveHost');
        this._meta.setTag('og:locale', 'es_CO');
    }

    private _setMeta_AR() {
        this._meta.setTitle('LIVEHOST | Web Hosting Profesional y Rápido en Argentina');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'LiveHost es un hosting profesional de alta velocidad, alto rendimiento y capacidad de adaptación a tus necesidades. Con DataCenter propio en Argentina');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting argentina web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting Profesional y Rápido en Argentina - LiveHost');
        this._meta.setTag('og:locale', 'es_AR');
    }

    private _setMeta_MX() {
        this._meta.setTitle('LIVEHOST | Web Hosting Profesional y Rápido en México');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'LiveHost es un hosting profesional de alta velocidad, alto rendimiento y capacidad de adaptación a tus necesidades. Con DataCenter propio en México');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting méxico, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting Profesional y Rápido en México - LiveHost');
        this._meta.setTag('og:locale', 'es_MX');
    }

    public getTaxSymbol(): string {
        if (this.tld === 'pe') { return '+ IGV'; }
        if (this.tld === 'mx' || this.tld === 'co' || this.tld === 'ar') { return ''; }
        return '+ IVA';
    }
}
