import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { WebHostingFullComponent } from './web-hosting-full/web-hosting-full.component';
import { ResellerComponent } from './reseller/reseller.component';
import { VpsComponent } from './vps/vps.component';
import { DomainsComponent } from './domains/domains.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ErrorComponent } from './error/error.component';
import { SuccessComponent } from './success/success.component';
import { SuccessGuard } from './success/success.guard';
import { DirectPaymentComponent } from './direct-payment/direct-payment.component';
import { VpsBackupComponent } from './vps-backup/vps-backup.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    }, {
        path: 'web-hosting/.',
        component: WebHostingComponent
    }, {
        path: 'web-hosting',
        redirectTo: 'web-hosting/.',
        pathMatch: 'full'
    },  {
        path: 'success',
        component: SuccessComponent,
        canActivate: [SuccessGuard],
    },{
        path: 'web-hosting-full/.',
        component: WebHostingFullComponent
    }, {
        path: 'web-hosting-full',
        redirectTo: 'web-hosting-full/.',
        pathMatch: 'full'
    }, {
        path: 'reseller/.',
        component: ResellerComponent
    }, {
        path: 'reseller',
        redirectTo: 'reseller/.',
        pathMatch: 'full'
    }, {
        path: 'ssd-vps-cloudserver/.',
        component: VpsComponent
    }, {
        path: 'ssd-vps-cloudserver',
        redirectTo: 'ssd-vps-cloudserver/.',
        pathMatch: 'full'
    }, {
        path: 'cloud-vps-backup/.',
        component: VpsBackupComponent
    }, {
        path: 'cloud-vps-backup',
        redirectTo: 'cloud-vps-backup/.',
        pathMatch: 'full'
    }, {
        path: 'registro-dominio/.',
        component: DomainsComponent
    }, {
        path: 'registro-dominio',
        redirectTo: 'registro-dominio/.',
        pathMatch: 'full'
    }, {
        path: 'formasdepago/.',
        component: PaymentMethodsComponent
    }, {
        path: 'formasdepago',
        redirectTo: 'formasdepago/.',
        pathMatch: 'full'
    }, {
        path: 'contactar-con-ventas/.',
        component: ContactsComponent
    }, {
        path: 'contactar-con-ventas',
        redirectTo: 'contactar-con-ventas/.',
        pathMatch: 'full'
    },
    {
        path: 'pago-directo/.',
        component: DirectPaymentComponent
    },
    {
        path: 'pago-directo',
        redirectTo: 'pago-directo/.',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '404/.'
    }, {
        path: '404/.',
        component: ErrorComponent
    }, {
        path: '404',
        redirectTo: '404/.',
        pathMatch: 'full'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
