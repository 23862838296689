import { Component, OnInit, HostListener, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { LocaleService } from '../../services/locale.service';

@Component({
    selector: 'app-hostings-table',
    templateUrl: './hostings-table.component.html',
    styleUrls: ['./hostings-table.component.sass']
})

export class HostingsTableComponent implements OnInit, AfterViewInit {
    @ViewChild('slider', { static: true }) public slider: ElementRef;
    @Input() loadImage: boolean;

    public stars = Array;
    public slides = Array;
    public maxRating = 5;

    public isMobile = false;
    public currentItem = 0;

    sliderProps = {
        slider: undefined,
        padding: undefined
    };

    tld = this.localeService.currentContact.country_code;
    symbol = this.localeService.symbol;

    public data;
    data_def = {
        rowsName: [
            'Valor anual',
            'Dominios',
            'Espacio total',
            'Cuentas de correo',
            'Correos por hora',
            'Comparativa General'
        ],
        hostings: [
            {
                name: this.tld === 'cl' ? 'livehost.cl' : 'livehost.pe',
                plan: 'Estándar',
                price: this.tld === 'cl' ? 18900 : 95,
                count: 1,
                total: 'Ilimitado',
                mailAccounts: 'Ilimitadas',
                amountOfLetters: 3000,
                rating: 5
            }, {
                name: this.tld === 'cl' ? 'hostin.cl' : 'hostingperu.com.pe',
                plan: 'Emprendedor',
                price: this.tld === 'cl' ? 99000 : 169,
                count: 1,
                total: this.tld === 'cl' ? '3 GB' : '500 MB',
                mailAccounts: this.tld === 'cl' ? 'ilimitadas' : '5',
                amountOfLetters: this.tld === 'cl' ? 500 : 100,
                rating: 3
            }, {
                name: this.tld === 'cl' ? 'hostname.cl' : 'hn.pe',
                plan: 'Blogger',
                price: this.tld === 'cl' ? 33900 : 120,
                count: 1,
                total: '7 GB',
                mailAccounts: 10,
                amountOfLetters: 100,
                rating: 3
            }
        ]
    };

    data_mx = {
        rowsName: [
            'Valor anual',
            'Dominios',
            'Espacio total',
            'Cuentas de correo',
            'Correos por hora',
            'Comparativa General'
        ],
        hostings: [
            {
                name: 'livehost.cl',
                plan: 'Estándar',
                price: 550,
                count: 1,
                total: 'Ilimitado',
                mailAccounts: 'Ilimitadas',
                amountOfLetters: 3000,
                rating: 5
            }, {
                name: 'Technoweb.net',
                plan: 'Emprendedor',
                price: 720,
                count: 1,
                total: '2GB',
                mailAccounts: 10,
                amountOfLetters: null,
                rating: 3
            }, {
                name: 'Aldeahost.com.mx',
                plan: 'Blogger',
                price: 620,
                count: 1,
                total: '10 GB',
                mailAccounts: 20,
                amountOfLetters: null,
                rating: 4
            }
        ]
    };

    data_co = {
        rowsName: [
            'Valor anual',
            'Dominios',
            'Espacio total',
            'Cuentas de correo',
            'Correos por hora',
            'Comparativa General'
        ],
        hostings: [
            {
                name: 'livehost.com.co',
                plan: 'Estándar',
                price: 90000,
                count: 1,
                total: 'Ilimitado',
                mailAccounts: 'Ilimitadas',
                amountOfLetters: 3000,
                rating: 5
            }, {
                name: 'planetahosting.com.co',
                plan: 'Emprendedor',
                price: 129000,
                count: 5,
                total: '3GB',
                mailAccounts: 'Ilimitadas',
                amountOfLetters: 150,
                rating: 4
            }, {
                name: 'conexcol.net.co',
                plan: 'Blogger',
                price: 124740,
                count: 'Ilimitado',
                total: '7 GB',
                mailAccounts: 'Ilimitadas',
                amountOfLetters: 50,
                rating: 3
            }
        ]
    };

    data_ar = {
        rowsName: [
            'Valor anual',
            'Dominios',
            'Espacio total',
            'Cuentas de correo',
            'Correos por hora',
            'Comparativa General'
        ],
        hostings: [
            {
                name: 'livehost.ar',
                plan: 'Estándar',
                price: 1160,
                count: 1,
                total: 'Ilimitado',
                mailAccounts: 'Ilimitadas',
                amountOfLetters: 3000,
                rating: 5
            }, {
                name: 'webhostingargentina.net',
                plan: 'Emprendedor',
                price: 1350,
                count: 1,
                total: '4GB',
                mailAccounts: '3',
                amountOfLetters: null,
                rating: 4
            }, {
                name: 'calebix.com',
                plan: 'Blogger',
                price: 2640,
                count: 1,
                total: '1GB',
                mailAccounts: '5',
                amountOfLetters: null,
                rating: 3
            }
        ]
    };

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobile = window.innerWidth <= 767 ? true : false;
    }

    constructor(
        private localeService: LocaleService,
    ) {
    }

    ngOnInit() {
        this.isMobile = window.innerWidth <= 767 ? true : false;
        if (this.tld === 'mx') {
            this.data = this.data_mx;
        } else if (this.tld === 'co') {
            this.data = this.data_co;
        } else if (this.tld === 'ar') {
            this.data = this.data_ar;
        } else {
            this.data = this.data_def;
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.initCustomSlider();
        }, 0);
    }

    initCustomSlider() {
        const props = this.sliderProps;
        props.slider = <HTMLElement>this.slider.nativeElement;
        const slides = props.slider.children;
        props.padding = 10;
        props.slider.style.marginBottom = props.slider.children.length * 8 + 20 + 'px';

        for (let i = 0; i < slides.length; i++) {
            slides[i].style.transform = 'translateY(' + 8 * i + 'px)';
            slides[i].style.paddingLeft = props.padding * i + 'px';
            slides[i].style.paddingRight = props.padding * i + 'px';
            slides[i].style.zIndex = slides.length - i;
        }
    }

    moveSlides(button) {
        if (button === 1 && this.currentItem > 0) {
            this.currentItem--;
        } else if (button === 2 && this.currentItem < this.data.rowsName.length - 1) {
            this.currentItem++;
        }

        const props = this.sliderProps;

        for (let i = 0; i < props.slider.children.length; i++) {
            if (props.slider.children[i].classList.contains('active')) {
                for (let j = 0; j < this.currentItem; j++) {
                    props.slider.children[j].style.transform = 'translateY(' + 8 * (j + 1) + 'px)';
                    props.slider.children[j].style.paddingLeft = (props.padding * (j + 1)) + 'px';
                    props.slider.children[j].style.paddingRight = (props.padding * (j + 1)) + 'px';
                }
            } else if (i > this.currentItem) {
                for (let j = this.currentItem; j < props.slider.children.length; j++) {
                    props.slider.children[j].style.transform = 'translateY(' + 8 * j + 'px)';
                    props.slider.children[j].style.paddingLeft = props.padding * j + 'px';
                    props.slider.children[j].style.paddingRight = props.padding * j + 'px';
                }
            }

        }
    }

    public getTaxSymbol(): string {
        if (this.tld === 'pe') { return '+ IGV'; }
        if (this.tld === 'mx' || this.tld === 'co' || this.tld === 'ar') { return ''; }
        return '+ IVA';
    }
}
