import { Component, OnInit } from '@angular/core';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { Contact } from '../contact';
import { CONTACTS } from '../contacts.constants';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.sass']
})

export class ErrorComponent implements OnInit {
    public URL = URL;

    public tld = this.localeService.currentContact.country_code;
    public contact: Contact;
    public contactList;

    constructor(
        private localeService: LocaleService
    ) {
        this.contactList = CONTACTS;
        this.contact = this.contactList.filter((c) => c.country_code === this.tld)[0];
    }

    public ngOnInit() {

    }
}
