import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {

    transform(value: {}): string[] {

        if (!value) {
            return [];
        }

        return Object.keys(value);
    }
}
