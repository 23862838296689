<section class="firstScreen has-wave bottom">
  <div class="firstScreen__img">
    <picture>
      <source srcset="/assets/images/reseller/reseller-full-firstscreen@2x.webp 2x"
                      type="image/webp" media="(min-width: 1280px)">
      <source srcset="/assets/images/reseller/reseller-full-firstscreen@1x.webp,
                      /assets/images/reseller/reseller-full-firstscreen@2x.webp 2x"
                      type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/reseller/reseller-full-first-screen.jpg" media="(min-width: 671px)">
      <source srcset="/assets/images/reseller/reseller-fullfirstscreen-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/reseller/reseller-fullfirstscreen-mobile@1x.jpg"
            srcset="/assets/images/reseller/reseller-fullfirstscreen-mobile@2x.jpg 2x" alt="people">
    </picture>
  </div>
  <div class="wave wave-1">
    <img  src="/assets/images/shared/wave@1x.png"
          srcset="/assets/images/shared/wave@2x.png 2x" alt="wave">
  </div>
  <picture>
    <source srcset="/assets/images/reseller/firstscreen-reseller-decorations@1x.png,
                    /assets/images/reseller/firstscreen-reseller-decorations@2x.png 2x" media="(min-width: 992px)">
    <img  src="/assets/images/shared/firstscreen-decorations-tablet@1x.png"
          srcset="/assets/images/shared/firstscreen-decorations-tablet@2x.png 2x" alt="decorations" class="decorations">
  </picture>

  <div class="container">
    <div class="firstScreen__info">
      <div class="suptitle upper color-accent-green" i18n>RESELLER</div>
      <h1 class="title-1" i18n>Gana dinero como <br> Hosting Reseller</h1>
      <div class="subheading" i18n>Ten tu propio negocio de Hosting de la mano de LiveHost.</div>

      <div class="wrapButton">
        <button id="l-whr-plans" class="btn btnAccent rounded" mat-button (click)="navigateToSection('plans-ts')" i18n>Ver Planes</button>
        <button id="l-whr-contactanos" class="btn btnTransp rounded" mat-button [routerLink]="['/contactar-con-ventas/']" i18n>Contáctanos</button>
      </div>
    </div>
  </div>
</section>

<section class="has-wave bottom plans-ts" data-offset="40">
  <div class="wave wave-2">
    <img  src="/assets/images/shared/wave2@1x.png"
          srcset="/assets/images/shared/wave2@2x.png 2x" alt="wave">
  </div>

  <!-- START app-cards-->
  <app-cards
      [data]="dataCards.reseller.plans"
      [suptitle]="suptitle"
      [title2]="title2"
      [preRender]="false" (deferLoad)="showImage1 = true">
  </app-cards>
  <!-- END -->

  <!-- START sectionSales-->
  <app-section-sales [data]="sales" [preRender]="false" (deferLoad)="showImage2 = true" [loadImage]="showImage1"></app-section-sales>
  <!-- END -->

  <div class="suptitle upper color-accent-green center" i18n>Al contratar cualquier plan obtén:</div>

  <div class="checkList__wrapper">
    <ul class="checkList" *ngFor="let ul of lists">
      <li *ngFor="let point of ul.list">
        <div class="icon color-accent-green">
          <svg-icon-sprite
            [src]="'/assets/images/sprites/sprite.svg#icon-check'"
            [width]="'16px'"
            [classes]="'icon-check'"
            *ngIf="showImage2">
          </svg-icon-sprite>
        </div>
        <span class="body-1 color-secondary" i18n>
          {{point}}
        </span>
      </li>
    </ul>
  </div>
</section>

<!-- START sectionColumns--reseller -->
<section class="sectionColumns sectionColumns--reseller" [preRender]="false" (deferLoad)="showImage3 = true">
  <div class="sectionColumns__col rel">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>¿Qué es un hosting reseller?</div>
      <h2 class="title-2 color-white" i18n>Haz mucho dinero con tu propio negocio de hosting</h2>
      <div class="body-1 color-white" i18n>Es una solución que te permite crear varias cuentas personalizadas en un único plan, para así vender cada una de ellas a tus propios clientes. Podrás iniciar tu negocio de Web Hosting mientras LiveHost hace el trabajo duro.</div>
    </div>
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/shared/bg-circle-blue@1x.webp,
                      /assets/images/shared/bg-circle-blue@2x.webp 2x"
              type="image/webp" media="(min-width: 480px)">
      <source srcset="/assets/images/shared/bg-circle-blue@1x.png,
                      /assets/images/shared/bg-circle-blue@2x.png 2x" media="(min-width: 480px)">
      <source srcset="/assets/images/shared/bg-circle-blue-mobile@1x.webp,
                      /assets/images/shared/bg-circle-blue-mobile@2x.webp 2x" type="image/webp">
      <img src="/assets/images/shared/bg-circle-blue-mobile@1x.png"
        srcset="/assets/images/shared/bg-circle-blue-mobile@2x.png 2x" alt="circle">
    </picture>
  </div>

  <div class="sectionColumns__col">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/reseller/section-reseller-desktop@2x.webp 2x"
                      type="image/webp" media="(min-width: 993px)">
      <source srcset="/assets/images/reseller/section-reseller-desktop@1x.png,
                      /assets/images/reseller/section-reseller-desktop@2x.png 2x"
                      media="(min-width: 993px)">
      <source srcset="/assets/images/reseller/section-reseller-tablet@2x.webp 2x"
                      type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/reseller/section-reseller-tablet@1x.png,
                      /assets/images/reseller/section-reseller-tablet@2x.png 2x"
                      media="(min-width: 671px)">
      <source srcset="/assets/images/reseller/section-reseller-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/reseller/section-reseller-mobile@1x.png"
            srcset="/assets/images/reseller/section-reseller-mobile@2x.png 2x" alt="reseller">
    </picture>
  </div>
  <picture *ngIf="showImage2">
    <source srcset="/assets/images/reseller/reseller-decorations-desktop@1x.png,
                    /assets/images/reseller/reseller-decorations-desktop@2x.png 2x"
                    media="(min-width: 993px)">
    <source srcset="/assets/images/reseller/reseller-decorations-tablet@1x.png,
                    /assets/images/reseller/reseller-decorations-tablet@2x.png 2x"
                    media="(min-width: 671px)">
    <img  src="/assets/images/reseller/reseller-decorations-mobile@1x.png"
          srcset="/assets/images/reseller/reseller-decorations-mobile@2x.png 2x" alt="decorations" class="decorations">
  </picture>
</section>
<!-- END -->

<!-- START sectionManager -->
<section class="sectionManager has-wave top bottom">
  <div class="wave wave-3" *ngIf="showImage2">
    <img  src="/assets/images/shared/wave3@1x.png" srcset="/assets/images/shared/wave3@2x.png 2x" alt="wave">
  </div>

  <div class="wave wave-1" *ngIf="showImage2">
    <img src="/assets/images/shared/wave@1x.png" srcset="/assets/images/shared/wave@2x.png 2x" alt="wave">
  </div>

  <img  src="/assets/images/reseller/section-manager-decorations@1x.png"
        srcset="/assets/images/reseller/section-manager-decorations@2x.png 2x" alt="decorations" class="decorations" *ngIf="showImage2">
  <div class="wrapper">
    <div class="suptitle upper color-accent-green center" i18n>web hosting manager</div>
    <h2 class="title-2 center" i18n>Panel de control WHM</h2>
    <div class="checkList__wrapper">
      <ul class="checkList" *ngFor="let ul of listsWHM">
        <li *ngFor="let point of ul.list">
          <div class="icon color-accent-green">
            <svg-icon-sprite
              [src]="'/assets/images/sprites/sprite.svg#icon-check'"
              [width]="'16px'"
              [classes]="'icon-check'"
              *ngIf="showImage2">
            </svg-icon-sprite>
          </div>
          <span class="body-1 color-secondary" i18n>
            {{point}}
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div class="image">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/reseller/manager-desktop@2x.avif 2x"
                      type="image/avif" media="(min-width: 670px)">
      <source srcset="/assets/images/reseller/manager-desktop@2x.webp 2x"
                      type="image/webp" media="(min-width: 670px)">
      <source srcset="/assets/images/reseller/manager-desktop@1x.png,
                      /assets/images/reseller/manager-desktop@2x.png 2x"
                      media="(min-width: 670px)">
      <source srcset="/assets/images/reseller/manager-mobile@2x.webp 2x"
                      type="image/webp">
      <img  src="/assets/images/reseller/manager-mobile@1x.png"
            srcset="/assets/images/reseller/manager-mobile@2x.png 2x" alt="manager">
    </picture>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--software -->
<section class="sectionColumns sectionColumns--software">
  <div class="sectionColumns__col">
    <picture *ngIf="showImage2">
      <source srcset="/assets/images/reseller/reseller-softaculous-desktop@2x.avif 2x"
              type="image/avif" media="(min-width: 670px)">
      <source srcset="/assets/images/reseller/reseller-softaculous-desktop@2x.webp 2x"
              type="image/webp" media="(min-width: 670px)">
      <source srcset="/assets/images/reseller/reseller-softaculous-desktop@1x.png,
                      /assets/images/reseller/reseller-softaculous-desktop@2x.png 2x" media="(min-width: 670px)">
      <source srcset="/assets/images/reseller/reseller-softaculous-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/reseller/reseller-softaculous-mobile@1x.png"
            srcset="/assets/images/reseller/reseller-softaculous-mobile@2x.png 2x" alt="Softaculous">
    </picture>
  </div>
  <div class="sectionColumns__col">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>Softaculous</div>
      <h2 class="title-2 color-white" i18n>Instalación de Apps en un clic con Softaculous</h2>
      <div class="body-1 color-white" i18n>Ofrécele a tus clientes un plan de hosting con Softaculous, así podrán generar respaldos e instalar en segundos y con un solo clic las aplicaciones que deseen en su sitio web.</div>
    </div>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--publisher -->
<section class="sectionColumns sectionColumns--publisher has-wave top">
  <div class="sectionColumns__col">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>Site publisher</div>
      <h2 class="title-2" i18n>Un creador de sitios para todos los gustos</h2>
      <div class="body-1 color-secondary" i18n>Con el creador tus clientes podrán diseñar y construir su sitio web. Al ser una experiencia sencilla e intuitiva no necesitarán de tu orientación.</div>
    </div>
  </div>

  <div class="sectionColumns__col">
    <picture *ngIf="showImage3">
      <source srcset="/assets/images/web-hosting/site-publisher-desktop@2x.webp 2x"
              type="image/webp" media="(min-width: 670px)">
      <source srcset="/assets/images/web-hosting/site-publisher-desktop@1x.png,
                      /assets/images/web-hosting/site-publisher-desktop@2x.png 2x" media="(min-width: 670px)">
      <source srcset="/assets/images/web-hosting/site-publisher-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/web-hosting/site-publisher-mobile@1x.png"
            srcset="/assets/images/web-hosting/site-publisher-mobile@2x.png 2x" alt="Site publisher">
    </picture>
  </div>
  <div class="wave wave-5" *ngIf="showImage3">
    <picture>
      <source srcset="/assets/images/shared/wave5@1x.png,
                      /assets/images/shared/wave5@2x.png 2x" media="(min-width: 671px)">
      <img  src="/assets/images/shared/wave3@1x.png"
            srcset="/assets/images/shared/wave3@2x.png 2x" alt="wave">
    </picture>
  </div>
</section>
<!-- END -->

<!-- START wrapResources -->
<section class="wrapResources">
  <img  src="/assets/images/reseller/section-recursos-decorations@1x.png"
        srcset="/assets/images/reseller/section-recursos-decorations@2x.png 2x" alt="decorations" class="decorations" *ngIf="showImage3">

  <div class="wrapImg">
    <picture *ngIf="showImage3">
      <source srcset="/assets/images/shared/comma-left@1x.webp,
                      /assets/images/shared/comma-left@2x.webp 2x"
                      type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/shared/comma-left@1x.png,
                      /assets/images/shared/comma-left@2x.png 2x" media="(min-width: 671px)">
      <source srcset="/assets/images/shared/comma-left-mobile@1x.webp,
                      /assets/images/shared/comma-left-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/shared/comma-left-mobile@1x.png"
            srcset="/assets/images/shared/comma-left-mobile@2x.png 2x" alt="comma">
    </picture>
  </div>
  <div class="container">
    <div class="suptitle upper center color-accent-green" i18n>Recursos</div>
    <h2 class="title-2 center" i18n>Recursos de los planes de Reseller Hosting</h2>

    <app-options [options]="options" [class]="'recursos'" [loadImage]="showImage3"></app-options>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--support -->
<app-section-support [data]="dataSupport" [class]="'reseller'" [loadImage]="showImage3"></app-section-support>
<!-- END -->

<!-- START payment-logos -->
<app-payment-logos
  [class]="'web-hosting-full'"
  [title]="'¡Conviértete en un Reseller ahora!'"
  [buttonId]="'l-r-plans'"
  [subtext]="'Desde ' + symbol + (dataCards.reseller.plans[0].price | currencyFormat ) + ' MENSUAL'"
  [loadImage]="showImage3">
</app-payment-logos>
<!-- END -->

<app-footer [loadImage]="showImage3"></app-footer>
