<section class="firstScreen has-wave bottom">
  <div class="wave wave-5">
    <img  src="/assets/images/shared/wave5@1x.png"
          srcset="/assets/images/shared/wave5@2x.png 2x" alt="wave">
  </div>

  <img  src="/assets/images/payment-methods/firstscreen-payment-decorations@1x.png"
        srcset="/assets/images/payment-methods/firstscreen-payment-decorations@2x.png 2x" alt="decorations" class="decorations">

  <div class="container">
    <div class="firstScreen__info">
      <div class="suptitle upper color-accent-green center" i18n>Contacto</div>
      <h1 class="title-1 center" i18n>Contactar con ventas</h1>
      <div class="subheading center w-100" i18n>Por favor llena el formulario y un ejecutivo te contactará.</div>
    </div>
  </div>

</section>

<!-- START sectionContacts -->
<section class="sectionContacts has-wave bottom">
  <div class="wave wave-6">
    <picture>
      <source srcset="/assets/images/shared/wave6@1x.png,
                      /assets/images/shared/wave6@2x.png 2x" media="(min-width: 992px)">
      <img  src="/assets/images/shared/wave6-tablet@1x.png"
            srcset="/assets/images/shared/wave6-tablet@2x.png 2x" alt="wave">
    </picture>
  </div>

  <!-- START contactsForm -->
  <form class="contactsForm" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="title-2" i18n>Te llamaremos</div>

    <!-- Nombre field -->
    <mat-form-field>
      <mat-label i18n>Nombre</mat-label>
      <input matInput formControlName="name" required>
      <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')" i18n>Su nombre completo es requerido.</mat-error>
      <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('minlength')" i18n>Su nombre debe contener al menos tres caracteres.</mat-error>
      <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('maxlength')" i18n>Su nombre debe contener como máximo 100 caracteres.</mat-error>
    </mat-form-field>
    <!-- Nombre End -->

    <!-- Teléfono field -->
    <mat-form-field class="fieldPhone">
      <mat-label i18n>Teléfono*</mat-label>
      <span class="color-secondary">{{contact.phone_code}}</span>
      <input  matInput
              type="text"
              formControlName="phone"
              [ngClass]="{ 'is-invalid': form.get('phone').errors }"
              (blur)="onPhoneBlur($event)"
              (focus)="onPhoneFocus($event)">
      <mat-error *ngIf="form.get('phone').touched && form.get('phone').hasError('pattern')" i18n>Su teléfono no tiene formato correcto.</mat-error>

      <span matPrefix>
        <div class="fieldPhone__select {{contact.country_code.replace('.','')}}">
          <mat-icon>keyboard_arrow_down</mat-icon>
          <mat-select panelClass="countriesPanel"
                      tabindex="-1"
                      [(value)]="contact"
                      (selectionChange)="changeValidation($event.value)">
            <mat-option *ngFor="let contact of contactList" [value]="contact">
              <span class="flag">
                <img src="{{contact.flag_url}}" alt="{{contact.country}}">
              </span>
              <span class="city" i18n>{{contact.city}}</span><span class="country" i18n>, {{contact.country}}</span>
            </mat-option>
          </mat-select>
        </div>
      </span>
    </mat-form-field>
    <!-- Teléfono End -->

    <!-- E-mail field -->
    <mat-form-field>
      <mat-label>E-mail</mat-label>
      <input  matInput
              formControlName="email"
              required
              email
              [ngClass]="{ 'is-invalid': form.get('email').errors }">
      <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('required')" i18n>Su email es requerido.
      </mat-error>
      <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('email')" i18n>Su email no posee el formato correcto.</mat-error>
      <mat-error *ngIf="form.get('email').touched && form.get('name').hasError('maxlength')" i18n>Su nombre debe contener como máximo 200 caracteres.</mat-error>
    </mat-form-field>
    <!-- E-mail End -->

    <button mat-button
            id = "sb-btn-call"
            class="btn btnAccent rounded"
            [disabled]="form.invalid || disableButton"
            (click)="sendForm()" i18n>
      <mat-spinner *ngIf="isSendForm" [diameter]="24"></mat-spinner>
      {{ btnMsg() }}
    </button>
  </form>
  <!-- contactsForm END -->

  <!-- START contactsCards -->
  <div class="contactsCards" [preRender]="false" (deferLoad)="showImage1 = true">
    <div class="contactsCards__item" *ngFor="let item of contactsArr">
      <span class="icon size-40" [ngClass]="item.color">
        <svg-icon-sprite
          [src]="'/assets/images/sprites/sprite.svg#' + item.icon"
          [width]="'20px'"
          [classes]="item.icon">
        </svg-icon-sprite>
      </span>

      <div class="title-3" i18n>{{item.title}}</div>

      <a href="tel:{{item.tel[tld]}}" class="body-2 color-secondary" *ngIf="item.tel !== undefined">{{item.tel[tld]}}</a>

      <ng-container *ngIf="item.title !== 'Social media'">
        <a href="mailto:{{point.soc}}" class="body-2 color-secondary" *ngFor="let point of item.contacts">{{point.soc}}</a>
      </ng-container>

      <ng-container *ngIf="item.title == 'Social media'">
        <a [href]="URL[point.url]"
            class="body-2 color-secondary"
            target="_blank"
            *ngFor="let point of item.contacts">
          <svg-icon-sprite
            [src]="'/assets/images/sprites/sprite.svg#icon-' + point.soc"
            [width]="point.soc == 'twiter' ? '16px' : (point.soc == 'facebook' ? '16px' : '24px')"
            [classes]="'icon-' + point.soc">
          </svg-icon-sprite>
          <ng-container *ngIf="point.soc !== 'facebook'">@</ng-container><ng-container *ngIf="point.soc == 'facebook'">/</ng-container>livehostlat
        </a>
      </ng-container>

    </div>
  </div>
  <!-- contactsForm END -->
</section>
<!-- END -->

<app-footer [loadImage]="showImage1"></app-footer>
