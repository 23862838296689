import { Component, OnInit, Input } from '@angular/core';
import { LocaleService } from '../../services/locale.service';

@Component({
    selector: 'app-horizontal-cards',
    templateUrl: './horizontal-cards.component.html',
    styleUrls: ['./horizontal-cards.component.sass']
})

export class HorizontalCardsComponent implements OnInit {
    @Input() data;
    @Input() class;
    @Input() loadImage;

    symbol = this.localeService.symbol;

    constructor(
        private localeService: LocaleService
    ) {
    }

    ngOnInit() {
    }

}
