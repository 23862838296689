<section class="firstScreen has-wave bottom">
  <div class="firstScreen__img">
    <picture>
      <source srcset="/assets/images/vps/vps-full-firstscreen@2x.webp 2x"
              type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/vps/vps-full-first-screen.jpg" media="(min-width: 671px)">
      <source srcset="/assets/images/vps/vps-fullfirstscreen-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/vps/vps-fullfirstscreen-mobile@1x.jpg"
            srcset="/assets/images/vps/vps-fullfirstscreen-mobile@2x.jpg 2x" alt="people">
    </picture>
  </div>
  <div class="wave wave-1">
    <img  src="/assets/images/shared/wave@1x.png"
          srcset="/assets/images/shared/wave@2x.png 2x" alt="wave">
  </div>
  <picture>
    <source srcset="/assets/images/reseller/firstscreen-reseller-decorations@1x.png,
                    /assets/images/reseller/firstscreen-reseller-decorations@2x.png 2x" media="(min-width: 992px)">
    <img  src="/assets/images/shared/firstscreen-decorations-tablet@1x.png"
          srcset="/assets/images/shared/firstscreen-decorations-tablet@2x.png 2x" alt="decorations" class="decorations">
  </picture>

  <div class="container">
    <div class="firstScreen__info">
      <div class="suptitle upper color-accent-green" i18n>VPS CLOUDSERVER + BACKUP</div>
      <h1 class="title-1" i18n>VPS SSD para más<br> autonomía y<br> desempeño</h1>
      <div class="subheading" i18n>Obtén acceso a consola y mayor rendimiento con nuestros servidores virtuales</div>

      <div class="wrapButton">
        <button id="l-vps-plans" class="btn btnAccent rounded" mat-button (click)="navigateToSection('plans-ts')" i18n>Ver Planes</button>
        <button id="l-vps-contactanos" class="btn btnTransp rounded" mat-button [routerLink]="['/contactar-con-ventas/']" i18n>Contáctanos</button>
      </div>
    </div>
  </div>
</section>

<!-- START tablePrice-->
<section class="has-wave bottom plans-ts" data-offset="40">
  <div class="wave wave-2">
    <img  src="/assets/images/shared/wave2@1x.png"
          srcset="/assets/images/shared/wave2@2x.png 2x" alt="wave">
  </div>

  <div class="tablePrice__wrapper">
    <div class="suptitle upper color-accent-green center" i18n>planes vps ajustados a tu bolsillo</div>
    <h2 class="title-2 color-white center" i18n>Servidores Virtuales Privados con rendimiento y performance</h2>
    <app-table-price-backup [preRender]="false" (deferLoad)="showImage1 = true"></app-table-price-backup>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--vps -->
<section class="sectionColumns sectionColumns--vps" [preRender]="false" (deferLoad)="showImage2 = true">
  <div class="sectionColumns__col rel">
    <div class="sectionColumns__text">
      <div class="suptitle upper color-accent-green" i18n>¿Qué es un VPS?</div>
      <h2 class="title-2 color-white" i18n>Servidor Virtual Privado</h2>
      <div class="body-1 color-white" i18n>Un VPS te permite hospedar sitios y aplicaciones, creando entornos virtuales privados. A diferencia de los hosting, en un VPS los recursos son dedicados y exclusivos para tu uso.</div>
    </div>
    <img  src="/assets/images/shared/bg-circle-blue@1x.png"
          srcset="/assets/images/shared/bg-circle-blue@2x.png 2x" alt="circle" *ngIf="showImage1">
  </div>

  <div class="sectionColumns__col">
    <picture *ngIf="showImage1">
      <source srcset="/assets/images/web-hosting-full/section-productivity-desktop@2x.webp 2x"
              type="image/webp" media="(min-width: 992px)">
      <source srcset="/assets/images/web-hosting-full/section-productivity-desktop@1x.png,
                      /assets/images/web-hosting-full/section-productivity-desktop@2x.png 2x"
              media="(min-width: 992px)">
      <source srcset="/assets/images/web-hosting-full/section-productivity-tablet@2x.webp 2x"
              type="image/webp" media="(min-width: 670px)">
      <source srcset="/assets/images/web-hosting-full/section-productivity-tablet@1x.png,
                      /assets/images/web-hosting-full/section-productivity-tablet@2x.png 2x"
              media="(min-width: 670px)">
      <source srcset="/assets/images/web-hosting-full/section-productivity-mobile@2x.webp 2x" type="image/webp">
      <img  src="/assets/images/web-hosting-full/section-productivity-mobile@1x.png"
            srcset="/assets/images/web-hosting-full/section-productivity-mobile@2x.png 2x" alt="productivity">
    </picture>
  </div>
  <picture *ngIf="showImage1">
    <source srcset="/assets/images/vps/vps-decorations-desktop@1x.png,
                    /assets/images/vps/vps-decorations-desktop@2x.png 2x" media="(min-width: 670px)">
    <img  src="/assets/images/vps/vps-decorations-mobile@1x.png"
          srcset="/assets/images/vps/vps-decorations-mobile@2x.png 2x" alt="decorations" class="decorations">
  </picture>
</section>
<!-- END -->

<!-- START wrapFeatures -->
<section class="wrapFeatures has-wave top bottom">
  <div class="wave wave-3" *ngIf="showImage2">
    <img  src="/assets/images/shared/wave3@1x.png"
          srcset="/assets/images/shared/wave3@2x.png 2x" alt="wave">
  </div>
  <div class="wave wave-1" *ngIf="showImage2">
    <img  src="/assets/images/shared/wave@1x.png"
          srcset="/assets/images/shared/wave@2x.png 2x" alt="wave">
  </div>
  <div class="container">
    <div class="suptitle upper center color-accent-green" i18n>Características</div>
    <h2 class="title-2 center" i18n>La marca personal de nuestros servicios</h2>

    <app-options [options]="options" [class]="'features'" [loadImage]="showImage2"></app-options>
  </div>
</section>
<!-- END -->

<!-- START sectionPremium-->
<section class="sectionPremium">
  <div class="container">
    <div class="suptitle upper color-accent-green center" i18n>Alojamiento premium</div>
    <h2 class="title-2 color-white center" i18n>Complementos adicionales para tu VPS</h2>

    <app-horizontal-cards [data]="additionalPlugins" [class]="'v-2'" [loadImage]="showImage2"></app-horizontal-cards>
  </div>
</section>
<!-- END -->

<!-- START sectionColumns--support -->
<section class="has-wave top">
  <div class="wave wave-5" *ngIf="showImage2">
    <picture>
      <source srcset="/assets/images/shared/wave5@1x.png,
                      /assets/images/shared/wave5@2x.png 2x" media="(min-width: 671px)">
      <img  src="/assets/images/shared/wave3@1x.png"
            srcset="/assets/images/shared/wave3@2x.png 2x" alt="wave">
    </picture>
  </div>
  <app-section-support [data]="dataSupport" [class]="'vps'" [loadImage]="showImage2"></app-section-support>
</section>
<!-- END -->

<!-- START payment-logos -->
<app-payment-logos
  [buttonId]="'l-vps-plans'"
  [title]="title"
  [class]="'web-hosting-full'"
  [subtext]="'Desde ' + symbol + (dataCards.vps.plans[0].price | currencyFormat ) + ' MENSUAL'"
  [loadImage]="showImage2">
</app-payment-logos>
<!-- END -->

<app-footer [loadImage]="showImage2"></app-footer>
