export const TLD_LIST = [
    'cl',
    'com',
    'net',
    'org',
    'info',
    'co',
    'com.co',
    'pe',
    'com.mx',
    'us',
    'name',
    'biz',
    'tv',
    'io',
    'ltda',
    'pro',
    'ar'
];
