<app-direct-payment-first-screen
  (loading)="getLoadingStatus($event)"
  (showNoResult)="getNoResultVisibility($event)"
  (complete)="getCompleteStatus($event)">
</app-direct-payment-first-screen>
<div class="preloading-overlay" *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<app-search-result [data]="dataResult" *ngIf="isComplete && !isLoading"></app-search-result>

<app-no-search-result [data]="dataNoResult" *ngIf="isNoResult && !isLoading"></app-no-search-result>

<app-send-ticket></app-send-ticket>

<section class="has-wave top">
  <div class="wave wave-1">
    <img
      src="/assets/images/shared/wave2@1x.png"
      srcset="/assets/images/shared/wave2@2x.png 2x"
      alt="wave">
  </div>
</section>

<app-footer [loadImage]="true"></app-footer>

