import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { isPlatformBrowser } from '@angular/common';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { UrlService } from '../../services/url.service';
import { dP_emailRegex, dP_invoiceRegex, dP_tldRegex } from '../../../../../common/functions/regex-standarts';
@Component({
    selector: 'app-direct-payment-first-screen',
    templateUrl: './direct-payment-first-screen.component.html',
    styleUrls: ['./direct-payment-first-screen.component.scss']
})
export class DirectPaymentFirstScreenComponent implements OnInit {
    @Output() public loading = new EventEmitter<boolean>();
    @Output() public complete = new EventEmitter<any>();
    @Output() public showNoResult = new EventEmitter<any>();

    public isLoading = false;
    public form: FormGroup;
    private siteKey: string = '6LeFGJMUAAAAAEDLJLme8uOEGMOTwsjYB6v-gZe7';
    private captcha: any;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private formBuilder: FormBuilder,
        private renderer: Renderer2,
        private apiService: ApiService,
        private reCaptchaService: ReCaptchaV3Service,
        private urlService: UrlService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            search: ['', [
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(63)
            ]]
        });
        this._setOverlay();
        const query = this.urlService.getValue('query');
        if (!!query) {
            this.form.controls['search'].setValue(query);
            this.form.markAllAsTouched();
            this.form.markAsDirty()
            this.form.markAsTouched();
            this.onSubmit();
        }
    }

    public onSubmit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.startCaptcha(true)
        }

        this.isLoading = true;
        this.loading.emit(this.isLoading);

        let invoice = '';
        let email = '';
        let domain = '';
        const input = this.form.controls['search'].value;
        this.complete.emit({ show: false, status: '', data: {} });
        this.showNoResult.emit({ show: false, status: '', data: {} });

        if (dP_emailRegex.test(input)) {
            email = input;
        } else if (dP_invoiceRegex.test(input)) {
            invoice = input;
        } else if (dP_tldRegex.test(input)) {
            domain = input;
        } else {
            this.showNoResult.emit({ show: true, status: 'badInput', data: {} });
            this.isLoading = false;
            this.loading.emit(this.isLoading);
            return;
        }

        this.isLoading = true;
        this._setOverlay();

        const formData = new FormData();
        formData.append("username", "monitor");
        formData.append("password", "7e5a846166cf508ec3b19c2cb9903bde");
        formData.append("invoice", invoice);
        formData.append("email", email);
        formData.append("domain", domain);


        this.apiService.findInvoice(formData).subscribe(res => {
            let values = Object.values(res);
            if (values[0] === 'No found' || values[0] === 'error') {
                this.showNoResult.emit({ show: true, status: values[0], data: values[1] });
            }
            if (values[0] === 'Found finished') {
                this.complete.emit({ show: true, status: values[0], data: values[1] });
            }
            if (values[0] === 'found') {
                this.complete.emit({ show: true, status: values[0], data: values[1] });
            }
        }, err => {
            console.error(err)
        }, () => {
            this.isLoading = false;
            this.loading.emit(this.isLoading);
            this._setOverlay();
        })
    }

    private _setOverlay(): void {
        if (this.isLoading) {
            this.renderer.addClass(document.body.parentElement, 'overflow-hidden');
        } else {
            this.renderer.removeClass(document.body.parentElement, 'overflow-hidden')
        }
    }

    private startCaptcha(state: boolean): void {
        this.reCaptchaService.execute(this.siteKey,
            'dialog_tariff',
            (token) => {
                if (state) {
                    this.captcha = token;
                    const element = document.getElementsByClassName('grecaptcha-badge')[0];
                    element.setAttribute('style', element.getAttribute('style') + 'bottom: 108px;');
                }
            });
    }

}
