import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { ScrollToClass } from './../../../../common/scrollTo.class';

@Component({
    selector: 'app-web-hosting-full',
    templateUrl: './web-hosting-full.component.html',
    styleUrls: ['./web-hosting-full.component.sass']
})

export class WebHostingFullComponent extends ScrollToClass implements OnInit {

    public URL = URL;
    public contactsUrl: string;
    public tld = this.localeService.currentContact.country_code;
    public symbol = this.localeService.symbol;

    public isTablet: boolean;

    public title2 = 'Precios pensados para ti';
    public suptitle = 'Planes para profesionales';

    // load images
    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    public dataCards;

    public sales = [
        {
            title: 'Hasta 4% descuento',
            text: 'Al contratar 2 años en los planes Web Hosting Full',
            icon: 'icon-local-offer',
            color: 'bg-accent-violet'
        }, {
            title: 'Hasta 8% descuento',
            text: 'Al contratar 3 años en los planes Web Hosting Full',
            icon: 'icon-gift',
            color: 'bg-accent-red'
        }
    ];

    public powerArr = [
        {
            title: 'Triple potencia de procesamiento',
            text: 'Más velocidad, más clientes sin comprometer tu rendimiento.',
            icon: 'icon-battery-charging',
            color: 'color-accent-yellow'
        }, {
            title: 'Tres veces más procesos simultáneos',
            text: '¿No te detienes? Nosotros tampoco, más visitas sin preocupaciones.',
            icon: 'icon-zap',
            color: 'color-accent-green'
        }, {
            title: 'El triple de memoria RAM',
            text: 'La capacidad que necesitas en tu sitio a un precio asequible.',
            icon: 'icon-save',
            color: 'color-accent-blue'
        }, {
            title: 'Cuatro veces más inodos',
            text: 'Almacena tus archivos sin pensar en superar el límite.',
            icon: 'icon-dns',
            color: 'color-accent-rose'
        }
    ];

    public powerArr_AR = [
        {
            title: 'Triple potencia de procesamiento',
            text: 'Más velocidad, más clientes sin comprometer tu rendimiento.',
            icon: 'icon-battery-charging',
            color: 'color-accent-yellow'
        }, {
            title: 'Tres veces más procesos simultáneos',
            text: '¿No te detenés? Nosotros tampoco, más visitas sin preocupaciones.',
            icon: 'icon-zap',
            color: 'color-accent-green'
        }, {
            title: 'El triple de memoria RAM',
            text: 'La capacidad que necesitás en tu sitio a un precio asequible.',
            icon: 'icon-save',
            color: 'color-accent-blue'
        }, {
            title: 'Cuatro veces más inodos',
            text: 'Almacená tus archivos sin pensar en superar el límite.',
            icon: 'icon-dns',
            color: 'color-accent-rose'
        }
    ];

    public powerArr_CO = [
        {
            title: 'Triple potencia de procesamiento',
            text: 'Más velocidad, más clientes sin comprometer su rendimiento.',
            icon: 'icon-battery-charging',
            color: 'color-accent-yellow'
        }, {
            title: 'Tres veces más procesos simultáneos',
            text: '¿No se detiene? Nosotros tampoco, más visitas sin preocupaciones.',
            icon: 'icon-zap',
            color: 'color-accent-green'
        }, {
            title: 'El triple de memoria RAM',
            text: 'La capacidad que necesita en su sitio a un precio asequible.',
            icon: 'icon-save',
            color: 'color-accent-blue'
        }, {
            title: 'Cuatro veces más inodos',
            text: 'Almacene sus archivos sin pensar en superar el límite.',
            icon: 'icon-dns',
            color: 'color-accent-rose'
        }
    ];

    public datacenter = [
        {
            title: 'Enlaces Dedicados',
            text: 'LiveHost tiene 2 enlaces Tier I independientes y autónomos de las empresas CenturyLink e Internexa.',
            icon: 'icon-share',
            color: 'bg-accent-violet',
            iconWidth: 17
        }, {
            title: 'RAID 10',
            text: 'Dos veces más velocidad en la lectura y redundancia en la información gracias a nuestros discos independientes.',
            icon: 'icon-infographic',
            color: 'bg-accent-green',
            iconWidth: 17
        }, {
            title: 'Procesador XEON E7',
            text: 'Gestionamos grandes volúmenes de datos sin problemas dado nuestro procesador Intel Xeon CPU E7-4890.',
            icon: 'icon-cpu',
            color: 'bg-accent-orange',
            iconWidth: 24
        }, {
            title: 'Servidores Dell R920',
            text: 'Servidores compuestos por partes originales y certificados que te brindan un mayor rendimiento y fiabilidad.',
            icon: 'icon-window',
            color: 'bg-accent-red',
            iconWidth: 18
        }
    ];

    public dataSupport = {
        suptitle: 'soporte técnico',
        title: 'Estamos contigo',
        // tslint:disable-next-line:max-line-length
        text: '¿Nuevo en hosting? No te preocupes, estamos aquí para ayudarte. Comunicate de Lunes a Viernes con nuestro equipo de soporte y ellos resolverán todas tus dudas.',
        buttonId: 'l-whf-contactar2'
    };

    public dataSupport_AR = {
        suptitle: 'soporte técnico',
        title: 'Estamos contigo',
        // tslint:disable-next-line:max-line-length
        text: '¿Nuevo en hosting? No te preocupés, estamos aquí para ayudarte. Comunicate de Lunes a Viernes con nuestro equipo de soporte y ellos resolverán todas tus dudas.',
        buttonId: 'l-whf-contactar2'
    };

    public dataSupport_CO = {
        suptitle: 'soporte técnico',
        title: 'Estamos con usted',
        // tslint:disable-next-line:max-line-length
        text: '¿Nuevo en hosting? No se preocupe, estamos aquí para ayudarle. Comuníquese de Lunes a Viernes con nuestro equipo de soporte y ellos resolverán todas sus dudas.',
        buttonId: 'l-whf-contactar2'
    };

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isTablet = window.innerWidth <= 992 ? true : false;
    }

    constructor(
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private el: ElementRef
    ) {
        super(el);
        this.dataCards = this.localeService.getContent().plans;
    }

    public ngOnInit() {
        this.isTablet = window.innerWidth <= 992 ? true : false;

        if (this.tld === 'ar') {
            this._setMeta_AR();
            this.title2 = 'Precios pensados para vos';
            this.dataSupport = this.dataSupport_AR;
            this.powerArr = this.powerArr_AR;
        } else if (this.tld === 'co') {
            this._setMeta_CO();
            this.title2 = 'Precios pensados para usted';
            this.datacenter[3].text = 'Servidores compuestos por partes ' +
                'originales y certificados que le brindan un mayor rendimiento y fiabilidad.';
            this.powerArr = this.powerArr_CO;
            this.dataSupport = this.dataSupport_CO;
        } else if (this.tld === 'pe') {
            this._setMeta_PE();
        } else if (this.tld === 'mx') {
            this._setMeta_MX();
        } else {
            this._setMeta_CL();
        }
    }

    public navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }

    private _setMeta_CL() {
        this._meta.setTitle('LIVEHOST | Hosting profesional para sitios de alto tráfico');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Un Web Hosting para profesionales. Porque el alto tráfico en tu sitio web jamás debe ser un problema, contrata tu plan de hosting con LiveHost Chile');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting chile, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-web-hosting-full.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting Full SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_CL');
    }

    private _setMeta_PE() {
        this._meta.setTitle('LIVEHOST | Hosting profesional para sitios de alto tráfico');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Un Web Hosting para profesionales. Porque el alto tráfico en tu sitio web jamás debe ser un problema, contrata tu plan de hosting con LiveHost Perú');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting perú, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-web-hosting-full.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting Full SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_PE');
    }

    private _setMeta_CO() {
        this._meta.setTitle('LIVEHOST | Hosting profesional para sitios de alto tráfico');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Un Web Hosting para profesionales. Porque el alto tráfico en su sitio web jamás debe ser un problema, contrate su plan de hosting con LiveHost Colombia');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting colombia, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-web-hosting-full.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting Full SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_CO');
    }

    private _setMeta_AR() {
        this._meta.setTitle('LIVEHOST | Hosting profesional para sitios de alto tráfico');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Un Web Hosting para profesionales. Porque el alto tráfico en tu sitio web jamás debe ser un problema, contrata tu plan de hosting con LiveHost Argentina');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting argentina, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-web-hosting-full.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting Full SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_AR');
    }

    private _setMeta_MX() {
        this._meta.setTitle('LIVEHOST | Hosting profesional para sitios de alto tráfico');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Un Web Hosting para profesionales. Porque el alto tráfico en tu sitio web jamás debe ser un problema, contrata tu plan de hosting con LiveHost México');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, hosting méxico, web hosting, hosting con dominio, alojamiento web, hosting pyme, dominios, web hosting, servidor privado virtual');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-web-hosting-full.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting Full SSD - LiveHost');
        this._meta.setTag('og:locale', 'es_MX');
    }

}
