<div class="paymentOptions">
  <!-- START paymentOptions__select -->
  <div class="paymentOptions__select">
    <span class="flag">
      <img [src]="getCurrentCountry()['flag_url']" [alt]="currentCountry">
    </span>

    <mat-form-field class="countrySelect">
      <mat-select panelClass="countrySelect__panel" [(value)]="currentCountry">
        <mat-option *ngFor="let contact of contactList; let i = index" [value]="contact.country">
          <span class="flag">
            <img src="{{contact.flag_url}}" alt="">
          </span>
          <span class="country" i18n>{{contact.country}}</span>
          <span class="icon">
            <svg-icon-sprite
              [src]="'/assets/images/sprites/sprite.svg#icon-arrow-up'"
              [width]="'10px'"
              [classes]="'icon-arrow-up'">
            </svg-icon-sprite>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- END -->
  <!-- START paymentOptions__card -->
  <div class="paymentOptions__card">
    <div class="paymentOptions__item" *ngFor="let payment of getCurrentPayment().payments_method">
      <div class="row">
        <div class="col">
          <div class="title-3" [innerHTML]="payment.method" i18n></div>
          <div class="body-2 color-secondary" [innerHTML]="payment.state" i18n></div>
        </div>
        <div class="col" *ngIf="payment.hasOwnProperty('logos')">
          <img src="/assets/images/logos-banks/{{logo}}.svg" alt="logo bank" *ngFor="let logo of payment.logos">
        </div>
      </div>

      <!-- START bank_info -->
      <ng-container *ngIf="payment.hasOwnProperty('bank_info')">
        <ng-container *ngIf="payment.bank_info.hasOwnProperty('logos')">
          <img src="/assets/images/logos-banks/{{logo}}.svg" alt="bank logo" *ngFor="let logo of payment.bank_info.logos">
        </ng-container>

        <ul class="listInfo">
          <li class="body-2"><span class="color-secondary" i18n>Banco:</span><b>{{payment.bank_info.bank}}</b></li>
          <li class="body-2"><span class="color-secondary" i18n>Cuenta corriente:</span><b>{{payment.bank_info.number}}</b></li>
          <li class="body-2"><span class="color-secondary" i18n>Razón social:</span><b>{{payment.bank_info.company}}</b></li>
          <li class="body-2"><span class="color-secondary" i18n>Rut:</span><b>{{payment.bank_info.rut}}</b></li>
          <li class="body-2"><span class="color-secondary">E-mail:</span><b>{{payment.bank_info.email}}</b></li>
        </ul>
      </ng-container>
      <!-- end -->

      <!-- START payment_info -->
      <ng-container *ngIf="payment.hasOwnProperty('payment_info')">
        <ng-container *ngFor="let info of payment.payment_info">
          <ng-container *ngIf="info.hasOwnProperty('logos')">
            <img src="/assets/images/logos-banks/{{logo}}.svg" alt="bank logo" *ngFor="let logo of info.logos">
          </ng-container>

          <ul class="listInfo">
            <li class="body-2"><span class="color-secondary" i18n>Titular:</span><b>{{info.point_1}}</b></li>
            <li class="body-2"><span class="color-secondary" i18n>№ cta. cte. soles S/.:</span><b>{{info.point_2}}</b></li>
            <li class="body-2" *ngIf="info.hasOwnProperty('point_3')" i18n><span class="color-secondary">N° de CCI S/.:</span><b>{{info.point_3}}</b></li>
            <li class="body-2"><span class="color-secondary" i18n>№ cta. cte. dólares USD:</span><b>{{info.point_4}}</b></li>
            <li class="body-2" *ngIf="info.hasOwnProperty('point_5')" i18n><span class="color-secondary">№ CCI dólares USD:</span><b>{{info.point_5}}</b></li>
            <li class="body-2"><span class="color-secondary" i18n>RUC:</span><b>{{info.point_6}}</b></li>
            <li class="body-2"><span class="color-secondary">E-mail:</span><b>{{info.email}}</b></li>
          </ul>
        </ng-container>
      </ng-container>
      <!-- end -->
    </div>
  </div>
  <!-- END -->
</div>
