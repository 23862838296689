import { Component, OnInit, HostListener, Input } from '@angular/core';
import { LocaleService } from '../../services/locale.service';

@Component({
    selector: 'app-table-domain-zones',
    templateUrl: './table-domain-zones.component.html',
    styleUrls: ['./table-domain-zones.component.sass']
})

export class TableDomainZonesComponent implements OnInit {
    @Input() subtext: string;

    public isMobile = false;
    public symbol;
    public dataTableDomainZones;
    public currencyPeriod;
    public currencyData = 1;

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobile = window.innerWidth <= 767 ? true : false;
    }

    constructor(
        private localeService: LocaleService
    ) {
        this.symbol = localeService.symbol;
    }

    ngOnInit() {
        this.dataTableDomainZones = this.localeService.getContent().DATA_DOMAIN_ZONES;
        this.currencyPeriod = this.dataTableDomainZones.table_header[1];
        this.isMobile = window.innerWidth <= 767 ? true : false;
    }

    selectedPeriod(selectedPeriod, index) {
        this.currencyPeriod = selectedPeriod;
        this.currencyData = index;
    }
}
