import { Component, Input, OnInit } from '@angular/core';

export interface INoResult {
    icon: string;
    title: string;
    text: string;
    state?: string;
}

@Component({
    selector: 'app-no-search-result',
    templateUrl: './no-search-result.component.html',
    styleUrls: ['./no-search-result.component.scss']
})
export class NoSearchResultComponent implements OnInit {
    @Input() public data: INoResult;

    constructor() { }

    ngOnInit(): void {
    }

}
