<div class="paymentLogos" [ngClass]="class">
  <div class="container rel">
    <picture *ngIf="loadImage">
      <source srcset="/assets/images/shared/payment-curves-desktop@1x.png,
                      /assets/images/shared/payment-curves-desktop@2x.png 2x" media="(min-width: 992px)">
      <source srcset="/assets/images/shared/payment-curves-tablet@1x.png,
                      /assets/images/shared/payment-curves-tablet@2x.png 2x" media="(min-width: 671px)">
      <img  src="/assets/images/shared/firstscreen-decorations-tablet@1x.png"
            srcset="/assets/images/shared/firstscreen-decorations-tablet@2x.png 2x" alt="Curves" class="curves">
    </picture>
    <h3 class="suptitle upper center color-accent-green">Formas de pago</h3>
    <picture *ngIf="loadImage">
      <source srcset="{{ getImgPayment('desktop', '1x') }},
                      {{ getImgPayment('desktop', '2x') }}" media="(min-width: 481px)">
      <img  src="{{ getImgPayment('mobile', '1x') }}"
            srcset="{{ getImgPayment('mobile', '2x') }}" alt="Payment methods">
    </picture>
  </div>
  <div class="paymentLogos__action rel">
    <picture *ngIf="!isTablet && loadImage">
      <source srcset="/assets/images/shared/payments-points-desktop@1x.png,
                      /assets/images/shared/payments-points-desktop@2x.png 2x" media="(min-width: 671px)">
      <img  src="/assets/images/shared/payments-points-mobile@1x.png"
            srcset="/assets/images/shared/payments-points-mobile@2x.png 2x" alt="Points" class="decorations">
    </picture>
    <svg-icon-sprite
      class="decorations"
      [src]="'/assets/images/sprites/sprite.svg#icon-decoration-x'"
      [width]="'28px'"
      [classes]="'icon-decoration-x'"
      *ngIf="isTablet && loadImage">
    </svg-icon-sprite>
    <div class="suptitle upper color-accent-green" i18n *ngIf="subtext !== undefined">{{subtext}}</div>
    <h2 class="title-2 color-white" i18n>{{title}}</h2>
    <button id="{{buttonId}}" class="btn btnAccent rounded" mat-button (click)="navigateToSection('plans-ts')" i18n>{{buttonText}}</button>
  </div>
</div>
