import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-info-cards',
    templateUrl: './info-cards.component.html',
    styleUrls: ['./info-cards.component.sass']
})

export class InfoCardsComponent implements OnInit {
    @Input() data;
    @Input() class;
    @Input() isIconCircle: boolean;
    @Input() loadImage: boolean;

    constructor() {
    }

    ngOnInit() {
    }

}
