import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TLD_LIST } from '../data-domain-zones';
import { LocaleService } from './locale.service';
import { URL } from '../../environments/environment';

@Injectable()
export class DomainService {

    public doc$ = new Subject<string>();
    public URL = URL;
    aApiURLPrices = this.URL.LIVEHOST_GET_DOMAIN;
    CurrenciCode;
    private domain: string;

    constructor(
        private http: HttpClient,
        private localeService: LocaleService
    ) {
        this.CurrenciCode = this.localeService.getContact().currency_code;
    }

    public getValue$(): Observable<string> {
        return this.doc$.asObservable();
    }

    public setValue(doc: string): void {
        this.doc$.next(doc);
    }
    public setdomain(searchdomain: string) {
        this.domain = searchdomain;
    }

    public getDomains(): Observable<any> {
        const body = new HttpParams()
        .set('domain', this.domain)
        .set('currency', this.CurrenciCode)
        .set('tlds', TLD_LIST.toString());

        return this.http.post(this.aApiURLPrices, body);
    }

}
