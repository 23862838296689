import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LocaleService } from './services/locale.service';

declare var _slaask: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'livehost';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private serviceLocale: LocaleService
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.loadScript();
      }, 2000);
      setTimeout(() => {
        this.initSlaask();
      }, 4000);
    }
    if (this.serviceLocale.getGTM() != '') {
      this.loadGTM(this.serviceLocale.getGTM(), this.serviceLocale.getGtag());
    }
  }

  loadGTM(GTMlolcale: string, idGtag: string): void {
    //insert into <head></head> script for Google Tag Manager
    let scriptGTM = document.createElement('script');
    scriptGTM.type = 'text/javascript';
    scriptGTM.innerHTML = 'setTimeout(function(){(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start":new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src="https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f);})(window,document,"script","dataLayer","' + GTMlolcale + '");},5000);';
    document.getElementsByTagName('head')[0].appendChild(scriptGTM);
    //insert into <body></body> noscript for Google Tag Manager
    let noscriptGTM = document.createElement('noscript');
    noscriptGTM.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=' + GTMlolcale + '"height="0" width="0" style="display:none;visibility:hidden"></iframe>';
    document.getElementsByTagName('body')[0].appendChild(noscriptGTM);
    //insert into <head></head> script source Google Analytics
    let scriptSRC = document.createElement('script');
    scriptSRC.src = 'https://www.googletagmanager.com/gtag/js?id=' + idGtag;
    scriptSRC.async = true
    document.getElementsByTagName('head')[0].appendChild(scriptSRC);
    //insert into <head><head> script gtag
    let scriptGtag = document.createElement('script');
    scriptGtag.innerHTML = 'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config","' + idGtag + '");';
    document.getElementsByTagName('head')[0].appendChild(scriptGtag);
  }

  loadScript(): void {
    let node1 = document.createElement('script');
    node1.src = 'https://cdn.slaask.com/chat.js';
    node1.type = 'text/javascript';
    node1.async = true;
    document.getElementsByTagName('head')[0].appendChild(node1);
  }

  initSlaask(): void {
    _slaask.init('spk-f19989bc-7cf0-4c71-ad93-32c31b97d854');
  }
}
