<footer class="wrapFooter">
  <picture *ngIf="loadImage">
    <source srcset="/assets/images/shared/footer-decorations-desktop@1x.png,
                    /assets/images/shared/footer-decorations-desktop@2x.png 2x" media="(min-width: 992px)">
    <source srcset="/assets/images/shared/footer-decorations-tablet@1x.png,
                    /assets/images/shared/footer-decorations-tablet@2x.png 2x" media="(min-width: 670px)">
    <img  src="/assets/images/shared/footer-decorations-mobile@1x.png"
          srcset="/assets/images/shared/footer-decorations-mobile@2x.png 2x" alt="decorations" class="decorations">
  </picture>

  <div class="wrapFooter__top">
    <div class="wrapper row">
      <div class="inner">
        <a class="logo" [routerLink]="['/']" (click)="scrollTop()">
          <picture class="logo-white" *ngIf="loadImage">
            <source srcset="/assets/images/logo/livehost-white@1x.png,
                    /assets/images/logo/livehost-white@2x.png 2x" media="(min-width: 671px)">
            <img  src="/assets/images/logo/livehost-white-mobile@1x.png"
                  srcset="/assets/images/logo/livehost-white-mobile@2x.png 2x" alt="Livehost">
          </picture>
        </a>
        <div class="currentlyCountry">
          <div class="country">{{currentCountry.city}}, {{currentCountry.country}}</div>
          <h2 class="phone">{{currentCountry.main_phone}}</h2>
        </div>
      </div>
      <div class="inner row">
        <div class="wrapFooter__menu" *ngFor="let item of footerList">
          <div class="title" i18n><b>{{item.title}}</b></div>
          <ul class="wrapFooter__list">
            <li *ngFor="let point of item.list">
              <a  id="{{point.id}}" (click)="navigationTo(point.url, point.isRouter)">
                {{point.text}}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="inner">
        <div class="title" i18n><b>Contáctanos</b></div>
        <div class="soc">
          <a id="{{soc.id}}" (click)="goToUrl(soc.url)" class="icon" *ngFor="let soc of socList">
            <svg-icon-sprite
              [src]="'/assets/images/sprites/sprite.svg#' + soc.icon"
              [width]="soc.width + 'px'"
              [classes]="soc.icon"
              *ngIf="loadImage">
            </svg-icon-sprite>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapFooter__bottom">
    <div class="countriesPhones">
      <ng-container *ngFor="let contact of contactList">
        <div class="countriesPhones__item">
          <span class="flag">
            <img src="{{contact.flag_url}}" alt="flag {{contact.country}}" *ngIf="loadImage">
          </span>
          <div class="country"><span class="city">{{contact.city}},</span> {{contact.country}}</div>
          <a [href]="'https://www.livehost.'  + contact.footer_link" class="link" target="_blank" rel="noopener">
            www.livehost.{{contact.footer_link}}
          </a>
          <div class="phone">{{contact.main_phone}}</div>
        </div>
      </ng-container>
    </div>
  </div>
</footer>
