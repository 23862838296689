import { Component, OnInit, Output, HostListener, ElementRef } from '@angular/core';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DomainService } from '../services/domain-service';
import { Observable } from 'rxjs';
import { MetaService } from '@ngx-meta/core';
import { ScrollToClass } from '../../../../common/scrollTo.class';

@Component({
    selector: 'app-domains',
    templateUrl: './domains.component.html',
    styleUrls: ['./domains.component.sass']
})

export class DomainsComponent extends ScrollToClass implements OnInit {
    @Output() searchedDomain;

    // load images
    public showImage1 = false;
    public showImage2 = false;

    public te = 'te';
    public form;
    public isloading: boolean;
    public isShowResultSearch = false;
    public response: Observable<any>;
    public tldList = [];
    public aux;

    public subtext = 'Los valores indicados no incluyen impuestos*';

    public URL = URL;
    public tld = this.localeService.currentContact.country_code;
    public domain = this.tld;
    public symbol = this.localeService.symbol;
    public title = '¡Obtén tu Dominio .' + this.domain + ' ahora!';
    public isShowVideo: boolean;
    public isMobile: boolean;

    public dataCards;

    public dataSupport = {
        suptitle: 'Transferencia de dominio',
        title: 'Transfiere tu dominio existente a Livehost sin costo',
        // tslint:disable-next-line:max-line-length
        text: 'Si ya cuentas con un nombre de dominio podemos transferirlo a LiveHost totalmente gratis para que recibas todos los beneficios que tenemos para ofrecerte. ',
        buttonId: 'l-d-contactar2'
    };

    public dataSupport_AR = {
        suptitle: 'Transferencia de dominio',
        title: 'Transferí tu dominio existente a Livehost sin costo',
        // tslint:disable-next-line:max-line-length
        text: 'Si ya contás con un nombre de dominio podemos transferirlo a LiveHost totalmente gratis para que recibás todos los beneficios que tenemos para ofrecerte. ',
        buttonId: 'l-d-contactar2'
    };

    public dataSupport_CO = {
        suptitle: 'Transferencia de dominio',
        title: 'Transfiera su dominio existente a Livehost sin costo',
        // tslint:disable-next-line:max-line-length
        text: 'Si ya cuenta con un nombre de dominio podemos transferirlo a LiveHost totalmente gratis para que reciba todos los beneficios que tenemos para ofrecerle. ',
        buttonId: 'l-d-contactar2'
    };

    public options = [
        {
            title: 'Simple siempre es mejor',
            // tslint:disable-next-line:max-line-length
            text: 'Mientras más simple sea el nombre de tu sitio, es más probable que tus clientes lo recuerden, además te ayudará con tu SEO.',
            icon: 'icon-one',
            color: 'color-accent-yellow'
        }, {
            title: 'Usa tu Marca',
            // tslint:disable-next-line:max-line-length
            text: 'Tu nombre es tu marca. Se único y destaca con tu dominio pero ten cuidado con crear un nombre difícil de recordar',
            icon: 'icon-two',
            color: 'color-accent-green'
        }, {
            title: 'Actúa rápido',
            // tslint:disable-next-line:max-line-length
            text: 'La verdad es que los dominios se registran muy rápidamente y puedes perder el nombre de tu sitio mientras lo piensas mejor.',
            icon: 'icon-three',
            color: 'color-accent-violet'
        }
    ];

    public options_AR = [
        {
            title: 'Simple siempre es mejor',
            // tslint:disable-next-line:max-line-length
            text: 'Mientras más simple sea el nombre de tu sitio, es más probable que tus clientes lo recuerden, además te ayudará con tu SEO.',
            icon: 'icon-one',
            color: 'color-accent-yellow'
        }, {
            title: 'Usá tu Marca',
            // tslint:disable-next-line:max-line-length
            text: 'Tu nombre es tu marca. Se único y destacá con tu dominio pero ten cuidado con crear un nombre difícil de recordar',
            icon: 'icon-two',
            color: 'color-accent-green'
        }, {
            title: 'Actúa rápido',
            // tslint:disable-next-line:max-line-length
            text: 'La verdad es que los dominios se registran muy rápidamente y podés perder el nombre de tu sitio mientras lo pensás mejor.',
            icon: 'icon-three',
            color: 'color-accent-violet'
        }
    ];

    public options_CO = [
        {
            title: 'Simple siempre es mejor',
            // tslint:disable-next-line:max-line-length
            text: 'Mientras más simple sea el nombre de su sitio, es más probable que sus clientes lo recuerden, además le ayudará con su SEO.',
            icon: 'icon-one',
            color: 'color-accent-yellow'
        }, {
            title: 'Use su Marca',
            // tslint:disable-next-line:max-line-length
            text: 'Su nombre es su marca. Sea único y destaque con su dominio pero tenga cuidado con crear un nombre difícil de recordar.',
            icon: 'icon-two',
            color: 'color-accent-green'
        }, {
            title: 'Actúe rápido',
            // tslint:disable-next-line:max-line-length
            text: 'La verdad es que los dominios se registran muy rápidamente y puede perder el nombre de su sitio mientras lo piensa mejor.',
            icon: 'icon-three',
            color: 'color-accent-violet'
        }
    ];

    public additionalPlugins = [
        {
            title: 'cPanel',
            text: 'Administra y gestiona tu web hosting de forma más sencilla y rápida con cPanel.',
            price: 20000,
            icon: 'icon-cpanel',
            color: 'bg-accent-orange',
            iconWidth: 27
        }, {
            title: 'Softaculous',
            text: 'Dale a tus cientes el chance de optar entre las más de 450 apps de nuestro instalador.',
            price: 1000,
            icon: 'icon-soft',
            color: 'bg-accent-lightgreen',
            iconWidth: 24
        }
    ];

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobile = window.innerWidth <= 670 ? true : false;
    }

    constructor(
        private localeService: LocaleService,
        private fb: FormBuilder,
        private _domainSrv: DomainService,
        private readonly _meta: MetaService,
        private el: ElementRef
    ) {
        super(el);
        this.dataCards = this.localeService.getContent().plans;

        this.form = this.fb.group({
            search: ['', [
                Validators.required,
                Validators.pattern('[ña-zÑA-Z]{3,}(\\.{1}[a-zA-Z]{2,6}){1,2}')
            ]],
        });
    }

    ngOnInit() {
        this.isMobile = window.innerWidth <= 670 ? true : false;

        if (this.tld === 'ar') {
            this._setMeta_AR();
            this.options = this.options_AR;
            this.subtext = '';
            this.dataSupport = this.dataSupport_AR;
            this.title = '¡Obten tu Dominio .com.' + this.domain + ' ahora!';
        } else if (this.tld === 'co') {
            this._setMeta_CO();
            this.subtext = '';
            this.options = this.options_CO;
            this.dataSupport = this.dataSupport_CO;
            this.title = '¡Obtenga su Dominio .' + this.domain + ' ahora!';
            this.te = 'le';
        } else if (this.tld === 'pe') {
            this._setMeta_PE();
        } else if (this.tld === 'mx') {
            this._setMeta_MX();
            this.subtext = '';
            this.domain = 'com.mx';
        } else {
            this._setMeta_CL();
        }
    }

    public navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }

    onSubmit() {
        this.response = null;
        this.isloading = true;
        this.isShowResultSearch = false;
        this.searchedDomain = this.form.value.search;
        this._domainSrv.setdomain(this.searchedDomain);
        this.aux = this.searchedDomain.split(/\.(.+)/);
        const excludeTLD = this.aux[1];
        const excludeIndex = this.tldList.findIndex(d => d === excludeTLD);
        this._domainSrv.setValue(this.searchedDomain);
        this.getDomainSearched();

        if (excludeIndex == null || excludeIndex == undefined) {
            this.isShowResultSearch = false;
            // this.snackBarService.showMessage('¡Ups! Aún no vendemos ese dominio.', false, 'ACEPTAR', 5000);
        }
    }

    getDomainSearched() {
        this._domainSrv.getDomains().subscribe(res => {
            this.response = res['response'];
            this.isShowResultSearch = true;
            this.isloading = false;
            // if (this.response['is_available']) {
            //     this.isAvailable = true;
            //     this.periodList = [];
            //     this.response['prices'].forEach(price => {
            //         this.period = '';
            //         this.period = price['year'] + ' AÑOS ' + this.localeService.symbol + Math.round(price['price']);
            //         this.periodList.push(this.period);
            //     });
            //     this.selected_period = this.periodList[0];
            // } else {
            //     this.isAvailable = false;
            // }
        });
    }

    private _setMeta_CL() {
        this._meta.setTitle('LIVEHOST | Consulta y Registra tu dominio .CL en LiveHost');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', '¿Buscando dónde registrar tu dominio? Hazlo en LiveHost. Dominio .CL por solo $8.900 y comienza a posicionar tu sitio web');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'dominio, registrar dominio, dominios disponibles, dominio .CL, dominio .COM, hosting y dominio, hosting con dominio');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-domains.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Registra tu Dominio - LiveHost');
        this._meta.setTag('og:locale', 'es_CL');
    }

    private _setMeta_PE() {
        this._meta.setTitle('LIVEHOST | Consulta y Registra tu dominio .PE en LiveHost');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', '¿Buscando dónde registrar tu dominio? Hazlo en LiveHost. Dominio .PE por solo S/200 y comienza a posicionar tu sitio web');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'dominio, registrar dominio, dominios disponibles, dominio .PE, dominio .COM, hosting y dominio, hosting con dominio');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-domains.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Registra tu Dominio - LiveHost');
        this._meta.setTag('og:locale', 'es_PE');
    }

    private _setMeta_CO() {
        this._meta.setTitle('LIVEHOST | Consulte y Registre su dominio .CO en LiveHost');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', '¿Buscando dónde registrar su dominio? Hagalo en LiveHost. Dominio .CO por solo $140.000 y comience a posicionar su sitio web');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'dominio, registrar dominio, dominios disponibles, dominio .CO, dominio .COM, hosting y dominio, hosting con dominio');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-domains.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Registre su Dominio - LiveHost');
        this._meta.setTag('og:locale', 'es_CO');
    }

    private _setMeta_AR() {
        this._meta.setTitle('LIVEHOST | Consulta y Registra tu dominio .COM.AR en LiveHost');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', '¿Buscando dónde registrar tu dominio? Hazlo en LiveHost. Dominio .COM.AR por solo $1.250 y comienza a posicionar tu sitio web');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'dominio, registrar dominio, dominios disponibles, dominio .COM.AR, dominio .COM, hosting y dominio, hosting con dominio');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-domains.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Registra tu Dominio - LiveHost');
        this._meta.setTag('og:locale', 'es_AR');
    }

    private _setMeta_MX() {
        this._meta.setTitle('LIVEHOST | Consulta y Registra tu dominio .COM.MX en LiveHost');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', '¿Buscando dónde registrar tu dominio? Hazlo en LiveHost. Dominio .COM.MX por solo $790 y comienza a posicionar tu sitio web');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'dominio, registrar dominio, dominios disponibles, dominio .CL, dominio .COM.MX, hosting y dominio, hosting con dominio');
        this._meta.setTag('author', 'Livehost');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.LIVEHOST_WEBSITE_BASE + '/assets/images/og/og-domains.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Registra tu Dominio - LiveHost');
        this._meta.setTag('og:locale', 'es_MX');
    }

    public getTaxSymbol(): string {
        if (this.tld === 'pe') { return '+ IGV'; }
        if (this.tld === 'mx' || this.tld === 'co' || this.tld === 'ar') { return ''; }
        return '+ IVA';
    }

    getCurrentDomainZone(tld): string {
        const dataDomainZone = this.localeService.getContent().DATA_DOMAIN_ZONES.table_body;
        let currentDomain = (this.tld === 'ar' || this.tld === 'mx') ? ('.com.' + tld) : ('.' + tld);
        if (this.tld === 'co') {
            currentDomain = '.co (colombia)';
        }
        const currentItem = dataDomainZone.filter(item => item.name.toLowerCase() === currentDomain)[0];
        return currentItem.price_period_1;
    }

    goToPlan(domain: string): void {
        const aux = domain.split(/\.(.+)/);
        const sld = aux[0];
        const tld = aux[1];
        let currency = 1;
        currency = this.localeService.currentContact.currency_number;

        window.open('https://panel.livehost.host/cart.php?a=add&domain=register&sld='
        + sld + '&tld=.' + tld + '&currency=' + currency, '_blank');
    }
}
