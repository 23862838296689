<section class="paymentFirstScreen has-wave bottom">
  <div class="wave wave-1">
    <picture>
      <source srcset="/assets/images/shared/wave@1x.png,
                      /assets/images/shared/wave@2x.png 2x" media="(min-width: 481px)">
        <img src="/assets/images/shared/wave5@1x.png" srcset="/assets/images/shared/wave5@2x.png 2x" alt="wave">
    </picture>
  </div>
  <picture>
    <source srcset="/assets/images/domains/firstscreen-domains-decorations@1x.png,
                    /assets/images/domains/firstscreen-domains-decorations@2x.png 2x" media="(min-width: 481px)">
    <img  src="/assets/images/domains/firstscreen-domains-decorations-mobile@1x.png"
          srcset="/assets/images/domains/firstscreen-domains-decorations-mobile@2x.png 2x" alt="decorations" class="decorations">
  </picture>

  <div class="wrapCircle">
    <img
      src="/assets/images/shared/bg-blue-circle@1x.png"
      srcset="/assets/images/shared/bg-blue-circle@2x.png 2x"
      alt="circle">
  </div>

  <div class="container">
    <div class="paymentFirstScreen__info">
      <div class="suptitle upper color-accent-green center" i18n>RÁPIDO Y SEGURO</div>
      <h1 class="title-1 center" i18n>PAGA TU DEUDA AL INSTANTE.</h1>
    </div>

    <!-- START formDomain -->
    <form class="searchForm" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="searchForm__field">
        <input type="email"
              #inputSearch
              formControlName="search"
              [class.filled]="form.dirty">
        <span class="placeholder">Ingresa tu dominio, N° de Orden o correo electrónico</span>
      </div>
      <button class="btn btnAccent rounded"
              mat-button
              [disabled]="form.invalid || isLoading"
              type="submit" i18n>
        Consultar deuda
      </button>
    </form>
    <!-- END -->

  </div>
</section>
