import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LocaleService } from '../../services/locale.service';

@Component({
    selector: 'app-tab-plans',
    templateUrl: './tab-plans.component.html',
    styleUrls: ['./tab-plans.component.sass']
})

export class TabPlansComponent implements OnInit {
    @Input() data;
    @Input() link;

    period: string;
    currentPlan;
    tld = this.localeService.currentContact.country_code;
    symbol = this.localeService.symbol;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private localeService: LocaleService,
    ) {
    }

    ngOnInit() {
        this.period = this.data === this.localeService.getContent().plans.reseller.plans ? 'Mensual' : 'Anual';
        this.currentPlan = this.data.web_hosting;
        this.link = '/';
    }

    selectedPlan(number: number): void {
        switch (number) {
            case 2:
                this.currentPlan = this.data.pymes;
                this.period = 'Anual';
                break;
            case 3:
                this.currentPlan = this.data.vps;
                this.period = 'Mensual';
                break;
            default:
                this.period = 'Anual';
                this.currentPlan = this.data.web_hosting;
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    public getTaxSymbol(): string {
        if (this.tld === 'pe') { return '+ IGV'; }
        if (this.tld === 'mx' || this.tld === 'co' || this.tld === 'ar') { return ''; }
        return '+ IVA';
    }

}
